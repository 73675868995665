import Button, { ButtonProps } from "@mui/material/Button";
import styled from "styled-components";
import { theme } from "../utils/Theme";
import { ReactNode } from "react";

export interface CustomButtonsProps extends ButtonProps {
  bgcolor?: string;
  label?: string | ReactNode;
  labelcolor?: string;
  hoverBgcolor?: string;
  hoverlabelcolor?: string;
}

const CustomButton = ({
  bgcolor,
  label,
  labelcolor,
  hoverBgcolor,
  hoverlabelcolor,
  type,
  children,
  ...props
}: CustomButtonsProps) => {
  return (
    <StyledButton {...props} hoverBgcolor={hoverBgcolor} bgcolor={bgcolor} type={type}>
      {label && (
        <Label hoverlabelcolor={hoverlabelcolor} labelcolor={labelcolor}>
          {label}
        </Label>
      )}
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{
  bgcolor?: string;
  hoverBgcolor?: string;
}>`
  && {
    width: 100%;
    height: 100%;
    padding: 1rem;
    border: 1px solid ${({ bgcolor }) => bgcolor};
    border-radius: 1.5rem;
    background-color: ${({ bgcolor }) => bgcolor};
    text-transform: none;

    &:hover & {
      border: 1px solid ${({ hoverBgcolor }) => hoverBgcolor};
      border-color: ${({ hoverBgcolor }) => hoverBgcolor};
    }
  }
`;

const Label = styled.span<{ labelcolor?: string; hoverlabelcolor?: string }>`
  color: ${({ labelcolor }) => labelcolor};
  font-size: ${theme.fontSizes.medium};
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.semibold};

  ${StyledButton}:hover & {
    color: ${({ hoverlabelcolor }) => hoverlabelcolor};
  }
`;

export default CustomButton;
