import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "../../components/Form";
import Loading from "../../components/Loading";
import { inputProps } from "../../constants/demand/DemandProfile";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { DemandResponse, DemandResponseType, DemandType } from "../../models/Demand";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { ErrorType } from "../../models/Error";

const DemandProfileDataProvider = () => {
  const { error, setLoading, setError } = useContext(AppContext);
  const deviceType = useDeviceType();
  const { id } = useParams();
  const { customers, users, getCustomers, getUsers, loadAllData } = useApiData();

  const [demand, setDemand] = useState<DemandType | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getCustomers);
        await loadAllData(getUsers);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (id) {
      getDemand(id);
    }
  }, [id]);

  const getDemand = async (id: string) => {
    setLoading(true);

    try {
      const response = await callApi<DemandResponseType, undefined>(
        "GET",
        `/demands/${id}`,
        DemandResponse,
        undefined,
      );

      if (response) {
        const formattedData = {
          ...response,
          customer_id: response.customer_id?.replace("/api/customers/", ""),
          commercial_user_id: response.commercial_user_id?.replace("/api/users/", ""),
        };

        setDemand(formattedData);
      }
    } catch (e) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        demand && (
          <Form
            title="Richiesta"
            inputProps={inputProps(customers, users, demand, deviceType === DeviceType.Mobile)}
            onSubmit={() => {}}
          />
        )
      )}
    </>
  );
};

export default DemandProfileDataProvider;
