import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { CustomerCollectionResponseType } from "../../models/Customer";
import { CustomerHistoryType, typeHistoryOptions } from "../../models/CustomerHistory";
import { DemandCollectionResponseType } from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import { UserResponseCollectionType } from "../../models/User";
import { currentDate, formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";

export const inputProps = (
  customerHistory: CustomerHistoryType,
  customers: CustomerCollectionResponseType | null,
  users: UserResponseCollectionType | null,
  demands: DemandCollectionResponseType | null,
  error: ErrorType | null,
  resize: boolean,
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
): InputGroup[] => {
  const customerOptions = customers
    ? customers["hydra:member"].map((customer) => ({
        value: customer.id,
        label: `${customer.name ?? ""} ${customer.surname ?? ""}`,
      }))
    : [];

  const userOptions = users
    ? users["hydra:member"].map((user) => ({
        value: user.id,
        label: `${user.name ?? ""} ${user.surname ?? ""}`,
      }))
    : [];

  const demandOptions = demands
    ? demands["hydra:member"].map((demand) => ({
        value: demand.id,
        label: `${demand.unique_protocol ?? ""}`,
      }))
    : [];

  return [
    {
      fields: [
        {
          value:
            userOptions.find((option) => option.value === Number(customerHistory.user_id))?.value ||
            "",
          label: "Commerciale di riferimento",
          name: "user_id",
          type: "select",
          options: userOptions,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value:
            customerOptions.find((option) => option.value === Number(customerHistory.customer_id))
              ?.value || "",
          label: "Cliente",
          name: "customer_id",
          type: "select",
          options: customerOptions,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value:
            demandOptions.find((option) => option.value === Number(customerHistory.demand_id))
              ?.value || "",
          label: "Richiesta",
          name: "demand_id",
          type: "select",
          options: demandOptions,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: customerHistory.date ? formatDate(customerHistory.date) : "",
          label: "Data",
          name: "date",
          type: "date",
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          inputProps: { max: currentDate() },
          onChange: handleOnChange,
        },
        {
          value: customerHistory.type || "",
          label: "Tipologia",
          name: "type",
          type: "select",
          options: typeHistoryOptions,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: customerHistory.result || "",
          label: "Esito",
          name: "result",
          type: "text",
          multiline: true,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
      ],
      inputColumns: resize ? 1 : 2,
    },
  ];
};

export const buttonProps = (loading: boolean, error: ErrorType | null): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{error.detail}</ErrorText> : null,
    fields: [
      {
        disabled: loading || !!error,
        label: loading ? <CircularProgress size={24} /> : "Modifica storico cliente",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 0.5rem;
`;
