import { useEffect, useState } from "react";

export enum DeviceType {
  Mobile = "mobile",
  TabletPortrait = "tablet-portrait",
  TabletLandscape = "tablet-landscape",
  Desktop = "desktop",
}

const useDeviceType = (
  mobileBreakpoint: number = 768, // Mobile breakpoint
  tabletBreakpoint: number = 1024, // Tablet breakpoint
) => {
  const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.Desktop);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width <= mobileBreakpoint) {
        setDeviceType(DeviceType.Mobile);
      } else if (width <= tabletBreakpoint) {
        setDeviceType(height > width ? DeviceType.TabletPortrait : DeviceType.TabletLandscape);
      } else {
        setDeviceType(DeviceType.Desktop);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [mobileBreakpoint, tabletBreakpoint]);

  return deviceType;
};

export default useDeviceType;
