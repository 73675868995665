import * as io from "io-ts";

export const CUSTOMER_TYPES = {
  PRIVATE: "Privato",
  CONDO_ADMIN: "Amministratore di Condominio",
  CONDO: "Condomini",
  COMPANY: "Società",
  SELECT_TYPE: "Seleziona tipo cliente",
};

export const CUSTOMER_STATE = {
  POTENTIAL: "Potenziale",
};

export const customerTypeOptions = [
  { value: CUSTOMER_TYPES.PRIVATE, label: "Privato" },
  { value: CUSTOMER_TYPES.CONDO_ADMIN, label: "Amministratore di condominio" },
  { value: CUSTOMER_TYPES.CONDO, label: "Condomini" },
  { value: CUSTOMER_TYPES.COMPANY, label: "Società" },
  { value: CUSTOMER_TYPES.SELECT_TYPE, label: "Seleziona tipo cliente" },
];

export const CustomerLiteral = io.union([
  io.literal(CUSTOMER_TYPES.PRIVATE),
  io.literal(CUSTOMER_TYPES.CONDO_ADMIN),
  io.literal(CUSTOMER_TYPES.CONDO),
  io.literal(CUSTOMER_TYPES.COMPANY),
]);

export const Customer = io.type({
  name: io.union([io.string, io.undefined]),
  surname: io.union([io.string, io.undefined]),
  prefix_name_admin: io.union([io.string, io.undefined]),
  business_name: io.union([io.string, io.undefined]),
  address: io.union([io.string, io.undefined]),
  city: io.union([io.string, io.undefined]),
  province: io.union([io.string, io.undefined]),
  cf: io.union([io.string, io.undefined]),
  vat_number: io.union([io.string, io.undefined]),
  email: io.union([io.string, io.undefined]),
  sdi: io.union([io.string, io.undefined]),
  mobile_phone: io.union([io.string, io.undefined]),
  landline_phone: io.union([io.string, io.undefined]),
  website: io.union([io.string, io.undefined]),
  sector: io.union([io.string, io.undefined]),
  pec: io.union([io.string, io.undefined]),
  date_of_birth: io.union([io.string, io.undefined]),
  birth_place: io.union([io.string, io.undefined]),
  studio_name: io.union([io.string, io.undefined]),
  condo_name: io.union([io.string, io.undefined]),
  number_estate_units: io.union([io.number, io.undefined]),
  number_floors: io.union([io.number, io.undefined]),
  reference_commercial: io.union([io.string, io.undefined]),
  studio_classification: io.union([io.string, io.undefined]),
  studio_size: io.union([io.string, io.undefined]),
  condos_number: io.union([io.number, io.undefined]),
  associated_customer: io.union([io.boolean, io.undefined]),
  note: io.union([io.string, io.undefined]),
  customer_type: io.union([io.string, io.undefined]),
  customer_state: io.union([io.string, io.undefined]),
});

export const CustomerEdit = io.partial({
  name: io.string,
  surname: io.string,
  prefix_name_admin: io.string,
  business_name: io.string,
  address: io.string,
  city: io.string,
  province: io.string,
  cf: io.string,
  vat_number: io.string,
  email: io.string,
  sdi: io.string,
  mobile_phone: io.string,
  landline_phone: io.string,
  website: io.string,
  sector: io.string,
  pec: io.string,
  date_of_birth: io.string,
  birth_place: io.string,
  studio_name: io.string,
  condo_name: io.string,
  number_estate_units: io.number,
  number_floors: io.number,
  reference_commercial: io.string,
  studio_classification: io.string,
  studio_size: io.string,
  condos_number: io.number,
  associated_customer: io.boolean,
  note: io.string,
  customer_type: io.string,
});

export const CustomerResponse = io.type({
  id: io.number,
  ...Customer.props,
});

export const CustomerRegisterResponse = io.type({
  "@context": io.string,
  "@id": io.string,
  "@type": io.string,
  id: io.number,
  ...Customer.props,
});

export const CustomerCollectionResponse = io.type({
  "hydra:totalItems": io.number,
  "hydra:member": io.array(CustomerResponse),
  "hydra:view": io.union([
    io.type({
      "@id": io.string,
      "@type": io.string,
      "hydra:first": io.string,
      "hydra:last": io.string,
      "hydra:next": io.union([io.string, io.undefined]),
    }),
    io.undefined,
  ]),
});

export type CustomerType = io.TypeOf<typeof Customer>;
export type CustomerLiteralType = io.TypeOf<typeof CustomerLiteral>;
export type CustomerRegisterResponseType = io.TypeOf<typeof CustomerRegisterResponse>;
export type CustomerCollectionResponseType = io.TypeOf<typeof CustomerCollectionResponse>;
export type CustomerResponseType = io.TypeOf<typeof CustomerResponse>;
export type CustomerEditType = io.TypeOf<typeof CustomerEdit>;
