import { Pagination } from "@mui/material";
import * as io from "io-ts";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FilterBar from "../../components/Filter";
import Loading from "../../components/Loading";
import GenericTable from "../../components/Table";
import { demandFilters } from "../../constants/demand/DemandFiltersItem";
import { createDemandColumnsIcons, demandColumns } from "../../constants/demand/DemandTable";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { CustomerResponse, CustomerResponseType } from "../../models/Customer";
import {
  DemandCollectionResponse,
  DemandCollectionResponseType,
  DemandResponseType,
} from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { theme } from "../../utils/Theme";

const transformCustomerDetails = (data: CustomerResponseType) => ({
  name: data.name,
  surname: data.surname,
});

const DemandDataProvider = () => {
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const [demands, setDemands] = useState<DemandCollectionResponseType | null>(null);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filteredDemands, setFilteredDemands] = useState<DemandResponseType[]>(
    demands?.["hydra:member"] || [],
  );

  const navigate = useNavigate();
  const deviceType = useDeviceType();

  useEffect(() => {
    getDemands(`/demands?page=${currentPage}`, currentPage);
  }, [currentPage, isDeleted]);

  const handlePageChange = useCallback((_: React.ChangeEvent<unknown>, page: number) => {
    getDemands(`/demands?page=${page}`, page);
  }, []);

  const handleShowDemand = useCallback(
    (id: number) => {
      navigate(`/home/demand/${id}`);
    },
    [navigate],
  );

  const handleEditDemand = useCallback(
    (id: number) => {
      navigate(`/home/demand/edit/${id}`);
    },
    [navigate],
  );

  const handleDeleteDemand = useCallback(async (id: number) => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questa richiesta?");
    if (!confirmDelete) return;

    setLoading(true);
    try {
      await callApi("DELETE", `/demands/${id}`);
      setIsDeleted((prev) => !prev);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleNavigateToHistory = useCallback(
    (demand: DemandResponseType) => {
      navigate("/home/customer_histories", {
        state: { demandProtocol: `${demand.unique_protocol ?? ""}` },
      });
    },
    [navigate],
  );

  const getDemands = async (url: string, page: number) => {
    setLoading(true);

    try {
      const response = await callApi<DemandCollectionResponseType, undefined>(
        "GET",
        url,
        DemandCollectionResponse,
      );

      if (response) {
        const updatedDemands: DemandResponseType[] = [];

        for (const demand of response["hydra:member"]) {
          const customerId = demand.customer_id?.replace("/api/customers/", "");

          let customerDetails = null;

          if (customerId) {
            customerDetails = await getResponseById(
              "/customers",
              parseInt(customerId),
              CustomerResponse,
              transformCustomerDetails,
            );
          }

          updatedDemands.push({
            ...demand,
            customer_id: customerDetails
              ? `${customerDetails.name ?? ""} ${customerDetails.surname ?? ""}`
              : "",
          });
        }

        setDemands({
          ...response,
          "hydra:member": updatedDemands,
        });

        if (page && response) {
          setCurrentPage(page);
          setTotalPages(Math.ceil(response["hydra:totalItems"] / 10));
        }
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  const getResponseById = async <T,>(
    endpoint: string,
    id: number,
    responseSchema: io.Type<T>,
    transform: (data: T) => any,
  ): Promise<any | null> => {
    try {
      const response = await callApi<T, undefined>("GET", `${endpoint}/${id}`, responseSchema);
      return response ? transform(response) : null;
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      return null;
    }
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer>
          <FilterBar<DemandResponseType>
            data={demands?.["hydra:member"] || []}
            filters={demandFilters}
            columns={deviceType === DeviceType.Mobile ? 2 : 4}
            onFilter={setFilteredDemands}
          />
          {filteredDemands.length > 0 ? (
            <GenericTable<DemandResponseType>
              data={filteredDemands}
              columns={demandColumns}
              icons={createDemandColumnsIcons(
                handleShowDemand,
                handleEditDemand,
                handleDeleteDemand,
                handleNavigateToHistory,
              )}
              loading={loading}
              error={error?.["hydra:description"] || null}
            />
          ) : (
            <NoFoundItems>nessun risultato di ricerca</NoFoundItems>
          )}
          <PaginationContainer $ismobile={deviceType !== DeviceType.Desktop}>
            <StyledPagination
              size="medium"
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </PaginationContainer>
        </TableContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const TableContainer = styled.div`
  margin-top: 2rem;
`;

const NoFoundItems = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primary};
`;

const PaginationContainer = styled.div<{ $ismobile: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const StyledPagination = styled(Pagination)`
  && {
    .MuiPaginationItem-root {
    }
    .MuiPaginationItem-page.Mui-selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${theme.colors.primary};
      }
    }
  }
`;

export default DemandDataProvider;
