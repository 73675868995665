import { S3Client } from "@aws-sdk/client-s3";

// Configurazioni delle variabili di ambiente
const REGION = process.env.REACT_APP_AWS_S3_REGION as string;
const ACCESS_KEY = process.env.REACT_APP_AWS_KEY as string;
const SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY as string;
const BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET as string;
const DIRECTORY_BUCKET = process.env.REACT_APP_AWS_DIRECTORY_BUCKET as string;

// Configura il client S3
const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
  },
});

export const getS3ImageUrl = (path: string): string => {
  return `https://${BUCKET_NAME}.s3.${REGION}.amazonaws.com/${DIRECTORY_BUCKET}${path}`;
};

export default s3Client;
