import * as io from "io-ts";

export const TYPE_HISTORY = {
  PHONE_CALL: "Telefonata",
  APPOINTMENT: "Appuntamento",
  EMAIL: "Email",
};

export const typeHistoryOptions = [
  { value: TYPE_HISTORY.PHONE_CALL, label: "Telefonata" },
  { value: TYPE_HISTORY.APPOINTMENT, label: "Appuntamento" },
  { value: TYPE_HISTORY.EMAIL, label: "Email" },
];

export const CustomerHistory = io.type({
  customer_id: io.union([io.string, io.undefined]),
  date: io.union([io.string, io.undefined]),
  type: io.union([io.string, io.undefined]),
  result: io.union([io.string, io.undefined]),
  demand_id: io.union([io.string, io.null, io.undefined]),
  user_id: io.union([io.string, io.undefined]),
});

export const CustomerHistoryResponse = io.type({
  id: io.number,
  ...CustomerHistory.props,
});

export const CustomerHistoryRegisterResponse = io.type({
  "@context": io.string,
  "@id": io.string,
  "@type": io.string,
  id: io.number,
  ...CustomerHistory.props,
});

export const CustomerHistoryCollectionResponse = io.type({
  "hydra:totalItems": io.number,
  "hydra:member": io.array(CustomerHistoryResponse),
  "hydra:view": io.union([
    io.type({
      "@id": io.string,
      "@type": io.string,
      "hydra:first": io.string,
      "hydra:last": io.string,
      "hydra:next": io.union([io.string, io.undefined]),
    }),
    io.undefined,
  ]),
});

export type CustomerHistoryType = io.TypeOf<typeof CustomerHistory>;
export type CustomerHistoryResponseType = io.TypeOf<typeof CustomerHistoryResponse>;
export type CustomerHistoryRegisterResponseType = io.TypeOf<typeof CustomerHistoryRegisterResponse>;
export type CustomerHistoryCollectionResponseType = io.TypeOf<
  typeof CustomerHistoryCollectionResponse
>;
