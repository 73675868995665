import { FiltersType } from "../../components/Filter";
import { DemandResponseType } from "../../models/Demand";

export const demandFilters: FiltersType<DemandResponseType> = [
  {
    label: "Cliente",
    filterFn: (demand: DemandResponseType, filterValue: string | null) => {
      return demand.customer_id && filterValue
        ? demand.customer_id.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Protocollo univoco",
    filterFn: (demand: DemandResponseType, filterValue: string | null) => {
      return demand.unique_protocol && filterValue
        ? demand.unique_protocol.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Stato richiesta",
    filterFn: (demand: DemandResponseType, filterValue: string | null) => {
      return demand.demand_state && filterValue
        ? demand.demand_state.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
];
