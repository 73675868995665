import { Pagination } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FilterBar from "../../components/Filter";
import Loading from "../../components/Loading";
import GenericTable from "../../components/Table";
import { customerFilters } from "../../constants/customer/CustomerFiltersItem";
import {
  createCustomerColumnsIcons,
  customerColumns,
} from "../../constants/customer/CustomerTable";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import {
  CustomerCollectionResponse,
  CustomerCollectionResponseType,
  CustomerResponseType,
} from "../../models/Customer";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { theme } from "../../utils/Theme";

const CustomerDataProvider = () => {
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const [customers, setCustomers] = useState<CustomerCollectionResponseType | null>(null);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerResponseType[]>(
    customers?.["hydra:member"] || [],
  );

  const navigate = useNavigate();
  const deviceType = useDeviceType();

  useEffect(() => {
    getCustomers(`/customers?page=${currentPage}`, currentPage);
  }, [currentPage, isDeleted]);

  const handlePageChange = useCallback((_: React.ChangeEvent<unknown>, page: number) => {
    getCustomers(`/customers?page=${page}`, page);
  }, []);

  const handleShowCustomer = useCallback(
    (id: number) => {
      navigate(`/home/customer/${id}`);
    },
    [navigate],
  );

  const handleEditCustomer = useCallback(
    (id: number) => {
      navigate(`/home/customer/edit/${id}`);
    },
    [navigate],
  );

  const handleDeleteCustomer = useCallback(async (id: number) => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo cliente?");
    if (!confirmDelete) return;

    setLoading(true);
    try {
      await callApi("DELETE", `/customers/${id}`);

      setIsDeleted((prev) => !prev);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleNavigateToHistory = useCallback(
    (customer: CustomerResponseType) => {
      navigate("/home/customer_histories", {
        state: { customerName: `${customer.name ?? ""} ${customer.surname ?? ""}` },
      });
    },
    [navigate],
  );

  const getCustomers = async (url: string, page: number) => {
    setLoading(true);

    try {
      const response = await callApi<CustomerCollectionResponseType, undefined>(
        "GET",
        url,
        CustomerCollectionResponse,
        undefined,
      );

      if (response) {
        setCustomers(response);
      }

      if (page && response) {
        setCurrentPage(page);
        setTotalPages(Math.ceil(response["hydra:totalItems"] / 10));
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer>
          <FilterBar<CustomerResponseType>
            data={customers?.["hydra:member"] || []}
            filters={customerFilters}
            columns={deviceType === DeviceType.Mobile ? 2 : 4}
            onFilter={setFilteredCustomers}
          />
          {filteredCustomers.length > 0 ? (
            <GenericTable<CustomerResponseType>
              data={filteredCustomers}
              columns={customerColumns}
              icons={createCustomerColumnsIcons(
                handleShowCustomer,
                handleEditCustomer,
                handleDeleteCustomer,
                handleNavigateToHistory,
              )}
              loading={loading}
              error={error?.["hydra:description"] || null}
            />
          ) : (
            <NoFoundItems>nessun risultato di ricerca</NoFoundItems>
          )}
          <PaginationContainer $ismobile={deviceType !== DeviceType.Desktop}>
            <StyledPagination
              size="medium"
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </PaginationContainer>
        </TableContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const TableContainer = styled.div`
  margin-top: 2rem;
`;

const NoFoundItems = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primary};
`;

const PaginationContainer = styled.div<{ $ismobile: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const StyledPagination = styled(Pagination)`
  && {
    .MuiPaginationItem-root {
    }
    .MuiPaginationItem-page.Mui-selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${theme.colors.primary};
      }
    }
  }
`;

export default CustomerDataProvider;
