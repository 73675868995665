import { Person } from "@mui/icons-material";
import { IconButton, InputLabel, Menu, MenuItem } from "@mui/material";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { theme } from "../utils/Theme";

export type DropdownOptionType = {
  label: string;
  icon: ReactNode;
  onClick: () => void;
};

interface CustomDropdownProps {
  options: DropdownOptionType[];
  roles?: string;
  name?: string;
  surname?: string;
  textColor: string;
  showIconsOnly?: boolean;
  flexDirection?: "column" | "row";
}

const Dropdown = ({
  options,
  name,
  roles,
  surname,
  textColor,
  showIconsOnly,
  flexDirection = "column",
}: CustomDropdownProps) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleOnClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      {showIconsOnly ? (
        <ContainerIcons flexdirection={flexDirection}>
          {options.map((option, index) => (
            <StyledIcon key={index} onClick={option.onClick}>
              {option.icon}
            </StyledIcon>
          ))}
        </ContainerIcons>
      ) : (
        <>
          <ContainerIconButton>
            <Label $textcolor={textColor}>{`${name} ${surname} - ${roles}`}</Label>
            <IconButton style={{ color: theme.colors.background }} onClick={handleOnClick}>
              <Person />
            </IconButton>
          </ContainerIconButton>
          <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleOnClose}>
            {options.map((option, index) => (
              <MenuItem
                key={index}
                sx={{
                  "&:hover": {
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.white,
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  option.onClick();
                  handleOnClose();
                }}
              >
                {option.icon}
                <OptionsLabel
                  sx={{
                    "&:hover": {
                      color: theme.colors.white,
                      cursor: "pointer",
                    },
                  }}
                  $textcolor={theme.colors.text}
                >
                  {option.label}
                </OptionsLabel>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
};

const ContainerIconButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled(InputLabel)<{ $textcolor: string }>`
  && {
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes.small};
    color: ${({ $textcolor }) => $textcolor};
    padding-left: 1rem;
  }
`;

const OptionsLabel = styled(InputLabel)<{ $textcolor: string }>`
  && {
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes.medium};
    color: ${({ $textcolor }) => $textcolor};
    padding-left: 1rem;
  }
`;

const ContainerIcons = styled.div<{ flexdirection: string }>`
  display: flex;
  flex-direction: ${({ flexdirection }) => flexdirection};
  align-items: center;
  margin-top: auto;
`;

const StyledIcon = styled.div`
  cursor: pointer;
  padding-bottom: 1rem;
`;

export default Dropdown;
