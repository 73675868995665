import GroupIcon from "@mui/icons-material/Group";
import HistoryIcon from "@mui/icons-material/History";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReportIcon from "@mui/icons-material/Report";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SaveIcon from "@mui/icons-material/Save";
import { GroupItemType } from "../components/Sidebar";

export const getSidebarItems = (isAdmin: boolean): GroupItemType[] => {
  return [
    { items: [{ text: "Home", icon: <HomeIcon />, path: "/home" }] },
    // utenti
    {
      title: isAdmin ? "Utenti" : "",
      items: [
        { text: "Lista utenti", icon: <GroupIcon />, path: "/home/users" },
        ...(isAdmin
          ? [{ text: "Aggiungi utente", icon: <PersonAddIcon />, path: "/home/register" }]
          : []),
      ],
    },
    // Clienti
    {
      title: "Clienti",
      items: [
        { text: "Lista clienti", icon: <GroupIcon />, path: "/home/customer" },
        { text: "Aggiungi cliente", icon: <PersonAddIcon />, path: "/home/customer/register" },
      ],
    },
    // Richiesta
    {
      title: "Richieste",
      items: [
        { text: "Lista richieste", icon: <RequestQuoteIcon />, path: "/home/demand" },
        { text: "Aggiungi richiesta", icon: <RequestQuoteIcon />, path: "/home/demand/register" },
      ],
    },
    // Storico Cliente
    {
      title: "Storico clienti",
      items: [
        {
          text: "Lista storico clienti",
          icon: <HistoryIcon />,
          path: "/home/customer_histories",
        },
        {
          text: "Registra interazione cliente",
          icon: <SaveIcon />,
          path: "/home/customer_histories/register",
        },
      ],
    },
    // verbale sopralluogo esterno
    {
      title: "Verbale sopralluogo esterno",
      items: [
        {
          text: "Lista verbali esterni",
          icon: <ReportIcon />,
          path: "/home/ext_insp_report",
        },
        {
          text: "Aggiungi sopralluogo esterno",
          icon: <ReportIcon />,
          path: "/home/ext_insp_report/register",
        },
      ],
    },
  ];
};
