import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "../../components/Form";
import { buttonProps, inputProps } from "../../constants/customer/CustomerEdit";
import validators from "../../constants/customer/CustomerValidators";
import { ErrorInputType } from "../../constants/ErrorInput";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import {
  CUSTOMER_TYPES,
  CustomerEdit,
  CustomerEditType,
  CustomerResponse,
  CustomerResponseType,
  CustomerType,
} from "../../models/Customer";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { validateForm } from "../../utils/Validate";
import { useApiData } from "../../hooks/useApiData";

const CustomerEditDataProvider = () => {
  const { loading, error, setError, setLoading } = useContext(AppContext);
  const deviceType = useDeviceType();
  const { id } = useParams<string>();

  const [editData, setEditData] = useState<CustomerResponseType | null>(null);
  const [errorInput, setErrorInput] = useState<Record<string, ErrorInputType | null>>({
    email: null,
    name: null,
    surname: null,
    mobile_phone: null,
    address: null,
    city: null,
    province: null,
    cf: null,
    pec: null,
  });

  const { users, getUsers, loadAllData } = useApiData();
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getUsers);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    getCustomer();
  }, [id]);

  useEffect(() => {
    if (editData) {
      const validationErrors = validateForm<CustomerType>(editData, validators);
      setErrorInput(validationErrors);
      setError(null);
    }
  }, [editData, setError]);

  const getCustomer = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await callApi<CustomerResponseType, undefined>(
          "GET",
          `/customers/${id}`,
          CustomerResponse,
          undefined,
        );

        if (response) {
          const formattedData = {
            ...response,
            reference_commercial: response.reference_commercial?.replace("/api/users/", ""),
          };

          setEditData(formattedData);
        }
      } catch (error) {
        handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    setEditData((prevState) =>
      prevState
        ? {
            ...prevState,
            [name]: type === "checkbox" ? checked : type === "number" ? Number(value) : value,
          }
        : null,
    );
  };

  const updateCustomer = useCallback(async (customer: CustomerEditType) => {
    if (editData) {
      const validationErrors = validateForm<CustomerType>(editData, validators);
      if (validationErrors && Object.keys(validationErrors).length > 0) {
        setErrorInput(validationErrors);
        return;
      }
    }

    setError(null);
    setLoading(true);

    try {
      const body: CustomerEditType = {
        name: customer.name,
        surname: customer.surname,
        prefix_name_admin: customer.prefix_name_admin,
        business_name: customer.business_name,
        address: customer.address,
        city: customer.city,
        province: customer.province,
        cf: customer.cf,
        vat_number: customer.vat_number,
        email: customer.email,
        sdi: customer.sdi,
        mobile_phone: customer.mobile_phone,
        landline_phone: customer.landline_phone,
        website: customer.website,
        sector: customer.sector,
        pec: customer.pec,
        date_of_birth: customer.date_of_birth,
        birth_place: customer.birth_place,
        studio_name: customer.studio_name,
        condo_name: customer.condo_name,
        number_estate_units: customer.number_estate_units,
        number_floors: customer.number_floors,
        reference_commercial: customer.reference_commercial,
        studio_classification: customer.studio_classification,
        studio_size: customer.studio_size,
        condos_number: customer.condos_number,
        associated_customer: customer.associated_customer,
        note: customer.note,
        customer_type: customer.customer_type,
      };

      const response = await callApi<CustomerResponseType, CustomerEditType>(
        "PUT",
        `/customers/${id}`,
        CustomerResponse,
        CustomerEdit,
        {
          ...body,
          reference_commercial: `/api/users/${body.reference_commercial}`,
        },
      );

      if (response) {
        const formattedData = {
          ...response,
          reference_commercial: response.reference_commercial?.replace("/api/users/", ""),
        };

        setEditData(formattedData);
        alert("Cliente aggiornato!");
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (editData) {
      const validationErrors = validateForm<CustomerType>(editData, validators);
      if (validationErrors && Object.keys(validationErrors).length > 0) {
        setErrorInput(validationErrors);
        return;
      }

      setError(null);
      await updateCustomer(editData);
    }
  };

  return (
    <>
      {editData && (
        <Form
          title="Modifica Cliente"
          onSubmit={handleSubmit}
          inputProps={inputProps(
            users,
            editData,
            errorInput,
            error,
            deviceType === DeviceType.Mobile,
            handleOnChange,
          )}
          buttonProps={buttonProps(
            dataLoading,
            editData.customer_type === CUSTOMER_TYPES.SELECT_TYPE,
            loading,
            error,
          )}
        />
      )}
    </>
  );
};

export default CustomerEditDataProvider;
