import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Form from "../../components/Form";
import { ErrorInputType } from "../../constants/ErrorInput";
import { buttonProps, inputProps } from "../../constants/user/UserRegister";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { ROLES, UserRegisterType } from "../../models/User";
import { validateForm } from "../../utils/Validate";
import { validators } from "../../constants/user/UserRegisterValidators";
import { handleApiError } from "../../utils/ErrorUtils";
import { ErrorType } from "../../models/Error";

const initialFormData: UserRegisterType = {
  email: "",
  password: "",
  confirm_password: "",
  name: "",
  surname: "",
  roles: [ROLES.SELECT_ROLE],
  mobile_phone: "",
  address: "",
  city: "",
  province: "",
  date_of_birth: "",
  cf: "",
};

const UserRegisterDataProvider = () => {
  const deviceType = useDeviceType();
  const { loading, error, register, setError } = useContext(AppContext);
  const [errorInput, setErrorInput] = useState<Record<string, ErrorInputType | null>>({
    email: null,
    password: null,
    name: null,
    surname: null,
    mobile_phone: null,
    address: null,
    city: null,
    province: null,
    date_of_birth: null,
    cf: null,
  });
  const [formData, setFormData] = useState<UserRegisterType>(initialFormData);

  useEffect(() => {
    const validationErrors = validateForm<UserRegisterType>(formData, validators);
    validationErrors && setErrorInput(validationErrors);

    setError(null);
  }, [formData, setError]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "roles" ? [value] : value,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent<Element>) => {
    e.preventDefault();

    const validationErrors = validateForm<UserRegisterType>(formData, validators);
    if (validationErrors && Object.keys(validationErrors).length > 0) {
      setErrorInput(validationErrors);
      return;
    }

    setError(null);

    const data: UserRegisterType = {
      email: formData.email,
      password: formData.password,
      confirm_password: formData.confirm_password,
      name: formData.name,
      surname: formData.surname,
      roles: formData.roles,
      mobile_phone: formData.mobile_phone,
      address: formData.address,
      city: formData.city,
      province: formData.province,
      date_of_birth: formData.date_of_birth,
      cf: formData.cf,
    };

    try {
      await register(data);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    }
  };

  return (
    <Container>
      <Form
        inputProps={inputProps(
          formData,
          errorInput,
          error,
          deviceType === DeviceType.Mobile,
          handleOnChange,
        )}
        buttonProps={buttonProps(loading, error)}
        onSubmit={handleOnSubmit}
      />
    </Container>
  );
};

const Container = styled.div`
  justify-self: center;
`;

export default UserRegisterDataProvider;
