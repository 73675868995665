import axios from "axios";
import { ErrorType } from "../models/Error";

export type SetErrorFunction = (error: ErrorType | null) => void;

export const handleApiError = (
  error: ErrorType,
  setError: SetErrorFunction,
  defaultErrorMessage: string,
) => {
  if (axios.isAxiosError(error) && error.response) {
    const errorData = error.response.data;
    setError(errorData);
  } else {
    setError({ detail: defaultErrorMessage });
  }
};
