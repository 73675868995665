import { DemandType } from "../../models/Demand";
import { Validators } from "../../utils/Validate";

const validators: Validators<DemandType> = {
  province: (value) => {
    if (typeof value === "string" && (value.length < 2 || value.length > 3)) {
      return "invalid";
    }
    return null;
  },
  unique_protocol: (value) => {
    const protocolRegex = /^[0-9]{4}[A-Z]{3}[0-9]{3}$/;

    if (typeof value === "string" && !protocolRegex.test(value)) {
      return "invalid";
    }

    return null;
  },
};

export default validators;
