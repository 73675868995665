import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Column, IconType } from "../../components/Table";
import { CustomerHistoryResponseType, CustomerHistoryType } from "../../models/CustomerHistory";
import { theme } from "../../utils/Theme";

export const customerHistoryColumns: Column<CustomerHistoryType>[] = [
  { header: "Tipologia", accessor: "type" },
  { header: "Cliente", accessor: "customer_id" },
  { header: "Data", accessor: "date" },
  { header: "Richiesta", accessor: "demand_id" },
];

export const createCustomerHistoryColumnsIcons = (
  onShow: (id: number) => void,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
): IconType<CustomerHistoryResponseType>[] => [
  {
    icon: <VisibilityIcon />,
    color: theme.colors.background,
    titleTooltip: "Visualizza interazione",
    onClick: (customer: CustomerHistoryResponseType) => onShow(customer.id),
  },
  {
    icon: <EditIcon />,
    color: theme.colors.background,
    titleTooltip: "Modifica interazione",
    onClick: (customer: CustomerHistoryResponseType) => onEdit(customer.id),
  },
  {
    icon: <DeleteIcon />,
    color: theme.colors.error,
    titleTooltip: "Rimuovi interazione",
    onClick: (customer: CustomerHistoryResponseType) => onDelete(customer.id),
  },
];
