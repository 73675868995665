import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column, IconType } from "../../components/Table";
import { UserResponseType } from "../../models/User";
import { theme } from "../../utils/Theme";

export const userColumns: Column<UserResponseType>[] = [
  { header: "Nome", accessor: "name" },
  { header: "Cognome", accessor: "surname" },
  { header: "Email", accessor: "email" },
  { header: "Ruoli", accessor: "roles" },
];

export const createUserColumnsIcons = (
  onShow: (id: number) => void,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
): IconType<UserResponseType>[] => [
  {
    icon: <VisibilityIcon />,
    color: theme.colors.background,
    titleTooltip: "Visualizza utente",
    onClick: (user: UserResponseType) => onShow(user.id),
  },
  {
    icon: <EditIcon />,
    color: theme.colors.background,
    titleTooltip: "Modifica utente",
    onClick: (user: UserResponseType) => onEdit(user.id),
  },
  {
    icon: <DeleteIcon />,
    color: theme.colors.error,
    titleTooltip: "Rimuovi utente",
    onClick: (user: UserResponseType) => onDelete(user.id),
  },
];
