import styled from "styled-components";
import { theme } from "../../utils/Theme";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import UserLoginDataProvider from "../../data-providers/user/UserLoginDataProvider";
import PngImage from "../../components/PngImage";

const UserLoginPage = () => {
  const deviceType = useDeviceType();
  return (
    <Container devicetype={deviceType}>
      <UserLoginDataProvider />
      {deviceType !== DeviceType.TabletPortrait && deviceType !== DeviceType.Mobile && (
        <ImageContainer>
          <PngImage src="logo/icaro_world_logo.png" width={100} height={100} />
        </ImageContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ devicetype: DeviceType }>`
  display: grid;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  background-color: ${theme.colors.backgroundLight};
  /* Default for desktop */
  grid-template-columns: 1fr 1fr;

  /* Adjust layout for tablets */
  ${({ devicetype }) =>
    devicetype === DeviceType.TabletPortrait &&
    `
    grid-template-columns: 1fr;
  `}

  /* Adjust layout for mobile */
${({ devicetype }) =>
    devicetype === DeviceType.Mobile &&
    `
    grid-template-columns: 1fr;
    padding: 1rem;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: ${theme.colors.white};
`;

export default UserLoginPage;
