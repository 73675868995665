import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DynamicInputGroup } from "../../components/DynamicInput";
import Form, { InputField } from "../../components/Form";
import Loading from "../../components/Loading";
import { buttonProps, inputProps } from "../../constants/external-report/ExtInspReportEdit";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { ErrorType } from "../../models/Error";
import {
  BalconiesType,
  ExtInspReport,
  ExtInspReportResponse,
  ExtInspReportResponseType,
  ExtInspReportType,
} from "../../models/ExtInspReport";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";

const ExtInspReportEditDataProvider = () => {
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const deviceType = useDeviceType();
  const { users, demands, getUsers, getDemands, loadAllData } = useApiData();
  const { id } = useParams<string>();

  const [editData, setEditData] = useState<ExtInspReportType | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [dynamicInputGroups, setDynamicInputGroups] = useState<{
    [key: string]: DynamicInputGroup[];
  }>({});
  const [quantities, setQuantities] = useState<{
    [groupIndex: number]: { [fieldIndex: number]: number[] };
  }>({});

  useEffect(() => {
    setError(null);
  }, [editData]);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getUsers);
        await loadAllData(getDemands);
        await getExtInspReport();
      } catch (e) {
        handleApiError(error as ErrorType, setError, "Error loading data");
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const updateEditData = () => {
      if (!editData) return;

      const updatedEditData = { ...editData };

      if (dynamicInputGroups["balconi"]) {
        Object.entries(quantities).forEach(([groupIndexStr, groupQuantities]) => {
          const groupIndex = parseInt(groupIndexStr);
          const balconyKey = `balcone_${groupIndex + 1}`;

          if (!updatedEditData.balconies) updatedEditData.balconies = {};
          if (!updatedEditData.balconies[balconyKey]) {
            updatedEditData.balconies[balconyKey] = {
              pavimenti: [],
              parapetto_muratura: [],
              frontalini: [],
              ringhiere: [],
            };
          }

          Object.entries(groupQuantities).forEach(([fieldIndexStr, quantity]) => {
            const fieldIndex = parseInt(fieldIndexStr);
            const fieldName = dynamicInputGroups["balconi"]?.[groupIndex]?.fields?.[fieldIndex]
              ?.name as keyof BalconiesType;
            const currentFieldValue =
              dynamicInputGroups["balconi"]?.[groupIndex]?.fields?.[fieldIndex]?.value;

            if (fieldName && updatedEditData.balconies) {
              // Inizializza un array nuovo e vuoto per il campo
              const updatedValues = Array(Object.keys(groupQuantities).length).fill([]);

              if (currentFieldValue !== undefined && currentFieldValue !== "") {
                // Aggiungi il nuovo valore al relativo indice
                updatedValues[fieldIndex] = Array(quantity.length).fill(currentFieldValue);
              }

              // Sostituisci i valori del campo con il nuovo array
              updatedEditData.balconies[balconyKey][fieldName] = updatedValues;
            }
          });
        });
      }

      setEditData(updatedEditData);
    };

    updateEditData();
  }, [quantities, dynamicInputGroups]);

  useEffect(() => {
    const addNewValuesToFormData = () => {
      if (!editData) return;

      const updatedEditData = { ...editData };

      if (dynamicInputGroups["balconi"]) {
        Object.entries(quantities).forEach(([groupIndexStr, groupQuantities]) => {
          const groupIndex = parseInt(groupIndexStr);
          const balconyKey = `balcone_${groupIndex + 1}`;

          if (!updatedEditData.balconies) updatedEditData.balconies = {};
          if (!updatedEditData.balconies[balconyKey]) {
            updatedEditData.balconies[balconyKey] = {
              pavimenti: [],
              parapetto_muratura: [],
              frontalini: [],
              ringhiere: [],
            };
          }

          Object.entries(groupQuantities).forEach(([fieldIndexStr, quantity]) => {
            const fieldIndex = parseInt(fieldIndexStr);
            const fieldName = dynamicInputGroups["balconi"]?.[groupIndex]?.fields?.[fieldIndex]
              ?.name as keyof BalconiesType;
            const currentFieldValue =
              dynamicInputGroups["balconi"]?.[groupIndex]?.fields?.[fieldIndex]?.value;

            if (fieldName && updatedEditData.balconies) {
              const existingValues = updatedEditData.balconies[balconyKey][fieldName] || [];

              // Aggiungi nuovi valori solo se non sono già presenti
              if (currentFieldValue && currentFieldValue !== "") {
                if (!existingValues[fieldIndex]) {
                  existingValues[fieldIndex] = Array(quantity.length).fill(currentFieldValue);
                } else {
                  const newValues = Array(quantity.length).fill(currentFieldValue);

                  // Verifica che i nuovi valori non siano duplicati
                  const mergedValues = [
                    ...existingValues[fieldIndex].filter((val) => val !== currentFieldValue),
                    ...newValues,
                  ];

                  existingValues[fieldIndex] = mergedValues;
                }
              }

              // Aggiorna il campo unendo i valori esistenti con quelli nuovi
              updatedEditData.balconies[balconyKey][fieldName] = existingValues;
            }
          });
        });
      }

      setEditData(updatedEditData);
    };

    addNewValuesToFormData();
  }, [quantities, dynamicInputGroups]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, fieldIndex?: number) => {
    const { name, value, type, checked } = e.target;

    setEditData((prev) =>
      prev
        ? {
            ...prev,
            [name]: type === "checkbox" ? checked : type === "number" ? Number(value) : value,
          }
        : null,
    );
  };

  const handleMultiSelectChange = (name: string, selectedValues: string[]) => {
    setEditData((prev) => (prev ? { ...prev, [name]: selectedValues } : prev));
  };

  const handleOnChangeQuantity = (groupIndex: number, fieldIndex: number, quantity: number[]) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [groupIndex]: {
        ...prevQuantities[groupIndex],
        [fieldIndex]: quantity,
      },
    }));
  };

  const handleOnDynamicInputChange = (
    groupTitle: string,
    groupIndex: number,
    fieldIndex: number,
    value: string | string[] | number | boolean,
  ) => {
    const fieldName = dynamicInputGroups[groupTitle]?.[groupIndex]?.fields?.[fieldIndex]?.name;
    if (!fieldName) return;

    setDynamicInputGroups((prevGroups) => {
      const updatedGroups = { ...prevGroups };
      const field = updatedGroups[groupTitle]?.[groupIndex]?.fields?.[fieldIndex];
      if (field) field.value = value;
      return updatedGroups;
    });

    if (groupTitle === "lato facciata") {
      setEditData((prevFormData) => {
        if (!prevFormData) return prevFormData;

        const updatedFormData = { ...prevFormData };

        updatedFormData.sides = updatedFormData.sides || [];
        updatedFormData.sides[groupIndex] = {
          ...updatedFormData.sides[groupIndex],
          [fieldName]: value,
        };

        return updatedFormData;
      });
    }
  };

  const addField = (groupTitle: string, groupIndex: number, fieldIndex: number) => {
    setDynamicInputGroups((prevGroups) => {
      const targetGroup = prevGroups[groupTitle]?.[groupIndex];
      if (!targetGroup) return prevGroups;

      const duplicatedField = { ...targetGroup.fields[fieldIndex], value: "" };

      if (!("name" in duplicatedField) || !duplicatedField.name) {
        return prevGroups;
      }

      const updatedFields = [...targetGroup.fields, duplicatedField];

      const updatedGroup = { ...targetGroup, fields: updatedFields as InputField[] };

      return {
        ...prevGroups,
        [groupTitle]: prevGroups[groupTitle].map((g, idx) =>
          idx === groupIndex ? updatedGroup : g,
        ),
      };
    });

    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };

      if (!newQuantities[groupIndex]) {
        newQuantities[groupIndex] = {};
      }

      if (!newQuantities[groupIndex][fieldIndex]) {
        newQuantities[groupIndex][fieldIndex] = [];
      }

      return newQuantities;
    });
  };

  const removeField = (groupTitle: string, subGroupIndex: number, fieldIndex: number) => {
    setDynamicInputGroups((prevGroups) => {
      const targetGroup = prevGroups[groupTitle]?.[subGroupIndex];
      if (!targetGroup || targetGroup.fields.length <= 1) return prevGroups;

      const updatedFields = targetGroup.fields.filter((_, index) => index !== fieldIndex);
      const updatedGroup = { ...targetGroup, fields: updatedFields };

      return {
        ...prevGroups,
        [groupTitle]: prevGroups[groupTitle].map((value, index) =>
          index === subGroupIndex ? updatedGroup : value,
        ),
      };
    });

    setEditData((prevState) => {
      if (!prevState) return prevState;

      const newState = { ...prevState };
      const balconyKey = `balcone_${subGroupIndex + 1}`;
      const fieldName = dynamicInputGroups[groupTitle]?.[subGroupIndex]?.fields?.[fieldIndex]?.name;
      const fieldKey = fieldName as keyof BalconiesType;

      if (fieldKey && newState.balconies && newState.balconies[balconyKey]) {
        const updatedArray = [...(newState.balconies[balconyKey][fieldKey] || [])];

        if (updatedArray[fieldIndex]) {
          updatedArray.splice(fieldIndex, 1);
        }

        newState.balconies[balconyKey] = {
          ...newState.balconies[balconyKey],
          [fieldKey]: updatedArray.length > 0 ? updatedArray : [],
        };
      }

      return newState;
    });

    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      const fieldQuantities = updatedQuantities[subGroupIndex];

      if (fieldQuantities) {
        const newFieldQuantities = Object.keys(fieldQuantities)
          .filter((key) => parseInt(key) !== fieldIndex)
          .reduce((acc, key) => {
            const newKey = parseInt(key) > fieldIndex ? parseInt(key) - 1 : parseInt(key);
            acc[newKey] = fieldQuantities[parseInt(key)];
            return acc;
          }, {} as { [index: number]: number[] });

        updatedQuantities[subGroupIndex] = newFieldQuantities;
      }

      return updatedQuantities;
    });
  };

  const addFieldGroup = (groupTitle: string) => {
    const groups = inputProps(
      editData!,
      users,
      demands,
      error,
      deviceType === DeviceType.Mobile,
      handleOnChange,
      handleMultiSelectChange,
    );

    const fieldGroup = groups.find((g) =>
      g.groups?.some((sg) => sg.isDynamicInput && sg.groupTitle === groupTitle),
    );

    if (!fieldGroup) return;

    const currentGroup = fieldGroup.groups?.find((g) => g.groupTitle === groupTitle);

    if (currentGroup?.subGroups) {
      const currentIndex = dynamicInputGroups[groupTitle]?.length ?? 0;

      // Verifica se ci sono sottogruppi da aggiungere
      if (currentIndex < currentGroup.subGroups.length) {
        const nextSubGroup = currentGroup.subGroups[currentIndex];

        // Aggiunge il sottogruppo ai gruppi dinamici
        setDynamicInputGroups((prev) => ({
          ...prev,
          [groupTitle]: [
            ...(prev[groupTitle] || []),
            {
              ...nextSubGroup,
              fields: nextSubGroup.fields || [],
            },
          ],
        }));

        // Aggiorna i dati per aggiungere un nuovo lato facciata o balcone
        setEditData((prev) => {
          if (!prev) return prev;

          if (groupTitle === "lato facciata") {
            if (prev.sides && prev.sides.length > 0) {
              return prev;
            }

            const updatedSides = [
              ...(prev.sides || []),
              { mt: "", ground: "", border: "", h_interplane: "" },
            ];

            return { ...prev, sides: updatedSides };
          } else if (groupTitle === "balconi") {
            const nextBalconyKey = `balcone_${Object.keys(prev.balconies || {}).length + 1}`;

            return {
              ...prev,
              balconies: {
                ...(prev.balconies || {}),
                [nextBalconyKey]: {
                  pavimenti: [],
                  parapetto_muratura: [],
                  frontalini: [],
                  ringhiere: [],
                },
              },
            };
          }

          return prev;
        });
      }
    }
  };

  const removeFieldGroup = (groupTitle: string, groupIndex: number) => {
    // Aggiorna i gruppi dinamici rimuovendo il gruppo specifico in base all'indice
    setDynamicInputGroups((prev) => ({
      ...prev,
      [groupTitle]: prev[groupTitle]
        .filter((_, idx) => idx !== groupIndex) // Rimuove il gruppo all'indice specificato
        .map((group, idx) => ({
          ...group,
          title: groupTitle === "lato facciata" ? `L${idx + 1}` : `Balcone ${idx + 1}`,
        })),
    }));

    setEditData((prevState) => {
      if (!prevState) return prevState;

      const updatedFormData = { ...prevState };

      if (groupTitle === "lato facciata") {
        updatedFormData.sides = updatedFormData.sides.filter((_, idx) => idx !== groupIndex);

        // Se non ci sono più lati facciata, imposta sides in un array vuoto
        if (updatedFormData.sides.length === 0) {
          updatedFormData.sides = [];
        }
      } else if (groupTitle === "balconi") {
        // chiave del balcone da rimuovere
        const balconyKey = `balcone_${groupIndex + 1}`;

        if (updatedFormData.balconies && updatedFormData.balconies[balconyKey]) {
          // Rimuovi il balcone specifico
          delete updatedFormData.balconies[balconyKey];
        }

        if (!updatedFormData.balconies || Object.keys(updatedFormData.balconies).length === 0) {
          updatedFormData.balconies = null;
        } else {
          // Riorganizza gli indici dei balconi rimanenti
          updatedFormData.balconies = Object.keys(updatedFormData.balconies).reduce(
            (acc, key, idx) => {
              const newKey = `balcone_${idx + 1}`; // Nuovo indice per il balcone
              acc[newKey] = updatedFormData.balconies![key];
              return acc;
            },
            {} as Record<string, BalconiesType>,
          );
        }
      }

      return updatedFormData;
    });

    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      delete updatedQuantities[groupIndex];
      return updatedQuantities;
    });
  };

  const getExtInspReport = async () => {
    setLoading(true);
    try {
      const response = await callApi<ExtInspReportResponseType, undefined>(
        "GET",
        `/ext_insp_reports/${id}`,
        ExtInspReportResponse,
      );

      if (response) {
        // Filtra tutte le chiavi camelCase
        const formattedData = Object.fromEntries(
          Object.entries(response).filter(([key]) => !/[A-Z]/.test(key)),
        );

        if (typeof formattedData.commercial_user_id === "string") {
          formattedData.commercial_user_id = formattedData.commercial_user_id.replace(
            "/api/users/",
            "",
          );
        }

        if (typeof formattedData.demand_id === "string") {
          formattedData.demand_id = formattedData.demand_id.replace("/api/demands/", "");
        }

        setEditData(formattedData as ExtInspReportType);
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  const updateExtInspReport = useCallback(
    async (report: ExtInspReportType) => {
      setError(null);
      setLoading(true);

      const filterBalconies = (
        balconies: Record<string, BalconiesType> | null,
      ): Record<string, BalconiesType> | null => {
        if (!balconies) return null;

        const filteredBalconies = Object.fromEntries(
          Object.entries(balconies)
            .map(([key, fields]) => {
              const filteredFields = Object.fromEntries(
                Object.entries(fields).map(([fieldKey, values]) => [
                  fieldKey,
                  Array.isArray(values)
                    ? values.filter(
                        (arr) =>
                          Array.isArray(arr) && arr.some((val) => val !== "" && val !== undefined),
                      )
                    : [],
                ]),
              ) as BalconiesType;

              return Object.values(filteredFields).some((field) => field.length > 0)
                ? [key, filteredFields]
                : null;
            })
            .filter((entry): entry is [string, BalconiesType] => entry !== null), // Rimuove balconi vuoti
        );

        return Object.keys(filteredBalconies).length > 0 ? filteredBalconies : null; // Restituisci null se nessun balcone è valido
      };

      const balconiesData = filterBalconies(report.balconies || null);

      const updatedReport: ExtInspReportType = {
        ...report,
        balconies: balconiesData || null,
        commercial_user_id: `/api/users/${report.commercial_user_id}`,
        demand_id: `/api/demands/${report.demand_id}`,
      };

      try {
        await callApi<ExtInspReportResponseType, ExtInspReportType>(
          "PUT",
          `/ext_insp_reports/${id}`,
          ExtInspReportResponse,
          ExtInspReport,
          updatedReport,
        );
        alert("Report sopralluogo esterno aggiornato con successo!");
      } catch (error) {
        handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      } finally {
        setLoading(false);
      }
    },
    [id],
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (editData) {
      await updateExtInspReport(editData);
    }
  };

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        editData && (
          <Form
            title="Modifica sopralluogo esterno"
            onSubmit={handleSubmit}
            inputProps={inputProps(
              editData,
              users,
              demands,
              error,
              deviceType === DeviceType.Mobile,
              handleOnChange,
              handleMultiSelectChange,
            )}
            buttonProps={buttonProps(dataLoading, loading, error ?? undefined)}
            dynamicInputProps={{
              dynamicInputGroups,
              mode: "edit",
              quantities,
              onChangeDynamicInput: handleOnDynamicInputChange,
              onChangeQuantity: handleOnChangeQuantity,
              addFieldGroup,
              removeFieldGroup,
              addField,
              removeField,
            }}
          />
        )
      )}
    </>
  );
};

export default ExtInspReportEditDataProvider;
