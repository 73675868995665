import { InputGroup } from "../../components/Form";
import { UserResponseType } from "../../models/User";
import { formatDate, formatRoles } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";

export const inputProps = (currentUser: UserResponseType | null, resize: boolean): InputGroup[] => {
  return currentUser
    ? [
        {
          fields: [
            {
              label: "Nome",
              type: "text",
              value: currentUser.name || "",
              textcolor: theme.colors.secondary,
            },
            {
              label: "Cognome",
              type: "text",
              value: currentUser.surname || "",
              textcolor: theme.colors.secondary,
            },
            {
              label: "Email",
              type: "text",
              value: currentUser.email || "",
              textcolor: theme.colors.secondary,
            },
            {
              label: "Ruoli",
              type: "text",
              value: formatRoles(currentUser.roles),
              textcolor: theme.colors.secondary,
            },
            {
              label: "Tel",
              type: "text",
              value: currentUser.mobile_phone || "",
              textcolor: theme.colors.secondary,
            },
            {
              label: "Indirizzo",
              type: "text",
              value: currentUser.address || "",
              textcolor: theme.colors.secondary,
            },
            {
              label: "Città",
              type: "text",
              value: currentUser.city || "",
              textcolor: theme.colors.secondary,
            },
            {
              label: "Provincia",
              type: "text",
              value: currentUser.province,
              textcolor: theme.colors.secondary,
            },
            {
              label: "Data di nascita",
              type: "text",
              value: currentUser.date_of_birth ? formatDate(currentUser.date_of_birth) : "",
              textcolor: theme.colors.secondary,
            },
            {
              label: "Codice Fiscale",
              type: "text",
              value: currentUser.cf,
              textcolor: theme.colors.secondary,
            },
          ],
          inputColumns: resize ? 1 : 2,
        },
      ]
    : [];
};
