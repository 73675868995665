import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Form from "../../components/Form";
import {
  buttonProps,
  initialFormData,
  inputProps,
} from "../../constants/customer-histories/CustomerHistoryRegister";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import {
  CustomerHistory,
  CustomerHistoryRegisterResponse,
  CustomerHistoryRegisterResponseType,
  CustomerHistoryType,
} from "../../models/CustomerHistory";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";

const CustomerHistoryRegisterDataProvider = () => {
  const deviceType = useDeviceType();
  const { loading, error, setError, setLoading } = useContext(AppContext);
  const { customers, users, demands, getCustomers, getUsers, getDemands, loadAllData } =
    useApiData();

  const [formData, setFormData] = useState<CustomerHistoryType>(initialFormData);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getCustomers);
        await loadAllData(getUsers);
        await loadAllData(getDemands);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    setError(null);
  }, [formData, setError]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    const updatedValue = type === "checkbox" ? checked : type === "number" ? Number(value) : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent<Element>) => {
    e.preventDefault();

    setError(null);
    setLoading(true);

    try {
      await callApi<CustomerHistoryRegisterResponseType, CustomerHistoryType>(
        "POST",
        "/customer_histories",
        CustomerHistoryRegisterResponse,
        CustomerHistory,
        {
          ...formData,
          customer_id: `/api/customers/${formData.customer_id}`,
          user_id: `/api/users/${formData.user_id}`,
          demand_id: `/api/demands/${formData.demand_id}`,
        },
      );

      alert("Interazione creata con successo!");
      setFormData(initialFormData);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form
        inputProps={inputProps(
          formData,
          customers,
          users,
          demands,
          error,
          deviceType === DeviceType.Mobile,
          handleOnChange,
        )}
        buttonProps={buttonProps(dataLoading, loading, error ?? undefined)}
        onSubmit={handleOnSubmit}
      />
    </Container>
  );
};

const Container = styled.div`
  justify-self: center;
`;

export default CustomerHistoryRegisterDataProvider;
