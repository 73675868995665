import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "../../components/Form";
import Loading from "../../components/Loading";
import { inputProps } from "../../constants/customer/CustomerProfile";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { CustomerResponse, CustomerResponseType, CustomerType } from "../../models/Customer";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { useApiData } from "../../hooks/useApiData";

const CustomerProfileDataProvider = () => {
  const { loading, error, setError, setLoading } = useContext(AppContext);
  const deviceType = useDeviceType();
  const { id } = useParams();
  const { users, getUsers, loadAllData } = useApiData();

  const [customer, setCustomer] = useState<CustomerType | null>(null);

  const getFields = <T extends { id: number }>(
    collection: T[] | null,
    id: string | null,
    fields: (keyof T)[],
  ): string => {
    if (!collection || !id) return "";
    const entity = collection.find((item) => item.id === Number(id));

    if (!entity) return "";

    return fields.map((field) => (entity[field] ? String(entity[field]) : "")).join(" ");
  };

  const userFullName = getFields(
    users ? users["hydra:member"] : null,
    customer?.reference_commercial ?? null,
    ["name", "surname"],
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        await loadAllData(getUsers);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (id) {
      getCustomer(id);
    }
  }, []);

  const getCustomer = async (id: string) => {
    setLoading(true);

    try {
      const response = await callApi<CustomerResponseType, undefined>(
        "GET",
        `/customers/${id}`,
        CustomerResponse,
        undefined,
      );

      if (response) {
        const formattedData = {
          ...response,
          reference_commercial: response.reference_commercial?.replace("/api/users/", ""),
        };

        setCustomer(formattedData);
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        customer && (
          <Form
            title="Profilo Cliente"
            inputProps={inputProps(userFullName, customer, deviceType === DeviceType.Mobile)}
            onSubmit={() => {}}
          />
        )
      )}
    </>
  );
};

export default CustomerProfileDataProvider;
