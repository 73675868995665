import { InputGroup } from "../../components/Form";
import { CustomerHistoryType } from "../../models/CustomerHistory";
import { formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";

export const inputProps = (
  customerHistory: CustomerHistoryType,
  customerFullName: string,
  userFullName: string,
  demandId: string,
  resize: boolean,
): InputGroup[] => {
  return [
    {
      fields: [
        {
          value: userFullName || "",
          label: "Commerciale di riferimento",
          name: "user_id",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: customerFullName || "",
          label: "ID Cliente",
          name: "customer_id",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demandId || "",
          label: "ID Richiesta",
          name: "demand_id",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: customerHistory.date ? formatDate(customerHistory.date) : "",
          label: "Data",
          name: "date",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: customerHistory.type || "",
          label: "Tipologia",
          name: "type",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: customerHistory.result || "",
          label: "Risultato",
          name: "result",
          type: "text",
          textcolor: theme.colors.secondary,
        },
      ],
      inputColumns: resize ? 1 : 2,
    },
  ];
};
