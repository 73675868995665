export const theme = {
  colors: {
    primary: "#00B5E2",
    secondary: "#004B87",
    text: "#041C2C", // colore del testo principale
    background: "#53565A",
    backgroundLight: "#F3F5FB",
    error: "#d32f2f",
    white: "#ffffff",
    success: "#4CAF50",
  },
  fontWeights: {
    light: 300,
    semibold: 600,
  },
  fontSizes: {
    small: "12px",
    medium: "16px",
    large: "20px",
    xlarge: "32px",
  },
  fonts: {
    primary: "Lato, sans-serif",
  },
};
