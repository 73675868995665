import { AppBar, Toolbar } from "@mui/material";
import { ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { dropdownOptions } from "../constants/DropdownOptions";
import { AppContext } from "../context/AppContext";
import { formatRoles } from "../utils/Formatters";
import { theme } from "../utils/Theme";
import Dropdown from "./Dropdown";

interface NavbarProps {
  title: string;
  icon?: ReactNode;
  menuIcon?: React.ReactNode;
  selectedItem: string;
}

export const Navbar = ({ icon, selectedItem }: NavbarProps) => {
  const { userDecodeToken, userLogged, logout, getUserById } = useContext(AppContext);
  const navigate = useNavigate();

  const handleProfileClick = () => {
    if (userLogged) {
      getUserById(userLogged.id);
      navigate(`/home/users/${userLogged.id}`);
    }
  };

  return (
    <StyledAppBar>
      <StyledToolbar>
        {icon && icon}
        <Label>{selectedItem}</Label>
        <Dropdown
          options={dropdownOptions(handleProfileClick, logout)}
          name={userLogged?.name ?? ""}
          surname={userLogged?.surname ?? ""}
          roles={formatRoles(userDecodeToken?.roles ?? [])}
          textColor={theme.colors.primary}
        />
      </StyledToolbar>
    </StyledAppBar>
  );
};

const StyledToolbar = styled(Toolbar)`
  && {
    border-radius: 10px;
    background-color: ${theme.colors.white};
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
`;

const Label = styled.label`
  font-family: ${theme.fonts.primary};
  font-size: ${theme.fontSizes.medium};
  font-weight: ${theme.fontWeights.semibold};
  flex-grow: 1;
  text-align: center;
  color: ${theme.colors.primary};
`;

const StyledAppBar = styled(AppBar)`
  && {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    background-color: transparent;
    border-radius: 1rem;
    box-shadow: none;
  }
`;
