import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import { CustomerCollectionResponse, CustomerCollectionResponseType } from "../models/Customer";
import { DemandCollectionResponse, DemandCollectionResponseType } from "../models/Demand";
import { ErrorType } from "../models/Error";
import { UserResponseCollection, UserResponseCollectionType } from "../models/User";
import { callApi } from "../services/ApiServices";
import { handleApiError } from "../utils/ErrorUtils";

export const useApiData = () => {
  const { loading, setLoading, setError } = useContext(AppContext);
  const [customers, setCustomers] = useState<CustomerCollectionResponseType | null>(null);
  const [users, setUsers] = useState<UserResponseCollectionType | null>(null);
  const [demands, setDemands] = useState<DemandCollectionResponseType | null>(null);

  // Funzione per caricare tutti i dati
  const loadAllData = async (getData: (page: number) => Promise<boolean>) => {
    let page = 1;
    setLoading(true);

    try {
      while (true) {
        const hasMore = await getData(page);
        if (!hasMore) break;
        page++;
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  const getCustomers = async (page: number = 1): Promise<boolean> => {
    setLoading(true);

    try {
      const response = await callApi<CustomerCollectionResponseType, undefined>(
        "GET",
        `/customers?page=${page}`,
        CustomerCollectionResponse,
      );

      if (response) {
        setCustomers((prevCustomers) => {
          const existingItems = prevCustomers?.["hydra:member"] || [];
          const newItems = response?.["hydra:member"] || [];

          return {
            ...response,
            "hydra:member": [
              ...existingItems,
              ...newItems.filter((item) => !existingItems.some((i) => i.id === item.id)),
            ],
          };
        });

        return response?.["hydra:view"]?.["hydra:next"] !== undefined;
      }

      return false; // Se non c'è risposta, interrompe il ciclo
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getUsers = async (page: number = 1): Promise<boolean> => {
    setLoading(true);

    try {
      const response = await callApi<UserResponseCollectionType, undefined>(
        "GET",
        `/users?page=${page}`,
        UserResponseCollection,
      );

      if (response) {
        setUsers((prevUsers) => {
          const existingItems = prevUsers?.["hydra:member"] || [];
          const newItems = response?.["hydra:member"] || [];

          return {
            ...response,
            "hydra:member": [
              ...existingItems,
              ...newItems.filter((item) => !existingItems.some((i) => i.id === item.id)),
            ],
          };
        });

        return response?.["hydra:view"]?.["hydra:next"] !== undefined;
      }

      return false; // Se non c'è risposta, interrompe il ciclo
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getDemands = async (page: number = 1): Promise<boolean> => {
    setLoading(true);

    try {
      const response = await callApi<DemandCollectionResponseType, undefined>(
        "GET",
        `/demands?page=${page}`,
        DemandCollectionResponse,
      );

      if (response) {
        setDemands((prevDemands) => {
          const existingItems = prevDemands?.["hydra:member"] || [];
          const newItems = response?.["hydra:member"] || [];

          return {
            ...response,
            "hydra:member": [
              ...existingItems,
              ...newItems.filter((item) => !existingItems.some((i) => i.id === item.id)),
            ],
          };
        });

        return response?.["hydra:view"]?.["hydra:next"] !== undefined;
      }

      return false; // Se non c'è risposta, interrompe il ciclo
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    customers,
    users,
    demands,
    loading,
    loadAllData,
    getCustomers,
    getUsers,
    getDemands,
  };
};
