import { FiltersType } from "../../components/Filter";
import { CustomerResponseType } from "../../models/Customer";

export const customerFilters: FiltersType<CustomerResponseType> = [
  {
    label: "Nome",
    filterFn: (customer: CustomerResponseType, filterValue: string | null) => {
      return customer.name && filterValue
        ? customer.name.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Cognome",
    filterFn: (customer: CustomerResponseType, filterValue: string | null) => {
      return customer.surname && filterValue
        ? customer.surname.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Email",
    filterFn: (customer: CustomerResponseType, filterValue: string | null) => {
      return customer.email && filterValue
        ? customer.email.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Tipo cliente",
    filterFn: (customer: CustomerResponseType, filterValue: string | null) => {
      return customer.customer_type && filterValue
        ? customer.customer_type.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Stato cliente",
    filterFn: (customer: CustomerResponseType, filterValue: string | null) => {
      return customer.customer_state && filterValue
        ? customer.customer_state.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
];
