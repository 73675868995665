export type ErrorInputType = "required" | "invalid" | "minLength" | "mismatch";

export interface FieldErrorInput {
  invalid?: string;
  minLength?: string;
  mismatch?: string;
}

export interface ErrorInput {
  [field: string]: FieldErrorInput;
}

export const ERROR_INPUT = {
  email: {
    invalid: "L'email non è valida",
  },

  pec: {
    invalid: "La pec non è valida",
  },

  password: {
    minLength: "La password deve essere di almeno 6 caratteri",
  },

  confirm_password: {
    mismatch: "Le password non corrispondono",
  },

  mobile_phone: {
    invalid: "Il numero di cellulare non è valido",
  },

  landline_phone: {
    invalid: "Il numero di telefono non è valido",
  },

  date_of_birth: {
    invalid: "La data di nascita non è valida",
  },

  province: {
    invalid: "La provincia deve contenere da 2 a 3 caratteri",
  },

  cf: {
    invalid: "Il codice fiscale non è valido",
  },

  unique_protocol: {
    invalid:
      "Il protocollo univoco deve seguire il formato (ANNO)(AREA)(PROGRESSIVO), es: 2024LEG011",
  },
};
