import { CircularProgress } from "@mui/material";
import { ChangeEvent } from "react";
import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { DemandCollectionResponseType } from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import {
  downspoutsTypeOptions,
  ExtInspReportCollectionResponseType,
  ExtInspReportType,
  facadeTypeOptions,
  guttersTypeOptions,
  IMAGE_TYPE_VALUE,
  ImageTypeOptions,
  ImageUploadType,
  roofTypeOptions,
} from "../../models/ExtInspReport";
import { UserResponseCollectionType } from "../../models/User";
import { currentDate, formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";
import { ERROR_INPUT } from "../ErrorInput";

export const initialFormData: ExtInspReportType = {
  commercial_user_id: "",
  demand_id: "",
  date: "",
  address: "",
  work_contact: "",
  phone_work_contact: "",
  email_work_contact: "",
  access_contact: "",
  phone_access_contact: "",
  email_access_contact: "",
  other: "",

  // info
  roof_type: [],
  number_pitches: 0,
  elevator: false,
  attic: false,
  floors_number: 0,
  life_line: false,
  notes: "",
  water_point: false,
  water_point_place: "",
  light_point: false,
  light_point_place: "",
  tech_stock_room: false,
  tech_stock_room_place: "",
  number_estate_units: 0,
  condos_roof_access: false,
  private_cover_access: false,
  condo_name: "",
  condo_surname: "",
  phone: "",
  email: "",

  // facciate
  securing: [],
  restoration: [],
  shave: [],
  painting: [],
  water_repellent_treatment: [],
  window_wash: false,
  facade_wash: false,

  // balconi
  adjustment: false,
  waterproofing: false,
  flooring: false,
  rehabilitation_railings: false,
  balconies_notes: "",

  // copertura
  antivolatile: false,
  insulated: false,
  new_roof: false,
  structure: false,
  lifeline_project: false,
  maintenance: false,
  overhaul_coverage: false,
  overhaul_coverage_percentage: 0,
  sheath: false,
  tiles: false,
  obligatory_booklet: "",

  // lattoneria
  gutters: [],
  gutters_mt: 0,
  downspouts: [],
  downspouts_mt: 0,
  tinsmithery_notes: "",

  // facciata
  sides: [],
  facade_notes: "",

  // balconi privati
  balconies: null,
  ferrous_works: "",
  common_parts: "",
  private_balconies_notes: "",
};

export function mapToFiles(data: ImageUploadType): {
  name?: string;
  files: File[];
  type?: string;
} {
  const files: File[] = (data["files[]"] || [])
    .filter((path): path is string => typeof path === "string")
    .map((path) => new File([], path));

  return {
    name: data.name,
    files,
    type: data.type,
  };
}

export const inputProps = (
  formData: ExtInspReportType,
  users: UserResponseCollectionType | null,
  demands: DemandCollectionResponseType | null,
  error: ErrorType | null,
  errorHelperText: Record<string, string | null>,
  resize: boolean,
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement>,
    groupIndex?: number,
    fieldIndex?: number,
  ) => void,
  handleMultiSelectChange: (name: string, selectedValues: string[]) => void,
  extInspReports?: ExtInspReportCollectionResponseType,
): InputGroup[] => {
  const userOptions = users
    ? users["hydra:member"].map((user) => ({
        value: user.id,
        label: `${user.name ?? ""} ${user.surname ?? ""}`,
      }))
    : [];

  const demandOptions = demands
    ? demands["hydra:member"].map((demand) => {
        const isDemandIdAssociated =
          extInspReports &&
          extInspReports["hydra:member"].some(
            (report) =>
              report.demand_id &&
              report.demand_id.replace("/api/demands/", "") === String(demand.id),
          );

        return {
          value: demand.id,
          label: `${demand.unique_protocol ?? ""}`,
          disabled: isDemandIdAssociated,
        };
      })
    : [];

  return [
    {
      section: "Dati generici",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              value: formData.commercial_user_id || "",
              label: "Consulente",
              name: "commercial_user_id",
              type: "select",
              options: userOptions,
              textcolor: theme.colors.secondary,
              required: true,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.demand_id || "",
              label: "Id della richiesta da associare",
              name: "demand_id",
              type: "select",
              options: demandOptions,
              textcolor: theme.colors.secondary,
              required: true,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.date ? formatDate(formData.date) : "",
              label: "Data",
              name: "date",
              type: "date",
              textcolor: theme.colors.secondary,
              required: true,
              error: !!error,
              inputProps: { max: currentDate() },
              onChange: handleOnChange,
            },
            {
              value: formData.address || "",
              label: "Indirizzo",
              name: "address",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.work_contact || "",
              label: "Referente lavori",
              name: "work_contact",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.phone_work_contact || "",
              label: "Cellulare referente lavori",
              name: "phone_work_contact",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error:
                (formData.phone_work_contact && !!errorHelperText.phone_work_contact) || !!error,
              helperText:
                formData.phone_work_contact &&
                errorHelperText.phone_work_contact === "invalid" &&
                ERROR_INPUT.mobile_phone.invalid,
              onChange: handleOnChange,
            },
            {
              value: formData.email_work_contact || "",
              label: "Mail referente lavori",
              name: "email_work_contact",
              type: "email",
              textcolor: theme.colors.secondary,
              required: false,
              error:
                (formData.email_work_contact && !!errorHelperText.email_work_contact) || !!error,
              helperText:
                formData.email_work_contact &&
                errorHelperText.email_work_contact === "invalid" &&
                ERROR_INPUT.email.invalid,
              onChange: handleOnChange,
            },
            {
              value: formData.access_contact || "",
              label: "Referente accesso",
              name: "access_contact",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.phone_access_contact || "",
              label: "Cellulare referente accesso",
              name: "phone_access_contact",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error:
                (formData.phone_access_contact && !!errorHelperText.phone_access_contact) ||
                !!error,
              helperText:
                formData.phone_access_contact &&
                errorHelperText.phone_access_contact === "invalid" &&
                ERROR_INPUT.mobile_phone.invalid,
              onChange: handleOnChange,
            },
            {
              value: formData.email_access_contact || "",
              label: "Mail referente accesso",
              name: "email_access_contact",
              type: "email",
              textcolor: theme.colors.secondary,
              required: false,
              error:
                (formData.email_access_contact && !!errorHelperText.email_access_contact) ||
                !!error,
              helperText:
                formData.email_access_contact &&
                errorHelperText.email_access_contact === "invalid" &&
                ERROR_INPUT.email.invalid,
              onChange: handleOnChange,
            },
          ],
        },
        {
          paddingTop: 2,
          fields: [
            {
              value: formData.other || "",
              label: "Altro",
              name: "other",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Info",
      groups: [
        {
          inputColumns: resize ? 1 : 3,
          fields: [
            // checkbox
            {
              checked: formData.elevator || false,
              label: "Ascensore",
              name: "elevator",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.attic || false,
              label: "Sottotetto",
              name: "attic",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.life_line || false,
              label: "Linea vita",
              name: "life_line",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.water_point || false,
              label: "Punto acqua",
              name: "water_point",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.light_point || false,
              label: "Punto luce",
              name: "light_point",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.tech_stock_room || false,
              label: "Locale tecnico/stoccaggio",
              name: "tech_stock_room",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.condos_roof_access || false,
              label: "Accesso tetto condominio",
              name: "condos_roof_access",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.private_cover_access || false,
              label: "Accesso copertura privata",
              name: "private_cover_access",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          inputColumns: resize ? 1 : 3,
          paddingTop: 8,
          fields: [
            {
              value: formData.roof_type || "",
              selectedvalues: (formData.roof_type || []).filter(
                (v): v is string => v !== undefined,
              ),
              label: "Tipologia tetto",
              name: "roof_type",
              type: "multiselect",
              options: roofTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("roof_type", newSelectedValues),
            },
            {
              value: formData.number_pitches || "",
              label: "Numero falde",
              name: "number_pitches",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.floors_number || "",
              label: "N° piani",
              name: "floors_number",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.water_point_place || "",
              label: "Punto acqua luogo",
              name: "water_point_place",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.light_point_place || "",
              label: "Punto luce luogo",
              name: "light_point_place",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.tech_stock_room_place || "",
              label: "Locale tecnico/stoccaggio luogo",
              name: "tech_stock_room_place",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.number_estate_units || "",
              label: "N° unità immobiliari",
              name: "number_estate_units",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.condo_name || "",
              label: "Nome condominio",
              name: "condo_name",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.condo_surname || "",
              label: "Cognome condominio",
              name: "condo_surname",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.phone || "",
              label: "Telefono",
              name: "phone",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.email || "",
              label: "Email",
              name: "email",
              type: "email",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          paddingTop: 2,
          fields: [
            {
              value: formData.notes || "",
              label: "Note",
              name: "notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Facciate",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              checked: formData.window_wash || false,
              label: "Lavaggio vetri",
              name: "window_wash",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.facade_wash || false,
              label: "Lavaggio facciate",
              name: "facade_wash",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          inputColumns: resize ? 1 : 2,
          paddingTop: 8,
          fields: [
            {
              value: formData.securing || "",
              selectedvalues: (formData.securing || []).filter((v): v is string => v !== undefined),

              label: "Messa in sicurezza",
              name: "securing",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("securing", newSelectedValues),
            },
            {
              value: formData.restoration || "",
              selectedvalues: (formData.restoration || []).filter(
                (v): v is string => v !== undefined,
              ),
              label: "Ripristini",
              name: "restoration",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("restoration", newSelectedValues),
            },
            {
              value: formData.shave || "",
              selectedvalues: (formData.shave || []).filter((v): v is string => v !== undefined),
              label: "Rasatura",
              name: "shave",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("shave", newSelectedValues),
            },
            {
              value: formData.painting || "",
              selectedvalues: (formData.painting || []).filter((v): v is string => v !== undefined),
              label: "Tinteggiatura",
              name: "painting",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("painting", newSelectedValues),
            },
            {
              value: formData.water_repellent_treatment || "",
              selectedvalues: formData.water_repellent_treatment || "",
              label: "Trattamento idrorepellente",
              name: "water_repellent_treatment",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("water_repellent_treatment", newSelectedValues),
            },
          ],
        },
      ],
    },
    {
      section: "Balconi",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              checked: formData.adjustment || false,
              label: "Adeguamento",
              name: "adjustment",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.waterproofing || false,
              label: "Impermeabilizzazione",
              name: "waterproofing",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.flooring || false,
              label: "Pavimentazione",
              name: "flooring",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.rehabilitation_railings || false,
              label: "Risanamento ringhiere",
              name: "rehabilitation_railings",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          paddingTop: 8,
          fields: [
            {
              value: formData.balconies_notes || "",
              label: "Note balconi",
              name: "balconies_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Copertura",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              checked: formData.antivolatile || false,
              label: "Antivolatili",
              name: "antivolatile",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.insulated || false,
              label: "Coibentato",
              name: "insulated",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.new_roof || false,
              label: "Nuovo tetto (listelli, tegole)",
              name: "new_roof",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.structure || false,
              label: "Struttura",
              name: "structure",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.lifeline_project || false,
              label: "Linea vita progetto",
              name: "lifeline_project",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.maintenance || false,
              label: "Manutenzione",
              name: "maintenance",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.sheath || false,
              label: "Guaina",
              name: "sheath",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: formData.tiles || false,
              label: "Tegola",
              name: "tiles",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          inputColumns: 1,
          paddingTop: 8,
          fields: [
            {
              value: formData.obligatory_booklet || "",
              label: "Libretto obbligatorio",
              name: "obligatory_booklet",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          inputColumns: 1,
          paddingTop: 2,
          fields: [
            {
              checked: formData.overhaul_coverage || false,
              label: "Ripasso copertura",
              name: "overhaul_coverage",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.overhaul_coverage_percentage || "",
              label: "",
              name: "overhaul_coverage_percentage",
              type: "percentage",
              inputProps: {
                step: "0.01",
                min: "0",
              },
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Lattoneria",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              value: formData.gutters || "",
              selectedvalues: (formData.gutters || []).filter((v): v is string => v !== undefined),
              label: "Gronde",
              name: "gutters",
              type: "multiselect",
              options: guttersTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("gutters", newSelectedValues),
            },
            {
              value: formData.gutters_mt || "",
              label: "Gronde (mt)",
              name: "gutters_mt",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: formData.downspouts || "",
              selectedvalues: (formData.downspouts || []).filter(
                (v): v is string => v !== undefined,
              ),
              label: "Pluviali",
              name: "downspouts",
              type: "multiselect",
              options: downspoutsTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("downspouts", newSelectedValues),
            },
            {
              value: formData.downspouts_mt || "",
              label: "Scossaline (mt)",
              name: "downspouts_mt",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          paddingTop: 2,
          fields: [
            {
              value: formData.tinsmithery_notes || "",
              label: "Note lattoneria",
              name: "tinsmithery_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Facciata",
      groups: [
        {
          isDynamicInput: true,
          groupTitle: "lato facciata",
          subGroups: Array.from({ length: 6 }, (_, index) => ({
            title: `L${index + 1}`,
            inputColumns: resize ? 1 : 4,
            fields: [
              {
                value: formData.sides[index]?.mt || "",
                label: "Metrature",
                name: "mt",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
                error: !!error,
                onChange: (e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, index),
              },
              {
                value: formData.sides[index]?.ground || "",
                label: "Terra",
                name: "ground",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
                error: !!error,
                onChange: (e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, index),
              },
              {
                value: formData.sides[index]?.border || "",
                label: "Bordo",
                name: "border",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
                error: !!error,
                onChange: (e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, index),
              },
              {
                value: formData.sides[index]?.h_interplane || "",
                label: "H Interpiano",
                name: "h_interplane",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
                error: !!error,
                onChange: (e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, index),
              },
            ],
          })),
        },
        {
          paddingTop: 4,
          fields: [
            {
              value: formData.facade_notes || "",
              label: "Note facciata",
              name: "facade_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Balconi privati",
      groups: [
        {
          isDynamicInput: true,
          isVisibleQuantity: true,
          groupTitle: "balconi",
          subGroups: Array.from(
            { length: Object.keys(formData.balconies || {}).length + 1 },
            (_, index) => {
              const balcony = formData.balconies && formData.balconies[index];
              return {
                inputColumns: resize ? 1 : 4,
                title: `Balcone ${index + 1}`,
                fields: [
                  {
                    value: balcony?.pavimenti.flat() || "",
                    label: "MQ Pavimenti/Cielini",
                    name: "pavimenti",
                    type: "text",
                    textcolor: theme.colors.secondary,
                    required: false,
                    error: !!error,
                  },
                  {
                    value: balcony?.frontalini.flat() || "",
                    label: "ML Frontalini",
                    name: "frontalini",
                    type: "text",
                    textcolor: theme.colors.secondary,
                    required: false,
                    error: !!error,
                  },
                  {
                    value: balcony?.parapetto_muratura.flat() || "",
                    label: "ML Parapetto muratura",
                    name: "parapetto_muratura",
                    type: "text",
                    textcolor: theme.colors.secondary,
                    required: false,
                    error: !!error,
                  },
                  {
                    value: balcony?.ringhiere.flat() || "",
                    label: "ML Ringhiere",
                    name: "ringhiere",
                    type: "text",
                    textcolor: theme.colors.secondary,
                    required: false,
                    error: !!error,
                  },
                ],
              };
            },
          ),
        },
        {
          paddingTop: 2,
          fields: [
            {
              value: formData.private_balconies_notes || "",
              label: "Note balconi privati",
              name: "private_balconies_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
  ];
};

export const inputImageProps = (
  localFiles: File[],
  files: ImageUploadType | null,
  error: ErrorType | null,
  handleOnRemoveFile?: (index: number) => void,
  handleOnChangeFileInput?: (e: ChangeEvent<HTMLInputElement>) => void,
  labelsImages?: string[],
): InputGroup[] => [
  {
    inputColumns: 1,
    paddingTop: 4,
    fields: [
      localFiles.length > 0
        ? {
            value: files?.name || "",
            label: "Nome",
            name: "name",
            type: "text",
            textcolor: theme.colors.secondary,
            required: true,
            error: !!error,
            onChange: handleOnChangeFileInput,
          }
        : null,
      {
        value: files?.type || "",
        label: "Tipo",
        name: "type",
        type: "select",
        options: ImageTypeOptions,
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        onChange: handleOnChangeFileInput,
      },
      {
        files: (files && mapToFiles(files).files) || [],
        label: "Immagini",
        labelImage: labelsImages || [],
        name: "files[]",
        type: "file",
        inputProps: {
          multiple: true,
          accept: "image/*",
          disabled: files?.type === IMAGE_TYPE_VALUE.SELECT_TYPE,
        },
        textcolor:
          files?.type === IMAGE_TYPE_VALUE.SELECT_TYPE
            ? "InactiveCaptionText"
            : theme.colors.secondary,
        required: false,
        error: !!error,
        onChange: handleOnChangeFileInput,
        onRemoveFile: handleOnRemoveFile,
      },
    ],
  },
];

export const buttonImageProps = (
  dataLoading: boolean,
  loading: boolean,
  error: ErrorType | undefined,
): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{error.detail}</ErrorText> : null,
    fields: [
      {
        disabled: dataLoading || loading || !!error,
        label: dataLoading || loading ? <CircularProgress size={24} /> : "Salva",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

export const buttonProps = (
  dataLoading: boolean,
  loading: boolean,
  error: ErrorType | undefined,
): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{error.detail}</ErrorText> : null,
    fields: [
      {
        disabled: loading || !!error,
        label: dataLoading ? <CircularProgress size={24} /> : "Vai avanti e inserisci le foto",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 0.5rem;
`;
