import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { CustomerCollectionResponseType } from "../../models/Customer";
import { CustomerHistoryType, typeHistoryOptions } from "../../models/CustomerHistory";
import { DemandCollectionResponseType } from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import { UserResponseCollectionType } from "../../models/User";
import { currentDate, formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";
import { CircularProgress } from "@mui/material";

export const initialFormData: CustomerHistoryType = {
  customer_id: "",
  date: "",
  type: "",
  result: "",
  demand_id: "",
  user_id: "",
};

export const inputProps = (
  customerHistory: CustomerHistoryType,
  customers: CustomerCollectionResponseType | null,
  users: UserResponseCollectionType | null,
  demands: DemandCollectionResponseType | null,
  error: ErrorType | null,
  resize: boolean,
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
): InputGroup[] => {
  return [
    {
      fields: [
        {
          value: customerHistory.user_id || "",
          label: "Commerciale di riferimento",
          name: "user_id",
          type: "select",
          options: users
            ? users["hydra:member"].map((user) => ({
                value: user.id,
                label: `${user.name ?? ""} ${user.surname ?? ""}`,
              }))
            : [],
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: customerHistory.customer_id || "",
          label: "Cliente",
          name: "customer_id",
          type: "select",
          options: customers
            ? customers["hydra:member"].map((customer) => ({
                value: customer.id,
                label: `${customer.name ?? ""} ${customer.surname ?? ""}`,
              }))
            : [],
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: customerHistory.demand_id || "",
          label: "Richiesta",
          name: "demand_id",
          type: "select",
          options: demands
            ? demands["hydra:member"].map((demand) => ({
                value: demand.id,
                label: `${demand.unique_protocol ?? ""}`,
              }))
            : [],
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: customerHistory.date ? formatDate(customerHistory.date) : "",
          label: "Data",
          name: "date",
          type: "date",
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          inputProps: { max: currentDate() },
          onChange: handleOnChange,
        },
        {
          value: customerHistory.type || "",
          label: "Tipologia",
          name: "type",
          type: "select",
          options: typeHistoryOptions,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: customerHistory.result || "",
          label: "Esito",
          name: "result",
          type: "text",
          multiline: true,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
      ],
      inputColumns: resize ? 1 : 2,
    },
  ];
};

export const buttonProps = (
  dataLoading: boolean,
  loading: boolean,
  error: ErrorType | undefined,
): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{error.detail}</ErrorText> : null,
    fields: [
      {
        disabled: loading || !!error,
        label: dataLoading ? <CircularProgress size={24} /> : "Registra interazione cliente",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 0.5rem;
`;
