import { UserRegisterType } from "../../models/User";
import { Validators } from "../../utils/Validate";

export const validators: Validators<UserRegisterType> = {
  email: (value) => {
    if (typeof value === "string" && !/\S+@\S+\.\S+/.test(value)) {
      return "invalid";
    }
    return null;
  },
  password: (value) => {
    if (typeof value === "string" && value.length < 6) {
      return "minLength";
    }
    return null;
  },
  confirm_password: (value, data) => {
    if (typeof value === "string" && value !== data?.password) {
      return "mismatch";
    }
    return null;
  },
  mobile_phone: (value) => {
    if (typeof value === "string") {
      if (value.length < 9 || (value.length > 10 && !/^\d+$/.test(value))) {
        return "invalid";
      }
    }
    return null;
  },
  province: (value) => {
    if (typeof value === "string" && (value.length < 2 || value.length > 3)) {
      return "invalid";
    }
    return null;
  },
  cf: (value) => {
    if (typeof value === "string" && !/^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/.test(value)) {
      return "invalid";
    }
    return null;
  },
};
