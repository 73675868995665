import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../utils/Theme";
import { CommonInputProps } from "./Input";

export type CustomSelectOptionType = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

export interface CustomSelectProps extends CommonInputProps {
  type: "select";
  placeholder?: string;
  options?: CustomSelectOptionType[];
}

export interface CustomMultiSelectProps extends CommonInputProps {
  type: "multiselect";
  options?: CustomSelectOptionType[];
  selectedvalues: string[];
  onChange: (selected: string[]) => void;
}

export type CustomSelectInputProps = CustomSelectProps | CustomMultiSelectProps;

const CustomSelect = ({
  textcolor,
  error,
  label,
  type,
  options = [],
  required,
  placeholder,
  ...selectProps
}: CustomSelectInputProps & Omit<SelectProps, "onChange" | "value">) => {
  const [open, setOpen] = useState(false);

  const customStyles = {
    fontFamily: theme.fonts.primary,
    fontWeight: theme.fontWeights.semibold,
    fontSize: theme.fontSizes.large,
    color: error ? theme.colors.error : textcolor,
    paddingRight: "0.375rem",
    paddingLeft: "0.375rem",
    background: "#ffff",
  };

  const customSx = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: error ? theme.colors.error : theme.colors.secondary,
      },
    },
  };

  if (type === "multiselect") {
    const { selectedvalues, onChange, ...props } = selectProps as CustomMultiSelectProps;

    const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as string[];
      onChange(value);
    };

    return (
      <FormControl fullWidth error={error} required={required}>
        <InputLabel shrink style={customStyles}>
          {label}
        </InputLabel>
        <StyledSelect
          {...props}
          multiple
          error={error}
          variant="outlined"
          value={selectedvalues || []}
          inputProps={{ style: customStyles }}
          sx={customSx}
          renderValue={(selected) => (selected as string[]).join(",  ") || ""}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={handleSelectChange}
          MenuProps={{
            PaperProps: {
              style: { maxHeight: 300 },
            },
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    );
  }

  const { ...props } = selectProps as CustomSelectProps;
  return (
    <FormControl required={required} error={error} fullWidth>
      <InputLabel shrink style={customStyles}>
        {label}
      </InputLabel>
      <StyledSelect
        {...props}
        error={error}
        inputProps={{ style: customStyles }}
        sx={customSx}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 300 },
          },
        }}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default CustomSelect;
