import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { ROLES, UserEditType } from "../../models/User";
import { formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";
import { ERROR_INPUT, ErrorInputType } from "../ErrorInput";
import { ErrorType } from "../../models/Error";

const roleOptions = [
  { value: ROLES.USER, label: "User" },
  { value: ROLES.ADMIN, label: "Admin" },
  { value: ROLES.SELECT_ROLE, label: "Select role" },
];

const currentDate = new Date().toISOString().split("T")[0];

export const inputProps = (
  user: UserEditType | null,
  errorHelperText: Record<string, ErrorInputType | null>,
  error: ErrorType | null,
  resize: boolean,
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
): InputGroup[] & { options?: typeof ROLES } => [
  {
    fields: [
      {
        value: user?.name,
        label: "Nome",
        name: "name",
        type: "text",
        textcolor: theme.colors.secondary,
        required: false,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user?.surname,
        label: "Cognome",
        name: "surname",
        type: "text",
        textcolor: theme.colors.secondary,
        required: false,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user?.email,
        label: "Email",
        name: "email",
        type: "email",
        textcolor: theme.colors.secondary,
        required: false,
        error: (user?.email && !!errorHelperText.email) || !!error,
        helperText: user?.email && errorHelperText.email === "invalid" && ERROR_INPUT.email.invalid,
        onChange: handleOnChange,
      },
      {
        value: user?.roles.includes(ROLES.ADMIN)
          ? ROLES.ADMIN
          : user?.roles.includes(ROLES.USER)
          ? ROLES.USER
          : ROLES.SELECT_ROLE,
        label: "Ruoli",
        name: "roles",
        type: "select",
        textcolor: theme.colors.secondary,
        required: false,
        error: !!error,
        options: roleOptions,
        onChange: handleOnChange,
      },
      {
        value: user?.mobile_phone,
        label: "Tel",
        name: "mobile_phone",
        type: "tel",
        textcolor: theme.colors.secondary,
        required: false,
        error: (user?.mobile_phone && !!errorHelperText.mobile_phone) || !!error,
        helperText:
          user?.mobile_phone &&
          errorHelperText.mobile_phone === "invalid" &&
          ERROR_INPUT.mobile_phone.invalid,
        inputProps: { maxLength: 10 },
        onChange: handleOnChange,
      },
      {
        value: user?.address,
        label: "Indirizzo",
        name: "address",
        type: "text",
        textcolor: theme.colors.secondary,
        required: false,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user?.city,
        label: "Città",
        name: "city",
        type: "text",
        textcolor: theme.colors.secondary,
        required: false,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user?.province,
        label: "Provincia",
        name: "province",
        type: "text",
        textcolor: theme.colors.secondary,
        required: false,
        error: (user?.province && !!errorHelperText.province) || !!error,
        helperText:
          user?.province && errorHelperText.province === "invalid" && ERROR_INPUT.province.invalid,
        inputProps: { maxLength: 3, style: { textTransform: "uppercase" } },
        onChange: handleOnChange,
      },
      {
        value: user?.date_of_birth ? formatDate(user?.date_of_birth) : "",
        label: "Data di nascita",
        name: "date_of_birth",
        type: "date",
        textcolor: theme.colors.secondary,
        required: false,
        error: !!error,
        inputProps: { max: currentDate },
        onChange: handleOnChange,
      },
      {
        value: user?.cf,
        label: "Codice Fiscale",
        name: "cf",
        type: "text",
        textcolor: theme.colors.secondary,
        required: false,
        error: (user?.cf && !!errorHelperText.cf) || !!error,
        helperText: user?.cf && errorHelperText.cf === "invalid" && ERROR_INPUT.cf.invalid,
        inputProps: { style: { textTransform: "uppercase" } },
        onChange: handleOnChange,
      },
    ],
    rowGap: 2,
    inputColumns: resize ? 1 : 2,
  },
];

export const buttonProps = (loading: boolean, error: ErrorType | null): ButtonGroup[] => [
  {
    childrenButton: error && <ErrorText>{error.detail}</ErrorText>,
    fields: [
      {
        disabled: loading || !!error,
        label: loading ? <CircularProgress size={24} /> : "Aggiorna utente",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  && {
    color: ${theme.colors.error};
    margin-bottom: 0.5rem;
  }
`;
