import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Column, IconType } from "../../components/Table";
import { ExtInspReportResponseType, ExtInspReportType } from "../../models/ExtInspReport";
import { theme } from "../../utils/Theme";

export const extInspReportColumns: Column<ExtInspReportType>[] = [
  { header: "Cliente", accessor: "commercial_user_id" },
  { header: "Richiesta", accessor: "demand_id" },
];

export const createExtInspReportColumnsIcons = (
  onShow: (id: number) => void,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
  onImagesUpload: (id: number) => void,
): IconType<ExtInspReportResponseType>[] => [
  {
    icon: <VisibilityIcon />,
    color: theme.colors.background,
    titleTooltip: "Visualizza verbale",
    onClick: (report: ExtInspReportResponseType) => onShow(report.id),
  },
  {
    icon: <EditIcon />,
    color: theme.colors.background,
    titleTooltip: "Modifica verbale",
    onClick: (report: ExtInspReportResponseType) => onEdit(report.id),
  },
  {
    icon: <ImageIcon />,
    color: theme.colors.background,
    titleTooltip: "Gestione Immagini",
    onClick: (report: ExtInspReportResponseType) => onImagesUpload(report.id),
  },
  {
    icon: <DeleteIcon />,
    color: theme.colors.error,
    titleTooltip: "Rimuovi verbale",
    onClick: (report: ExtInspReportResponseType) => onDelete(report.id),
  },
];
