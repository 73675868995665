export const formatRoles = (roles: string[]): string => {
  const hasAdmin = roles.includes("ROLE_ADMIN");
  const hasUser = roles.includes("ROLE_USER");

  if (hasAdmin) {
    return "ADMIN";
  } else if (hasUser) {
    return "USER";
  }

  return "";
};

export const currentDate = (date?: string) =>
  date ? new Date(date).toISOString().split("T")[0] : new Date().toISOString().split("T")[0];

export const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatServerError = (detail?: string) => {
  const regex = /Duplicate entry '([^']+)'/;
  const match = detail && detail.match(regex);
  if (match && match.length > 1) {
    return match[0];
  }
};
