import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isRight } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as io from "io-ts";

const api = axios.create({
  // https://create-react-app.dev/docs/adding-custom-environment-variables/
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

export const callApi = async <T, U>(
  method: "GET" | "POST" | "DELETE" | "PUT" | "PATCH" | "OPTIONS",
  endpoint: string,
  responseDecoder?: io.Type<T>, // responseDecoder io-ts per validare la risposta
  requestDecoder?: io.Type<U>, // requestDecoder io-ts per validare la richiesta
  body?: U, // Body da inviare per i metodi POST e PUT
): Promise<T | void> => {
  const token = localStorage.getItem("token") || sessionStorage.getItem("token");
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  if (requestDecoder && body) {
    const bodyValidationResult = requestDecoder.decode(body);

    if (!isRight(bodyValidationResult)) {
      console.error("Errore di validazione del corpo della richiesta:", bodyValidationResult.left);
      throw new Error(
        `Il corpo della richiesta non è valido: ${JSON.stringify(bodyValidationResult.left)}`,
      );
    }
  }

  try {
    const config: AxiosRequestConfig = {
      method,
      url: endpoint,
      headers,
      data: body, // Solo per POST e PUT
    };
    const response: AxiosResponse = await api(config);

    if (method === "DELETE" && response.status === 204) {
      return;
    }

    if (response.data && responseDecoder) {
      const validationResult = responseDecoder.decode(response.data);
      // gestisce il risultato della validazione
      return pipe(validationResult, (result) => {
        if (isRight(result)) {
          return result.right;
        } else {
          throw result.left;
        }
      });
    } else {
      throw new Error("Risposta dell'API non valida o mancante");
    }
  } catch (error) {
    console.error(`Errore durante la chiamata ${method} ${endpoint}:`, error);
    throw error;
  }
};
