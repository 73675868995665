import { useContext } from "react";
import Form from "../../components/Form";
import { inputProps } from "../../constants/user/UserProfile";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";

const UserProfileDataProvider = () => {
  const { currentUser } = useContext(AppContext);
  const deviceType = useDeviceType();

  return (
    <Form
      title="Profilo Utente"
      inputProps={inputProps(currentUser, deviceType === DeviceType.Mobile)}
      onSubmit={() => {}}
    />
  );
};

export default UserProfileDataProvider;
