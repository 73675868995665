import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Grid, GridOwnProps } from "@mui/material";
import { ChangeEvent, Fragment, useState } from "react";
import styled from "styled-components";
import { theme } from "../utils/Theme";
import { InputField, LayoutProps } from "./Form";
import CustomInput, { CustomInputProps } from "./Input";
import CustomSelect, { CustomMultiSelectProps } from "./Select";

export type DynamicInputGroup = {
  fields: InputField[];
  title?: string;
  rowGap?: number;
  columnsGap?: number;
  inputColumns?: number;
} & LayoutProps;

interface DynamicInputFieldsProps extends GridOwnProps {
  inputGroups: DynamicInputGroup[];
  isDynamicInput?: boolean;
  groupTitle?: string;
  subGroupTitle?: string;
  mode?: "edit" | "register" | "view";
  isVisibleQuantity?: boolean;
  quantities?: { [groupIndex: number]: { [fieldIndex: number]: number[] } };
  onChangeDynamicInput: (
    groupIndex: number,
    fieldIndex: number,
    value: string | string[] | number,
  ) => void;
  onChangeQuantity?: (groupIndex: number, fieldIndex: number, value: number[]) => void;
  addFieldGroup: () => void;
  removeFieldGroup: (groupIndex: number) => void;
  addField?: (groupTitle: string, subGroupIndex: number, fieldIndex: number) => void;
  removeField?: (groupTitle: string, subGroupIndex: number, fieldIndex: number) => void;
}

const DynamicInput = ({
  inputGroups,
  isDynamicInput,
  groupTitle,
  subGroupTitle,
  mode = "register",
  isVisibleQuantity = false,
  quantities = {},
  onChangeDynamicInput: handleOnChangeDynamicInput,
  onChangeQuantity: handleOnChangeQuantity,
  addFieldGroup,
  removeFieldGroup,
  addField,
  removeField,
  ...props
}: DynamicInputFieldsProps) => {
  const [hiddenGroups, setHiddenGroups] = useState<boolean[]>(
    new Array(inputGroups.length).fill(false),
  );

  const toggleVisibility = (groupIndex: number) => {
    setHiddenGroups((prevHiddenGroups) => {
      const updated = [...prevHiddenGroups];
      updated[groupIndex] = !updated[groupIndex];
      return updated;
    });
  };

  const renderInputFields = (group: DynamicInputGroup, groupIndex: number) => {
    const labelOccurrences: { [label: string]: number } = {};

    // Raggruppa i campi per etichetta
    const fieldsByLabel = group.fields.reduce((acc, field, index) => {
      const label = field?.label || "";
      acc[label] = acc[label] || [];
      acc[label].push({ field, index });
      return acc;
    }, {} as { [label: string]: { field: InputField; index: number }[] });

    return (
      <Fragment key={`group-${groupIndex}`}>
        <GroupContainer>
          {group.title && (
            <GroupTitle>
              {group.title}
              <ToggleButton type="button" onClick={() => toggleVisibility(groupIndex)}>
                {hiddenGroups[groupIndex] ? "Mostra campi" : "Nascondi campi"}
              </ToggleButton>
            </GroupTitle>
          )}

          {!hiddenGroups[groupIndex] && (
            <Grid
              container
              spacing={2}
              rowGap={group.rowGap}
              columnGap={group.columnsGap}
              {...props}
            >
              {Object.entries(fieldsByLabel).map(([label, fields]) => (
                <StyledGrid item xs={12 / (group.inputColumns || 1)} key={label}>
                  {fields.map(({ field, index }) => {
                    const isSecondOrSubsequent = labelOccurrences[label] > 0;
                    labelOccurrences[label] = (labelOccurrences[label] || 0) + 1;

                    return (
                      <FieldContainer key={index}>
                        {isVisibleQuantity && (
                          <>
                            {mode !== "view" && (
                              <ContainerIcon>
                                <AddCircleOutline
                                  onClick={() => addField!(groupTitle || "", groupIndex, index)}
                                />
                                {isSecondOrSubsequent && (
                                  <RemoveCircleOutline
                                    onClick={() =>
                                      removeField!(groupTitle || "", groupIndex, index)
                                    }
                                  />
                                )}
                              </ContainerIcon>
                            )}
                            <CustomInput
                              type="number"
                              label="Quantità"
                              value={quantities[groupIndex]?.[index]?.length || ""}
                              disabled={mode === "view" || !field?.value}
                              required={!quantities[groupIndex]?.[index]?.length}
                              textcolor={theme.colors.secondary}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                const quantityValue =
                                  value === "" ? "" : Math.max(1, parseInt(value, 10));

                                handleOnChangeQuantity!(
                                  groupIndex,
                                  index,
                                  quantityValue === "" ? [] : Array(quantityValue).fill(1),
                                );
                              }}
                            />
                          </>
                        )}
                        {field && "options" in field ? (
                          <CustomSelect
                            textcolor={field.textcolor}
                            {...(field as CustomMultiSelectProps)}
                            onChange={(e: string[]) =>
                              handleOnChangeDynamicInput(groupIndex, index, e)
                            }
                            disabled={mode === "view"}
                          />
                        ) : (
                          field && (
                            <CustomInput
                              textcolor={field.textcolor}
                              {...(field as CustomInputProps)}
                              onChange={(e: ChangeEvent<HTMLInputElement | any>) =>
                                mode !== "view"
                                  ? handleOnChangeDynamicInput(groupIndex, index, e.target.value)
                                  : () => {}
                              }
                            />
                          )
                        )}
                      </FieldContainer>
                    );
                  })}
                </StyledGrid>
              ))}
              {inputGroups.length > 0 && mode !== "view" && (
                <ContainerRemoveButton>
                  <RemoveButton type="button" onClick={() => removeFieldGroup(groupIndex)}>
                    {`Rimuovi ${subGroupTitle}`}
                  </RemoveButton>
                </ContainerRemoveButton>
              )}
            </Grid>
          )}
        </GroupContainer>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isDynamicInput && mode !== "view" && (
        <ContainerButton>
          <StyledButton type="button" onClick={addFieldGroup}>
            {`${mode === "edit" ? "Modifica" : "Aggiungi"} ${subGroupTitle}`}
          </StyledButton>
        </ContainerButton>
      )}
      {inputGroups.map((group, index) => renderInputFields(group, index))}
    </Fragment>
  );
};

const GroupContainer = styled.div`
  margin-bottom: 2rem;
`;

const GroupTitle = styled.div`
  margin-bottom: 1rem;
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.semibold};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.secondary};
`;

const StyledGrid = styled(Grid)`
  && {
    .MuiFormControl-root {
      min-height: 5rem;
    }
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const ContainerIcon = styled.div`
  margin-bottom: 1rem;
`;

const ContainerButton = styled.div`
  align-items: center;
  margin-bottom: 2rem;
`;

const ContainerRemoveButton = styled.div`
  align-items: center;
  margin-bottom: 2rem;
  padding-left: 1rem;
`;

const StyledButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${theme.colors.primary};
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.white};
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${theme.colors.error};
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.white};
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
`;

const ToggleButton = styled(StyledButton)`
  margin-left: 1rem;
  background-color: ${theme.colors.secondary};
`;

export default DynamicInput;
