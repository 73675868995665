import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "../../components/Form";
import Loading from "../../components/Loading";
import { buttonProps, inputProps } from "../../constants/demand/DemandEdit";
import validators from "../../constants/demand/DemandValidators";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import {
  DemandEdit,
  DemandEditType,
  DemandResponse,
  DemandResponseType,
  DemandType,
} from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { validateForm } from "../../utils/Validate";

const DemandEditDataProvider = () => {
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const deviceType = useDeviceType();
  const { id } = useParams<string>();

  const { customers, users, getCustomers, getUsers, loadAllData } = useApiData();

  const [editData, setEditData] = useState<DemandResponseType | null>(null);
  const [errorInput, setErrorInput] = useState<Record<string, string | null>>({});
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (editData) {
      const validationErrors = validateForm<DemandType>(editData, validators);
      setErrorInput(validationErrors);
      setError(null);
    }
  }, [editData]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        await getDemand();
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getCustomers);
        await loadAllData(getUsers);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setEditData((prevState) =>
      prevState ? { ...prevState, [name]: type === "number" ? Number(value) : value } : null,
    );
  };

  const getDemand = async () => {
    setLoading(true);

    try {
      const response = await callApi<DemandResponseType, undefined>(
        "GET",
        `/demands/${id}`,
        DemandResponse,
        undefined,
      );

      if (response) {
        const formattedData = {
          ...response,
          customer_id: response.customer_id?.replace("/api/customers/", ""),
          commercial_user_id: response.commercial_user_id?.replace("/api/users/", ""),
        };
        setEditData(formattedData);
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  const updateDemand = useCallback(async (demand: DemandEditType) => {
    setError(null);
    setLoading(true);

    const body: DemandEditType = {
      customer_id: demand.customer_id,
      address: demand.address,
      number_estate_units: demand.number_estate_units,
      number_floors: demand.number_floors,
      degree_complexity: demand.degree_complexity,
      reference_area: demand.reference_area,
      unique_protocol: demand.unique_protocol,
      demand_date: demand.demand_date,
      contact_person: demand.contact_person,
      city: demand.city,
      province: demand.province,
      urgency: demand.urgency,
      inspection_date: demand.inspection_date,
      processes: demand.processes,
      commercial_user_id: demand.commercial_user_id,
      inspection_type: demand.inspection_type,
    };

    try {
      const response = await callApi<DemandResponseType, DemandEditType>(
        "PUT",
        `/demands/${id}`,
        DemandResponse,
        DemandEdit,
        {
          ...body,
          customer_id: `/api/customers/${body.customer_id}`,
          commercial_user_id: `/api/users/${body.commercial_user_id}`,
        },
      );

      if (response) {
        const formattedData = {
          ...response,
          customer_id: response.customer_id?.replace("/api/customers/", ""),
          commercial_user_id: response.commercial_user_id?.replace("/api/users/", ""),
        };

        setEditData(formattedData);
        alert("Richiesta aggiornata con successo!");
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (editData) {
      const validationErrors = validateForm<DemandType>(editData, validators);
      if (validationErrors && Object.keys(validationErrors).length > 0) {
        setErrorInput(validationErrors);
        return;
      }

      await updateDemand(editData);
    }
  };

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        editData && (
          <Form
            title="Modifica Richiesta"
            onSubmit={handleSubmit}
            inputProps={inputProps(
              editData,
              customers,
              users,
              error,
              errorInput,
              deviceType === DeviceType.Mobile,
              handleOnChange,
            )}
            buttonProps={buttonProps(loading, error ?? null)}
          />
        )
      )}
    </>
  );
};

export default DemandEditDataProvider;
