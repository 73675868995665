import { CircularProgress } from "@mui/material";
import { ChangeEvent } from "react";
import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { ErrorType } from "../../models/Error";
import { IMAGE_TYPE_VALUE, ImageTypeOptions, ImageUploadType } from "../../models/ExtInspReport";
import { theme } from "../../utils/Theme";
import { mapToFiles } from "./ExtInspReportRegister";

export const inputProps = (
  localFiles: File[],
  files: ImageUploadType | null,
  error: ErrorType | null,
  handleOnRemoveFile?: (index: number) => void,
  handleOnChangeFileInput?: (e: ChangeEvent<HTMLInputElement>) => void,
  labelsImages?: string[],
): InputGroup[] => [
  {
    inputColumns: 1,
    paddingTop: 4,
    fields: [
      localFiles.length > 0
        ? {
            value: files?.name || "",
            label: "Nome",
            name: "name",
            type: "text",
            textcolor: theme.colors.secondary,
            required: true,
            error: !!error,
            onChange: handleOnChangeFileInput,
          }
        : null,
      {
        value: files?.type || IMAGE_TYPE_VALUE.SELECT_TYPE,
        label: "Tipo",
        name: "type",
        type: "select",
        options: ImageTypeOptions,
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        onChange: handleOnChangeFileInput,
      },
      {
        files: (files && mapToFiles(files).files) || [],
        label: "Immagini",
        labelImage: labelsImages || [],
        name: "files[]",
        type: "file",
        inputProps: {
          multiple: true,
          accept: "image/*",
          disabled: files?.type === IMAGE_TYPE_VALUE.SELECT_TYPE,
        },
        textcolor:
          files?.type === IMAGE_TYPE_VALUE.SELECT_TYPE
            ? "InactiveCaptionText"
            : theme.colors.secondary,
        error: !!error,
        onChange: handleOnChangeFileInput,
        onRemoveFile: handleOnRemoveFile,
      },
    ],
  },
];

export const buttonImageProps = (loading: boolean, error: ErrorType | null): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{error.detail}</ErrorText> : null,
    fields: [
      {
        disabled: loading || !!error,
        label: loading ? <CircularProgress size={24} /> : "Salva",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 0.5rem;
`;
