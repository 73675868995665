import { FiltersType } from "../components/Filter";
import { UserResponseType } from "../models/User";
import { formatRoles } from "../utils/Formatters";

export const userFilters: FiltersType<UserResponseType> = [
  {
    label: "Nome",
    filterFn: (user: UserResponseType, filterValue: string | null) => {
      return user.name && filterValue
        ? user.name.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Cognome",
    filterFn: (user: UserResponseType, filterValue: string | null) => {
      return user.surname && filterValue
        ? user.surname.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Email",
    filterFn: (user: UserResponseType, filterValue: string | null) => {
      return user.email && filterValue
        ? user.email.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Ruoli",
    filterFn: (user: UserResponseType, filterValue: string | null) => {
      return user.roles && filterValue && user.roles.length > 0
        ? formatRoles(user.roles).toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
];
