import { FiltersType } from "../../components/Filter";
import { ExtInspReportResponseType } from "../../models/ExtInspReport";

export const extInspReportFilters: FiltersType<ExtInspReportResponseType> = [
  {
    label: "Cliente",
    filterFn: (report: ExtInspReportResponseType, filterValue: string | null) => {
      return report.commercial_user_id && filterValue
        ? report.commercial_user_id.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Richiesta",
    filterFn: (report: ExtInspReportResponseType, filterValue: string | null) => {
      return report.demand_id && filterValue
        ? report.demand_id.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
];
