import * as io from "io-ts";

export const URGENCY_LEVELS = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};
export const DEGREE_COMPLEXITY = {
  EASY: "Facile",
  MEDIUM: "Medio",
  HIGH: "Difficile",
};

export const INSPECTION_TYPE = {
  INTERNAL: "int",
  EXTERNAL: "ext",
};

export const DEMAND_STATE = {
  ASSIGN: "Da assegnare",
  INSPECT: "Da ispezionare",
};

export const urgencyOptions = [
  { value: URGENCY_LEVELS.LOW, label: "Bassa" },
  { value: URGENCY_LEVELS.MEDIUM, label: "Media" },
  { value: URGENCY_LEVELS.HIGH, label: "Alta" },
];

export const degreeComplexityOptions = [
  { value: DEGREE_COMPLEXITY.EASY, label: "Facile" },
  { value: DEGREE_COMPLEXITY.MEDIUM, label: "Medio" },
  { value: DEGREE_COMPLEXITY.HIGH, label: "Difficile" },
];

export const inspectionOptions = [
  { value: INSPECTION_TYPE.INTERNAL, label: "Sopralluogo interno" },
  { value: INSPECTION_TYPE.EXTERNAL, label: "Sopralluogo esterno" },
];

export const demandStatenOptions = [
  { value: DEMAND_STATE.ASSIGN, label: "Da assegnare" },
  { value: DEMAND_STATE.INSPECT, label: "Da ispezionare" },
];

export const Demand = io.type({
  // uuid: io.union([io.string, io.undefined]),
  customer_id: io.union([io.string, io.undefined]),
  address: io.union([io.string, io.undefined]),
  number_estate_units: io.union([io.number, io.undefined]),
  number_floors: io.union([io.number, io.undefined]),
  degree_complexity: io.union([io.string, io.undefined]),
  reference_area: io.union([io.string, io.undefined]),
  unique_protocol: io.union([io.string, io.undefined]),
  demand_date: io.union([io.string, io.undefined]),
  contact_person: io.union([io.string, io.undefined]),
  city: io.union([io.string, io.undefined]),
  province: io.union([io.string, io.undefined]),
  urgency: io.union([io.number, io.undefined]),
  inspection_date: io.union([io.string, io.undefined]),
  processes: io.union([io.string, io.undefined]),
  commercial_user_id: io.union([io.string, io.undefined]),
  inspection_type: io.union([io.string, io.undefined]),
  demand_state: io.union([io.string, io.undefined]),
});

export const DemandEdit = io.type({
  // uuid: io.union([io.string, io.undefined]),
  customer_id: io.union([io.string, io.undefined]),
  address: io.union([io.string, io.undefined]),
  number_estate_units: io.union([io.number, io.undefined]),
  number_floors: io.union([io.number, io.undefined]),
  degree_complexity: io.union([io.string, io.undefined]),
  reference_area: io.union([io.string, io.undefined]),
  unique_protocol: io.union([io.string, io.undefined]),
  demand_date: io.union([io.string, io.undefined]),
  contact_person: io.union([io.string, io.undefined]),
  city: io.union([io.string, io.undefined]),
  province: io.union([io.string, io.undefined]),
  urgency: io.union([io.number, io.undefined]),
  inspection_date: io.union([io.string, io.undefined]),
  processes: io.union([io.string, io.undefined]),
  commercial_user_id: io.union([io.string, io.undefined]),
  inspection_type: io.union([io.string, io.undefined]),
});

export const DemandResponse = io.type({
  id: io.number,
  ...Demand.props,
});

export const DemandRegisterResponse = io.type({
  "@context": io.string,
  "@id": io.string,
  "@type": io.string,
  id: io.number,
  ...Demand.props,
});

export const DemandCollectionResponse = io.type({
  "hydra:totalItems": io.number,
  "hydra:member": io.array(DemandResponse),
  "hydra:view": io.union([
    io.type({
      "@id": io.string,
      "@type": io.string,
      "hydra:first": io.string,
      "hydra:last": io.string,
      "hydra:next": io.union([io.string, io.undefined]),
    }),
    io.undefined,
  ]),
});

export type DemandType = io.TypeOf<typeof Demand>;
export type DemandRegisterResponseType = io.TypeOf<typeof DemandRegisterResponse>;
export type DemandCollectionResponseType = io.TypeOf<typeof DemandCollectionResponse>;
export type DemandResponseType = io.TypeOf<typeof DemandResponse>;
export type DemandEditType = io.TypeOf<typeof DemandEdit>;
