import { Pagination } from "@mui/material";
import * as io from "io-ts";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import FilterBar from "../../components/Filter";
import Loading from "../../components/Loading";
import GenericTable from "../../components/Table";
import { customerHistoryFilters } from "../../constants/customer-histories/CustomerHistoryFiltersItem";
import {
  createCustomerHistoryColumnsIcons,
  customerHistoryColumns,
} from "../../constants/customer-histories/CustomerHistoryTable";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { CustomerResponse, CustomerResponseType } from "../../models/Customer";
import {
  CustomerHistoryCollectionResponse,
  CustomerHistoryCollectionResponseType,
  CustomerHistoryResponseType,
} from "../../models/CustomerHistory";
import { DemandResponse, DemandResponseType } from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { theme } from "../../utils/Theme";

export type DefaultFilterValuesType = { label: string; value: string | null }[];

const transformCustomerDetails = (data: CustomerResponseType) => ({
  name: data.name,
  surname: data.surname,
});

const transformDemandDetails = (data: DemandResponseType) => ({
  protocol: data.unique_protocol,
});

const filterData = (
  data: CustomerHistoryResponseType[],
  filters: DefaultFilterValuesType,
): CustomerHistoryResponseType[] => {
  return data.filter((history) => {
    // Trova i filtri
    const customerFilter = filters.find((filter) => filter.label === "Cliente");
    const demandFilter = filters.find((filter) => filter.label === "Richiesta");

    // Controlla se i filtri sono presenti
    const customerFilterValue = customerFilter?.value?.toLowerCase() || "";
    const demandFilterValue = demandFilter?.value?.toLowerCase() || "";

    // Filtra i dati
    const customerMatch = customerFilterValue
      ? history.customer_id?.toLowerCase().includes(customerFilterValue)
      : true;

    const demandMatch = demandFilterValue
      ? history.demand_id?.toLowerCase().includes(demandFilterValue)
      : true;

    return customerMatch && demandMatch;
  });
};

const CustomerHistoryDataProvider = () => {
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const [customerHistory, setCustomerHistory] =
    useState<CustomerHistoryCollectionResponseType | null>(null);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [defaultFilterValues, setDefaultFilterValues] = useState<DefaultFilterValuesType | null>(
    null,
  );
  const [filteredCustomerHistory, setFilteredCustomerHistory] = useState<
    CustomerHistoryResponseType[]
  >(customerHistory?.["hydra:member"] || []);

  const navigate = useNavigate();
  const location = useLocation();
  const deviceType = useDeviceType();

  const customerName: string = location.state?.customerName || "";
  const demandProtocol: string = location.state?.demandProtocol || "";

  useEffect(() => {
    getCustomerHistory(`/customer_histories?page=${currentPage}`, currentPage);

    // setta i filtri in base al nome del cliente e al protocollo della richiesta
    if (customerName) {
      setDefaultFilterValues([{ label: "Cliente", value: customerName }]);
    }
    if (demandProtocol) {
      setDefaultFilterValues([{ label: "Richiesta", value: demandProtocol }]);
    }
  }, [currentPage, isDeleted, customerName, demandProtocol]);

  useEffect(() => {
    if (customerHistory && defaultFilterValues) {
      const filtered = filterData(customerHistory["hydra:member"], defaultFilterValues);
      setFilteredCustomerHistory(filtered);
    } else {
      setFilteredCustomerHistory(customerHistory?.["hydra:member"] || []);
    }
  }, [customerHistory, customerName, demandProtocol, defaultFilterValues]);

  const handlePageChange = useCallback((_: React.ChangeEvent<unknown>, page: number) => {
    getCustomerHistory(`/customer_histories?page=${page}`, page);
  }, []);

  const handleShowCustomerHistory = useCallback(
    (id: number) => {
      navigate(`/home/customer_histories/${id}`);
    },
    [navigate],
  );

  const handleEditCustomerHistory = useCallback(
    (id: number) => {
      navigate(`/home/customer_histories/edit/${id}`);
    },
    [navigate],
  );

  const handleDeleteCustomerHistory = useCallback(async (id: number) => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare l'interazione?");
    if (!confirmDelete) return;

    setLoading(true);
    try {
      await callApi("DELETE", `/customer_histories/${id}`);

      setIsDeleted((prev) => !prev);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const getCustomerHistory = async (url: string, page: number) => {
    setLoading(true);

    try {
      const response = await callApi<CustomerHistoryCollectionResponseType, undefined>(
        "GET",
        url,
        CustomerHistoryCollectionResponse,
      );

      if (response) {
        const updatedHistory: CustomerHistoryResponseType[] = [];

        for (const history of response["hydra:member"]) {
          const customerId = history.customer_id && history.customer_id.split("/").pop();
          const demandId = history.demand_id && history.demand_id.split("/").pop();

          let customerDetails = null;
          let demandDetails = null;

          if (customerId) {
            customerDetails = await getResponseById(
              "/customers",
              parseInt(customerId),
              CustomerResponse,
              transformCustomerDetails,
            );
          }
          if (demandId) {
            demandDetails = await getResponseById(
              "/demands",
              parseInt(demandId),
              DemandResponse,
              transformDemandDetails,
            );
          }

          updatedHistory.push({
            ...history,
            customer_id: customerDetails
              ? `${customerDetails.name ?? ""} ${customerDetails.surname ?? ""}`
              : "",
            demand_id: demandDetails ? demandDetails.protocol ?? "" : "",
          });
        }

        setCustomerHistory({
          ...response,
          "hydra:member": updatedHistory,
        });

        if (page && response) {
          setCurrentPage(page);
          setTotalPages(Math.ceil(response["hydra:totalItems"] / 10));
        }
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  // TODO: export function
  const getResponseById = async <T,>(
    endpoint: string,
    id: number,
    responseSchema: io.Type<T>,
    transform: (data: T) => any,
  ): Promise<any | null> => {
    try {
      const response = await callApi<T, undefined>("GET", `${endpoint}/${id}`, responseSchema);
      return response ? transform(response) : null;
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      return null;
    }
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer>
          <FilterBar<CustomerHistoryResponseType>
            data={customerHistory?.["hydra:member"] || []}
            filters={customerHistoryFilters}
            columns={deviceType === DeviceType.Mobile ? 2 : 4}
            onFilter={setFilteredCustomerHistory}
            defaultFilterValues={defaultFilterValues}
          />
          {filteredCustomerHistory.length > 0 ? (
            <GenericTable<CustomerHistoryResponseType>
              data={filteredCustomerHistory}
              columns={customerHistoryColumns}
              icons={createCustomerHistoryColumnsIcons(
                handleShowCustomerHistory,
                handleEditCustomerHistory,
                handleDeleteCustomerHistory,
              )}
              loading={loading}
              error={error?.["hydra:description"] || null}
            />
          ) : (
            <NoFoundItems>nessun risultato di ricerca</NoFoundItems>
          )}
          <PaginationContainer $ismobile={deviceType !== DeviceType.Desktop}>
            <StyledPagination
              size="medium"
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </PaginationContainer>
        </TableContainer>
      )}
    </Container>
  );
};

export default CustomerHistoryDataProvider;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const TableContainer = styled.div`
  margin-top: 2rem;
`;

const NoFoundItems = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primary};
`;

const PaginationContainer = styled.div<{ $ismobile: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const StyledPagination = styled(Pagination)`
  && {
    .MuiPaginationItem-root {
    }
    .MuiPaginationItem-page.Mui-selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${theme.colors.primary};
      }
    }
  }
`;
