import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useLocalStorage } from "usehooks-ts";
import { DynamicInputGroup } from "../../components/DynamicInput";
import Form, { InputField } from "../../components/Form";
import Loading from "../../components/Loading";
import { ErrorInputType } from "../../constants/ErrorInput";
import {
  buttonImageProps,
  buttonProps,
  initialFormData,
  inputImageProps,
  inputProps,
} from "../../constants/external-report/ExtInspReportRegister";
import validators from "../../constants/external-report/ExtInspReportValidators";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { ErrorType } from "../../models/Error";
import {
  BalconiesType,
  DeleteResponse,
  DeleteResponseType,
  ExtInspReport,
  ExtInspReportCollectionResponse,
  ExtInspReportCollectionResponseType,
  ExtInspReportImageCollectionResponse,
  ExtInspReportImageCollectionResponseType,
  ExtInspReportRegisterResponse,
  ExtInspReportRegisterResponseType,
  ExtInspReportType,
  FileType,
  FileUploadResponse,
  FileUploadResponseType,
  IMAGE_TYPE_VALUE,
  ImageUploadType,
} from "../../models/ExtInspReport";
import { callApi } from "../../services/ApiServices";
import { getS3ImageUrl } from "../../services/s3Service";
import { handleApiError } from "../../utils/ErrorUtils";
import { validateForm } from "../../utils/Validate";

const ExtInspReportRegisterDataProvider = () => {
  const deviceType = useDeviceType();
  const { loading, error, token, setError, setLoading } = useContext(AppContext);
  const { users, demands, getUsers, getDemands, loadAllData } = useApiData();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<ExtInspReportType>(initialFormData);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [extInspReports, setExtInspReports] = useState<ExtInspReportCollectionResponseType>();
  const [errorInput, setErrorInput] = useState<Record<string, ErrorInputType | null>>({});
  const [dynamicInputGroups, setDynamicInputGroups] = useState<{
    [key: string]: DynamicInputGroup[];
  }>({});
  const [quantities, setQuantities] = useState<{
    [groupIndex: number]: { [fieldIndex: number]: number[] };
  }>({});

  // image upload
  const [isImageUploadForm, setIsImageUploadForm] = useLocalStorage<boolean>(
    "isImageUploadForm",
    false,
  );
  const [reportId, setReportId] = useLocalStorage<number | null>("reportId", null);
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<FileType[]>([]);
  const [imageUploadData, setImageUploadData] = useState<ImageUploadType>({
    name: "",
    type: IMAGE_TYPE_VALUE.SELECT_TYPE,
    "files[]": [],
  });
  const [labelsImages, setLabelsImages] = useState<string[]>([]);

  useEffect(() => {
    if (reportId && extInspReports) {
      const reportExists = extInspReports["hydra:member"].some((report) => report.id === reportId);

      if (!reportExists) {
        setReportId(null);
        setIsImageUploadForm(false);
        setLocalFiles([]);
        setImageUploadData({ name: "", type: "", "files[]": [] });
      }
    }
  }, [extInspReports]);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getUsers);
        await loadAllData(getDemands);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);

      try {
        setFiles([]);
        setLabelsImages([]);
        setImageUploadData((prev) => ({ ...prev, "files[]": [] }));

        await loadAllData(getAndFilterImages);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };

    loadData();
  }, [imageUploadData.type]);

  useEffect(() => {
    if (!token) {
      setIsImageUploadForm(false);
      setReportId(null);
    }
  }, [token]);

  useEffect(() => {
    const validationErrors = validateForm<ExtInspReportType>(formData, validators);
    validationErrors && setErrorInput(validationErrors);

    setError(null);
  }, [formData, imageUploadData]);

  useEffect(() => {
    getExtInspReports();
  }, []);

  const getExtInspReports = async () => {
    setLoading(true);

    try {
      const response = await callApi<ExtInspReportCollectionResponseType, undefined>(
        "GET",
        `/ext_insp_reports`,
        ExtInspReportCollectionResponse,
      );

      if (response && response["hydra:member"].length > 0) {
        setExtInspReports(response);
      }
    } catch (e) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setDataLoading(false);
    }
  };

  // image upload
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;

    if (name === "files[]") {
      if (files) {
        const newFiles = Array.from(files);
        setLocalFiles((prev) => [...prev, ...newFiles]);

        setImageUploadData((prev) => ({
          ...prev,
          "files[]": [...prev["files[]"], ...newFiles.map((file) => URL.createObjectURL(file))],
        }));
      }
    } else {
      setImageUploadData((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    }
  };

  const handleRemoveFile = async (index: number) => {
    const fileToRemove = files[index];

    if (fileToRemove) {
      try {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare l'immagine?");
        if (!confirmDelete) return;

        if (fileToRemove.id) {
          await callApi<DeleteResponseType, undefined>(
            "DELETE",
            `/files/${fileToRemove.id}`,
            DeleteResponse,
          );
        }

        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setLocalFiles((prev) => prev.filter((_, i) => i !== index));
        setLabelsImages((prevLabels) => prevLabels.filter((_, i) => i !== index));

        setImageUploadData((prev) => ({
          ...prev,
          name: "",
          "files[]": prev["files[]"].filter((_, i) => i !== index),
        }));
      } catch (error) {
        handleApiError(error as ErrorType, setError, "Errore durante l'eliminazione del file");
      }
    } else {
      const confirmDelete = window.confirm("Sei sicuro di voler eliminare l'immagine?");
      if (!confirmDelete) return;

      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      setLocalFiles((prev) => prev.filter((_, i) => i !== index));
      setLabelsImages((prevLabels) => prevLabels.filter((_, i) => i !== index));

      setImageUploadData((prev) => ({
        ...prev,
        name: "",
        "files[]": prev["files[]"].filter((_, i) => i !== index),
      }));
    }
  };

  const handleOnSkip = () => {
    const confirmDelete = window.confirm("Sei sicuro di voler saltare l'aggiunta di immagini?");
    if (!confirmDelete) return;

    navigate("/home/ext_insp_report");
    setReportId(null);
    setIsImageUploadForm(false);
    setLocalFiles([]);
    setImageUploadData({ name: "", type: "", "files[]": [] });
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    const updatedValue = type === "checkbox" ? checked : type === "number" ? Number(value) : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
  };

  const getAndFilterImages = async (page: number = 1): Promise<boolean> => {
    setLoading(true);

    try {
      const response = await callApi<ExtInspReportImageCollectionResponseType, undefined>(
        "GET",
        `/files?page=${page}`,
        ExtInspReportImageCollectionResponse,
        undefined,
      );

      if (response) {
        const filteredFiles = response["hydra:member"].filter(
          (file) =>
            file.id_ext_insp_report === Number(reportId) && file.type === imageUploadData.type,
        );
        const imageUrls = filteredFiles.map((file) => getS3ImageUrl(file.path || ""));
        const names = filteredFiles.map((file) => file.name || "");

        setLabelsImages((prev) => [...prev, ...names]);
        setFiles(filteredFiles);

        setImageUploadData((prev) => ({
          ...prev,
          name: "",
          type: imageUploadData.type,
          "files[]": [...prev["files[]"], ...imageUrls.filter((file) => !file.startsWith("blob"))],
        }));

        return response?.["hydra:view"]?.["hydra:next"] !== undefined;
      }

      return false;
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Errore durante il recupero delle immagini");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("name", imageUploadData.name || "");
      payload.append("type", imageUploadData.type || "");
      payload.append("id_ext_insp_report", String(reportId) || "");

      localFiles.forEach((file) => {
        payload.append("files[]", file);
      });

      const response = await callApi<FileUploadResponseType, FormData>(
        "POST",
        `/files/upload`,
        FileUploadResponse,
        undefined,
        payload,
      );

      if (response && response.files.length > 0) {
        const newNames = response.files.map((file) => file.name || "");

        setFiles((prevFiles) => [...prevFiles, ...response.files]);
        setLabelsImages((prevLabels) => [...prevLabels, ...newNames]);
      }

      alert("Immagini salvate con successo!");
      setLocalFiles([]);
    } catch (err) {
      handleApiError(err as ErrorType, setError, "Errore durante il salvataggio dei file");
    } finally {
      setLoading(false);
    }
  };
  // end image upload

  const handleMultiSelectChange = (name: string, selectedValues: string[]) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedValues,
    }));
  };

  useEffect(() => {
    const updateFormData = () => {
      const updatedFormData = { ...formData };

      if (dynamicInputGroups["balconi"]) {
        Object.entries(quantities).forEach(([groupIndexStr, groupQuantities]) => {
          const groupIndex = parseInt(groupIndexStr);
          const balconyKey = `balcone_${groupIndex + 1}`;

          if (!updatedFormData.balconies) updatedFormData.balconies = {};
          if (!updatedFormData.balconies[balconyKey]) {
            updatedFormData.balconies[balconyKey] = {
              pavimenti: [],
              parapetto_muratura: [],
              frontalini: [],
              ringhiere: [],
            };
          }

          Object.entries(groupQuantities).forEach(([fieldIndexStr, quantity]) => {
            const fieldIndex = parseInt(fieldIndexStr);
            const fieldName = dynamicInputGroups["balconi"]?.[groupIndex]?.fields?.[fieldIndex]
              ?.name as keyof BalconiesType;
            const currentFieldValue =
              dynamicInputGroups["balconi"]?.[groupIndex]?.fields?.[fieldIndex]?.value;

            if (fieldName && updatedFormData.balconies) {
              const existingValues = updatedFormData.balconies[balconyKey][fieldName] || [];
              const updatedValues = [...existingValues];

              if (currentFieldValue === "") {
                updatedValues[fieldIndex] = [];
              } else {
                updatedValues[fieldIndex] = Array(quantity.length).fill(currentFieldValue);
              }

              updatedFormData.balconies[balconyKey][fieldName] = updatedValues;
            }
          });
        });
      }

      setFormData(updatedFormData);
    };

    updateFormData();
  }, [quantities, dynamicInputGroups]);

  const handleOnChangeQuantity = (groupIndex: number, fieldIndex: number, quantity: number[]) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [groupIndex]: {
        ...prevQuantities[groupIndex],
        [fieldIndex]: quantity,
      },
    }));
  };

  const handleOnDynamicInputChange = (
    groupTitle: string,
    groupIndex: number,
    fieldIndex: number,
    value: string | string[] | number | boolean,
  ) => {
    const fieldName = dynamicInputGroups[groupTitle]?.[groupIndex]?.fields?.[fieldIndex]?.name;
    if (!fieldName) return;

    setDynamicInputGroups((prevGroups) => {
      const updatedGroups = { ...prevGroups };
      const field = updatedGroups[groupTitle]?.[groupIndex]?.fields?.[fieldIndex];
      if (field) field.value = value;
      return updatedGroups;
    });

    if (groupTitle === "lato facciata") {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        updatedFormData.sides = updatedFormData.sides || [];
        updatedFormData.sides[groupIndex] = {
          ...updatedFormData.sides[groupIndex],
          [fieldName]: value,
        };

        return updatedFormData;
      });
    }
  };

  const handleOnSubmit = async (e: React.FormEvent<Element>) => {
    e.preventDefault();

    setError(null);
    setLoading(true);

    const balconiesData = formData.balconies
      ? (Object.fromEntries(
          Object.entries(formData.balconies).map(([balconyKey, fields]) => [
            balconyKey,
            Object.fromEntries(
              Object.entries(fields).map(([fieldKey, nestedValues]) => [
                fieldKey,
                Array.isArray(nestedValues)
                  ? nestedValues
                      .map((innerArray) =>
                        Array.isArray(innerArray)
                          ? innerArray.filter((value) => value !== "" && value !== undefined)
                          : [],
                      )
                      .filter((innerArray) => innerArray.length > 0)
                  : [],
              ]),
            ),
          ]),
        ) as ExtInspReportType["balconies"])
      : null;

    const updatedFormData = {
      ...formData,
      ...(balconiesData && { balconies: balconiesData }),
      commercial_user_id: `/api/users/${formData.commercial_user_id}`,
      demand_id: `/api/demands/${formData.demand_id}`,
    };

    try {
      const response = await callApi<ExtInspReportRegisterResponseType, ExtInspReportType>(
        "POST",
        "/ext_insp_reports",
        ExtInspReportRegisterResponse,
        ExtInspReport,
        updatedFormData,
      );

      if (response) {
        setFormData(initialFormData);
        setDynamicInputGroups({});

        setReportId(response.id);
        setIsImageUploadForm(true);

        await getExtInspReports();
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  const addField = (groupTitle: string, groupIndex: number, fieldIndex: number) => {
    setDynamicInputGroups((prevGroups) => {
      const targetGroup = prevGroups[groupTitle]?.[groupIndex];
      if (!targetGroup) return prevGroups;

      const duplicatedField = { ...targetGroup.fields[fieldIndex], value: "" };

      if (!("name" in duplicatedField) || !duplicatedField.name) {
        return prevGroups;
      }

      const updatedFields = [...targetGroup.fields, duplicatedField];

      const updatedGroup = { ...targetGroup, fields: updatedFields as InputField[] };

      return {
        ...prevGroups,
        [groupTitle]: prevGroups[groupTitle].map((g, idx) =>
          idx === groupIndex ? updatedGroup : g,
        ),
      };
    });

    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };

      if (!newQuantities[groupIndex]) {
        newQuantities[groupIndex] = {};
      }

      if (!newQuantities[groupIndex][fieldIndex]) {
        newQuantities[groupIndex][fieldIndex] = [];
      }

      return newQuantities;
    });
  };

  const removeField = (groupTitle: string, subGroupIndex: number, fieldIndex: number) => {
    setDynamicInputGroups((prevGroups) => {
      const targetGroup = prevGroups[groupTitle]?.[subGroupIndex];
      if (!targetGroup || targetGroup.fields.length <= 1) return prevGroups;

      const updatedFields = targetGroup.fields.filter((_, index) => index !== fieldIndex);
      const updatedGroup = { ...targetGroup, fields: updatedFields };

      return {
        ...prevGroups,
        [groupTitle]: prevGroups[groupTitle].map((value, index) =>
          index === subGroupIndex ? updatedGroup : value,
        ),
      };
    });

    setFormData((prevState) => {
      const newState = { ...prevState };
      const balconyKey = `balcone_${subGroupIndex + 1}`;
      const fieldName = dynamicInputGroups[groupTitle]?.[subGroupIndex]?.fields?.[fieldIndex]?.name;
      const fieldKey = fieldName as keyof BalconiesType;

      if (fieldKey && newState.balconies && newState.balconies[balconyKey]) {
        const updatedArray = [...(newState.balconies[balconyKey][fieldKey] || [])];

        if (updatedArray[fieldIndex]) {
          updatedArray.splice(fieldIndex, 1);
        }

        newState.balconies[balconyKey] = {
          ...newState.balconies[balconyKey],
          [fieldKey]: updatedArray.length > 0 ? updatedArray : [],
        };
      }

      return newState;
    });

    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      const fieldQuantities = updatedQuantities[subGroupIndex];

      if (fieldQuantities) {
        const newFieldQuantities = Object.keys(fieldQuantities)
          .filter((key) => parseInt(key) !== fieldIndex)
          .reduce((acc, key) => {
            const newKey = parseInt(key) > fieldIndex ? parseInt(key) - 1 : parseInt(key);
            acc[newKey] = fieldQuantities[parseInt(key)];
            return acc;
          }, {} as { [index: number]: number[] });

        updatedQuantities[subGroupIndex] = newFieldQuantities;
      }

      return updatedQuantities;
    });
  };

  const addFieldGroup = (groupTitle: string) => {
    const groups = inputProps(
      formData,
      users,
      demands,
      error,
      errorInput,
      deviceType === DeviceType.Mobile,
      handleOnChange,
      handleMultiSelectChange,
      extInspReports,
    );

    // Trova il gruppo che corrisponde al gruppo dinamico da aggiungere
    const fieldGroup = groups.find((g) =>
      g.groups?.some((sg) => sg.isDynamicInput && sg.groupTitle === groupTitle),
    );

    if (!fieldGroup) return; // Se il gruppo non esiste, esci dalla funzione

    const currentGroup = fieldGroup.groups?.find((g) => g.groupTitle === groupTitle);

    // Se il gruppo corrente esiste, prosegui con l'aggiunta
    if (currentGroup?.subGroups) {
      const currentIndex = dynamicInputGroups[groupTitle]?.length ?? 0;

      // Controlla se ci sono ancora sottogruppi da aggiungere
      if (currentIndex < currentGroup.subGroups.length) {
        const nextSubGroup = currentGroup.subGroups[currentIndex];

        // Aggiungi il sottogruppo ai gruppi dinamici
        setDynamicInputGroups((prev) => ({
          ...prev,
          [groupTitle]: [
            ...(prev[groupTitle] || []),
            {
              ...nextSubGroup,
              fields: nextSubGroup.fields || [],
            },
          ],
        }));

        // Aggiungi il nuovo balcone o lato facciata ai dati del form
        setFormData((prev) => {
          if (groupTitle === "lato facciata") {
            const updatedSides = [
              ...prev.sides,
              { mt: "", ground: "", border: "", h_interplane: "" },
            ];
            return {
              ...prev,
              sides: updatedSides,
            };
          } else if (groupTitle === "balconi") {
            const nextBalconyKey = `balcone_${Object.keys(prev.balconies || {}).length + 1}`;
            return {
              ...prev,
              balconies: {
                ...prev.balconies,
                [nextBalconyKey]: {
                  pavimenti: [],
                  parapetto_muratura: [],
                  frontalini: [],
                  ringhiere: [],
                },
              },
            };
          }
          return prev;
        });
      }
    }
  };

  const removeFieldGroup = (groupTitle: string, groupIndex: number) => {
    // Aggiorna i gruppi dinamici rimuovendo il gruppo specifico in base all'indice
    setDynamicInputGroups((prev) => ({
      ...prev,
      [groupTitle]: prev[groupTitle]
        .filter((_, idx) => idx !== groupIndex) // Rimuove il gruppo all'indice specificato
        .map((group, idx) => ({
          ...group,
          title: groupTitle === "lato facciata" ? `L${idx + 1}` : `Balcone ${idx + 1}`,
        })),
    }));

    setFormData((prevState) => {
      const updatedFormData = { ...prevState };

      if (groupTitle === "lato facciata") {
        updatedFormData.sides = updatedFormData.sides.filter((_, idx) => idx !== groupIndex);

        // Se non ci sono più lati facciata, imposta sides in un array vuoto
        if (updatedFormData.sides.length === 0) {
          updatedFormData.sides = [];
        }
      } else if (groupTitle === "balconi") {
        // chiave del balcone da rimuovere
        const balconyKey = `balcone_${groupIndex + 1}`;

        if (updatedFormData.balconies && updatedFormData.balconies[balconyKey]) {
          // Rimuovi il balcone specifico
          delete updatedFormData.balconies[balconyKey];
        }

        // Se non ci sono più balconi, imposta balconies a null
        if (!updatedFormData.balconies || Object.keys(updatedFormData.balconies).length === 0) {
          updatedFormData.balconies = null;
        } else {
          // Riorganizza gli indici dei balconi rimanenti
          updatedFormData.balconies = Object.keys(updatedFormData.balconies).reduce(
            (acc, key, idx) => {
              const newKey = `balcone_${idx + 1}`; // Nuovo indice per il balcone
              acc[newKey] = updatedFormData.balconies![key];
              return acc;
            },
            {} as Record<string, BalconiesType>,
          );
        }
      }

      return updatedFormData;
    });

    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      delete updatedQuantities[groupIndex];
      return updatedQuantities;
    });
  };

  return (
    <Container>
      {!isImageUploadForm ? (
        <Form
          title={deviceType === DeviceType.Mobile ? "Aggiungi sopralluogo esterno" : ""}
          inputProps={inputProps(
            formData,
            users,
            demands,
            error,
            errorInput,
            deviceType === DeviceType.Mobile,
            handleOnChange,
            handleMultiSelectChange,
            extInspReports,
          )}
          buttonProps={buttonProps(dataLoading, loading, error ?? undefined)}
          onSubmit={handleOnSubmit}
          dynamicInputProps={{
            dynamicInputGroups,
            mode: "register",
            quantities: quantities,
            onChangeDynamicInput: handleOnDynamicInputChange,
            onChangeQuantity: handleOnChangeQuantity,
            addFieldGroup: addFieldGroup,
            removeFieldGroup: removeFieldGroup,
            addField: addField,
            removeField: removeField,
          }}
        />
      ) : dataLoading ? (
        <Loading />
      ) : (
        <Form
          title="Immagini sopralluogo esterno"
          showSkipLabel={!!reportId}
          onSkip={handleOnSkip}
          inputProps={inputImageProps(
            localFiles,
            imageUploadData,
            error,
            handleRemoveFile,
            handleInputChange,
            labelsImages,
          )}
          buttonProps={buttonImageProps(dataLoading, loading, error ?? undefined)}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  justify-self: center;
`;

export default ExtInspReportRegisterDataProvider;
