import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { DemandCollectionResponseType } from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import {
  downspoutsTypeOptions,
  ExtInspReportType,
  facadeTypeOptions,
  guttersTypeOptions,
  roofTypeOptions,
} from "../../models/ExtInspReport";
import { UserResponseCollectionType } from "../../models/User";
import { currentDate, formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";

export const inputProps = (
  editData: ExtInspReportType,
  users: UserResponseCollectionType | null,
  demands: DemandCollectionResponseType | null,
  error: ErrorType | null,
  resize: boolean,
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleMultiSelectChange: (name: string, selectedValues: string[]) => void,
): InputGroup[] => {
  const userOptions = users
    ? users["hydra:member"].map((user) => ({
        value: user.id,
        label: `${user.name ?? ""} ${user.surname ?? ""}`,
      }))
    : [];

  const demandOptions = demands
    ? demands["hydra:member"].map((demand) => ({
        value: demand.id,
        label: `${demand.unique_protocol ?? ""}`,
      }))
    : [];

  return [
    {
      section: "Dati generici",
      fields: [
        {
          value:
            userOptions.find((option) => option.value === Number(editData.commercial_user_id))
              ?.value || "",
          label: "Commerciale di riferimento",
          name: "commercial_user_id",
          type: "select",
          options: userOptions,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value:
            demandOptions.find((option) => option.value === Number(editData.demand_id))?.value ||
            "",
          label: "Richiesta",
          name: "demand_id",
          type: "select",
          options: demandOptions,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: editData.date ? formatDate(editData.date) : "",
          label: "Data",
          name: "date",
          type: "date",
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          inputProps: { max: currentDate() },
          onChange: handleOnChange,
        },
        {
          value: editData.address || "",
          label: "Indirizzo",
          name: "address",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: editData.work_contact || "",
          label: "Referente lavori",
          name: "work_contact",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: editData.phone_work_contact || "",
          label: "Cellulare referente lavori",
          name: "phone_work_contact",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: editData.email_work_contact || "",
          label: "Mail referente lavori",
          name: "email_work_contact",
          type: "email",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: editData.access_contact || "",
          label: "Referente accesso",
          name: "access_contact",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: editData.phone_access_contact || "",
          label: "Cellulare referente accesso",
          name: "phone_access_contact",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: editData.email_access_contact || "",
          label: "Mail referente accesso",
          name: "email_access_contact",
          type: "email",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: editData.other || "",
          label: "Altro",
          name: "other",
          type: "text",
          multiline: true,
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
      ],
      inputColumns: resize ? 1 : 3,
    },
    {
      section: "Info",
      groups: [
        {
          inputColumns: resize ? 1 : 3,
          rowGap: 2,
          fields: [
            // checkbox
            {
              checked: editData.elevator || false,
              label: "Ascensore",
              name: "elevator",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.attic || false,
              label: "Sottotetto",
              name: "attic",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.life_line || false,
              label: "Linea vita",
              name: "life_line",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.water_point || false,
              label: "Punto acqua",
              name: "water_point",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.light_point || false,
              label: "Punto luce",
              name: "light_point",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.tech_stock_room || false,
              label: "Locale tecnico/stoccaggio",
              name: "tech_stock_room",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.condos_roof_access || false,
              label: "Accesso tetto condominio",
              name: "condos_roof_access",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.private_cover_access || false,
              label: "Accesso copertura privata",
              name: "private_cover_access",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          inputColumns: resize ? 1 : 3,
          rowGap: 2,
          paddingTop: 8,
          fields: [
            {
              value: editData.roof_type || "",
              selectedvalues: (editData.roof_type || []).filter(
                (v): v is string => v !== undefined,
              ),
              label: "Tipologia tetto",
              name: "roof_type",
              type: "multiselect",
              options: roofTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("roof_type", newSelectedValues),
            },
            {
              value: editData.number_pitches || "",
              label: "Numero falde",
              name: "number_pitches",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.floors_number || "",
              label: "N° piani",
              name: "floors_number",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.water_point_place || "",
              label: "Punto acqua luogo",
              name: "water_point_place",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.light_point_place || "",
              label: "Punto luce luogo",
              name: "light_point_place",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.tech_stock_room_place || "",
              label: "Locale tecnico/stoccaggio luogo",
              name: "tech_stock_room_place",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.number_estate_units || "",
              label: "N° unità immobiliari",
              name: "number_estate_units",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.condo_name || "",
              label: "Nome condominio",
              name: "condo_name",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.condo_surname || "",
              label: "Cognome condominio",
              name: "condo_surname",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.phone || "",
              label: "Telefono",
              name: "phone",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.email || "",
              label: "Email",
              name: "email",
              type: "email",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          paddingTop: 4,
          fields: [
            {
              value: editData.notes || "",
              label: "Note",
              name: "notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Facciate",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          rowGap: 2,
          fields: [
            {
              checked: editData.window_wash || false,
              label: "Lavaggio vetri",
              name: "window_wash",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.facade_wash || false,
              label: "Lavaggio facciate",
              name: "facade_wash",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          inputColumns: resize ? 1 : 2,
          rowGap: 2,
          paddingTop: 8,
          fields: [
            {
              value: editData.securing || "",
              selectedvalues: (editData.securing || []).filter((v): v is string => v !== undefined),

              label: "Messa in sicurezza",
              name: "securing",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("securing", newSelectedValues),
            },
            {
              value: editData.restoration || "",
              selectedvalues: (editData.restoration || []).filter(
                (v): v is string => v !== undefined,
              ),
              label: "Ripristini",
              name: "restoration",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("restoration", newSelectedValues),
            },
            {
              value: editData.shave || "",
              selectedvalues: (editData.shave || []).filter((v): v is string => v !== undefined),
              label: "Rasatura",
              name: "shave",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("shave", newSelectedValues),
            },
            {
              value: editData.painting || "",
              selectedvalues: (editData.painting || []).filter((v): v is string => v !== undefined),
              label: "Tinteggiatura",
              name: "painting",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("painting", newSelectedValues),
            },
            {
              value: editData.water_repellent_treatment || "",
              selectedvalues: editData.water_repellent_treatment || "",
              label: "Trattamento idrorepellente",
              name: "water_repellent_treatment",
              type: "multiselect",
              options: facadeTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("water_repellent_treatment", newSelectedValues),
            },
          ],
        },
      ],
    },
    {
      section: "Balconi",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          rowGap: 2,
          fields: [
            {
              checked: editData.adjustment || false,
              label: "Adeguamento",
              name: "adjustment",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.waterproofing || false,
              label: "Impermeabilizzazione",
              name: "waterproofing",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.flooring || false,
              label: "Pavimentazione",
              name: "flooring",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.rehabilitation_railings || false,
              label: "Risanamento ringhiere",
              name: "rehabilitation_railings",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          paddingTop: 8,
          fields: [
            {
              value: editData.balconies_notes || "",
              label: "Note balconi",
              name: "balconies_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Copertura",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          rowGap: 2,
          fields: [
            {
              checked: editData.antivolatile || false,
              label: "Antivolatili",
              name: "antivolatile",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.insulated || false,
              label: "Coibentato",
              name: "insulated",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.new_roof || false,
              label: "Nuovo tetto (listelli, tegole)",
              name: "new_roof",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.structure || false,
              label: "Struttura",
              name: "structure",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.lifeline_project || false,
              label: "Linea vita progetto",
              name: "lifeline_project",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.maintenance || false,
              label: "Manutenzione",
              name: "maintenance",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.sheath || false,
              label: "Guaina",
              name: "sheath",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              checked: editData.tiles || false,
              label: "Tegola",
              name: "tiles",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          inputColumns: 1,
          paddingTop: 8,
          fields: [
            {
              value: editData.obligatory_booklet || "",
              label: "Libretto obbligatorio",
              name: "obligatory_booklet",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          inputColumns: 1,
          paddingTop: 4,
          fields: [
            {
              checked: editData.overhaul_coverage || false,
              label: "Ripasso copertura",
              name: "overhaul_coverage",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.overhaul_coverage_percentage || "",
              label: "",
              name: "overhaul_coverage_percentage",
              type: "percentage",
              inputProps: {
                step: "0.01",
                min: "0",
              },
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Lattoneria",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          rowGap: 2,
          fields: [
            {
              value: editData.gutters || "",
              selectedvalues: (editData.gutters || []).filter((v): v is string => v !== undefined),
              label: "Gronde",
              name: "gutters",
              type: "multiselect",
              options: guttersTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("gutters", newSelectedValues),
            },
            {
              value: editData.gutters_mt || "",
              label: "Gronde (mt)",
              name: "gutters_mt",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
            {
              value: editData.downspouts || "",
              selectedvalues: (editData.downspouts || []).filter(
                (v): v is string => v !== undefined,
              ),
              label: "Pluviali",
              name: "downspouts",
              type: "multiselect",
              options: downspoutsTypeOptions,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: (newSelectedValues: string[]) =>
                handleMultiSelectChange("downspouts", newSelectedValues),
            },
            {
              value: editData.downspouts_mt || "",
              label: "Scossaline (mt)",
              name: "downspouts_mt",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
        {
          paddingTop: 4,
          fields: [
            {
              value: editData.tinsmithery_notes || "",
              label: "Note lattoneria",
              name: "tinsmithery_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Facciata",
      groups: [
        {
          isDynamicInput: true,
          groupTitle: "lato facciata",
          subGroups: Array.from({ length: 6 }, (_, index) => ({
            title: `L${index + 1}`,
            inputColumns: resize ? 1 : 4,
            fields: [
              {
                value: editData.sides[index]?.mt || "",
                label: "Metrature",
                name: "mt",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
                error: !!error,
                onChange: handleOnChange,
              },
              {
                value: editData.sides[index]?.ground || "",
                label: "Terra",
                name: "ground",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
                error: !!error,
                onChange: handleOnChange,
              },
              {
                value: editData.sides[index]?.border || "",
                label: "Bordo",
                name: "border",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
                error: !!error,
                onChange: handleOnChange,
              },
              {
                value: editData.sides[index]?.h_interplane || "",
                label: "H Interpiano",
                name: "h_interplane",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
                error: !!error,
                onChange: handleOnChange,
              },
            ],
          })),
        },
        {
          paddingTop: 4,
          fields: [
            {
              value: editData.facade_notes || "",
              label: "Note facciata",
              name: "facade_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
    {
      section: "Balconi privati",
      groups: [
        {
          isDynamicInput: true,
          isVisibleQuantity: true,
          groupTitle: "balconi",
          subGroups: Array.from(
            { length: Object.keys(editData.balconies || {}).length + 1 },
            (_, index) => {
              const balcony = editData.balconies && editData.balconies[`balcone_${index + 1}`]; // Get the current balcony
              return {
                inputColumns: resize ? 1 : 4,
                title: `Balcone ${index + 1}`,
                fields: [
                  ...(balcony && Array.isArray(balcony.pavimenti) && balcony.pavimenti.length > 0
                    ? balcony.pavimenti.map((pavimentiValues) => ({
                        value: Array.isArray(pavimentiValues) ? pavimentiValues[0] || "" : "",
                        label: "MQ Pavimenti/Cielini",
                        name: "pavimenti",
                        type: "text",
                        textcolor: theme.colors.secondary,
                        required: false,
                        error: !!error,
                      }))
                    : [
                        {
                          value: "",
                          label: "MQ Pavimenti/Cielini",
                          name: "pavimenti",
                          type: "text",
                          textcolor: theme.colors.secondary,
                          required: false,
                          error: !!error,
                        },
                      ]),
                  ...(balcony && Array.isArray(balcony.frontalini) && balcony.frontalini.length > 0
                    ? balcony.frontalini.map((frontaliniValues) => ({
                        value: Array.isArray(frontaliniValues) ? frontaliniValues[0] || "" : "",
                        label: `ML Frontalini`,
                        name: "frontalini",
                        type: "text",
                        textcolor: theme.colors.secondary,
                        required: false,
                        error: !!error,
                      }))
                    : [
                        {
                          value: "",
                          label: "ML Frontalini",
                          name: "frontalini",
                          type: "text",
                          textcolor: theme.colors.secondary,
                          required: false,
                          error: !!error,
                        },
                      ]),

                  ...(balcony &&
                  Array.isArray(balcony.parapetto_muratura) &&
                  balcony.parapetto_muratura.length > 0
                    ? balcony.parapetto_muratura.map((parapettoValues) => ({
                        value: Array.isArray(parapettoValues) ? parapettoValues[0] || "" : "",
                        label: "ML Parapetto Muratura",
                        name: "parapetto_muratura",
                        type: "text",
                        textcolor: theme.colors.secondary,
                        required: false,
                        error: !!error,
                      }))
                    : [
                        {
                          value: "",
                          label: "ML Parapetto Muratura",
                          name: "parapetto_muratura",
                          type: "text",
                          textcolor: theme.colors.secondary,
                          required: false,
                          error: !!error,
                        },
                      ]),

                  ...(balcony && Array.isArray(balcony.ringhiere) && balcony.ringhiere.length > 0
                    ? balcony.ringhiere.map((ringhiereValues) => ({
                        value: Array.isArray(ringhiereValues) ? ringhiereValues[0] || "" : "",
                        label: "ML Ringhiere",
                        name: "ringhiere",
                        type: "text",
                        textcolor: theme.colors.secondary,
                        required: false,
                        error: !!error,
                      }))
                    : [
                        {
                          value: "",
                          label: "ML Ringhiere",
                          name: "ringhiere",
                          type: "text",
                          textcolor: theme.colors.secondary,
                          required: false,
                          error: !!error,
                        },
                      ]),
                ],
              };
            },
          ),
        },
        {
          paddingTop: 4,
          fields: [
            {
              value: editData.private_balconies_notes || "",
              label: "Note balconi privati",
              name: "private_balconies_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            },
          ],
        },
      ],
    },
  ];
};

export const buttonProps = (
  dataLoading: boolean,
  loading: boolean,
  error: ErrorType | undefined,
): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{error.detail}</ErrorText> : null,
    fields: [
      {
        disabled: dataLoading || loading || !!error,
        label: dataLoading ? (
          <CircularProgress size={24} />
        ) : (
          "Modifica verbale sopralluogo esterno"
        ),
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 0.5rem;
`;
