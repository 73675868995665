import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Column, IconType } from "../../components/Table";
import { DemandResponseType, DemandType } from "../../models/Demand";
import { theme } from "../../utils/Theme";

export const demandColumns: Column<DemandType>[] = [
  { header: "Cliente", accessor: "customer_id" },
  { header: "Protocollo univoco", accessor: "unique_protocol" },
  { header: "Stato richiesta", accessor: "demand_state" },
];

export const createDemandColumnsIcons = (
  onShow: (id: number) => void,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
  navigate?: (demand: DemandResponseType) => void,
): IconType<DemandResponseType>[] => [
  {
    icon: <HistoryIcon />,
    color: theme.colors.background,
    titleTooltip: "Visualizza storico (filtra per richiesta)",
    onClick: (demand) => navigate && navigate(demand),
  },
  {
    icon: <VisibilityIcon />,
    color: theme.colors.background,
    titleTooltip: "Visualizza richiesta",
    onClick: (customer: DemandResponseType) => onShow(customer.id),
  },
  {
    icon: <EditIcon />,
    color: theme.colors.background,
    titleTooltip: "Modifica richiesta",
    onClick: (customer: DemandResponseType) => onEdit(customer.id),
  },
  {
    icon: <DeleteIcon />,
    color: theme.colors.error,
    titleTooltip: "Rimuovi richiesta",
    onClick: (customer: DemandResponseType) => onDelete(customer.id),
  },
];
