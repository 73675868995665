import { Grid, GridOwnProps } from "@mui/material";
import { Fragment, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../utils/Theme";
import CustomButton, { CustomButtonsProps } from "./Button";
import DynamicInput, { DynamicInputGroup } from "./DynamicInput";
import CustomInput, { CustomInputProps, CustomLabel } from "./Input";
import SectionPagination from "./SectionPagination";
import CustomSelect, { CustomMultiSelectProps, CustomSelectProps } from "./Select";

export type InputField = CustomInputProps | CustomSelectProps | CustomMultiSelectProps | null;

export type LayoutProps = {
  inputColumns?: number;
  rowGap?: number;
  columnsGap?: number;
  paddingTop?: number;
  childrenInput?: ReactNode;
};

export type InputGroup = {
  section?: string;
  fields?: InputField[];
  groups?: InputGroup[];
  isVisibleQuantity?: boolean;
  subGroups?: InputGroup[];
  isDynamicInput?: boolean;
  title?: string;
  groupTitle?: string;
} & LayoutProps;

export type ButtonGroup = {
  fields: CustomButtonsProps[];
  buttonColumns?: number;
  rowGap?: number;
  columnsGap?: number;
  paddingTop?: number;
  childrenButton?: ReactNode;
};

export type DynamicInputGroupType = {
  dynamicInputGroups?: { [key: string]: DynamicInputGroup[] };
  mode?: "edit" | "register" | "view";
  quantities?: {
    [groupIndex: number]: { [fieldIndex: number]: number[] };
  };
  addFieldGroup?: (groupTitle: string) => void;
  removeFieldGroup?: (groupTitle: string, index: number) => void;
  onChangeDynamicInput?: (
    groupTitle: string,
    groupIndex: number,
    fieldIndex: number,
    value: string | string[] | number | boolean,
  ) => void;
  onChangeQuantity?: (groupIndex: number, fieldIndex: number, value: number[]) => void;
  addField?: (groupTitle: string, subGroupIndex: number, fieldIndex: number) => void;
  removeField?: (groupTitle: string, subGroupIndex: number, fieldIndex: number) => void;
};

interface FormProps extends GridOwnProps {
  title?: string;
  inputProps: InputGroup[];
  buttonProps?: ButtonGroup[];
  onSubmit: (event: React.FormEvent) => void;
  dynamicInputProps?: DynamicInputGroupType;
  onSkip?: () => void;
  showSkipLabel?: boolean;
}

const Form = ({
  title,
  inputProps = [],
  buttonProps = [],
  dynamicInputProps,
  onSubmit,
  onSkip,
  showSkipLabel = false,
  ...props
}: FormProps) => {
  const sections = inputProps.filter((group) => group.section).map((group) => group.section!);

  const [selectedSection, setSelectedSection] = useState<string>(
    sections.length > 0 ? sections[0] : "Dati generici",
  );

  useEffect(() => {
    if (sections.length > 0 && !sections.includes(selectedSection)) {
      setSelectedSection(sections[0]);
    }
  }, [sections, selectedSection]);

  const [error, setError] = useState<string | null>(null);
  const {
    dynamicInputGroups,
    mode,
    onChangeDynamicInput: handleOnChangeDynamicInput,
    onChangeQuantity: handleOnChangeQuantity,
    addFieldGroup,
    removeFieldGroup,
    addField,
    removeField,
  } = dynamicInputProps || {};

  const handleSectionChange = (section: string) => {
    setSelectedSection(section);
  };

  const validateForm = () => {
    for (const group of inputProps) {
      if (group.groups) {
        for (const subGroup of group.groups) {
          for (const field of subGroup.fields || []) {
            if (field?.required && !field.value) {
              setSelectedSection(group.section || "");
              setError(`Il campo (${field.label}) è obbligatorio.`);
              return false;
            }
          }
        }
      } else {
        for (const field of group.fields || []) {
          if (field?.required && !field.value) {
            setSelectedSection(group.section || "");
            setError(`Il campo ${field.label} è obbligatorio.`);
            return false;
          }
        }
      }
    }
    setError(null);
    return true;
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validateForm()) {
      onSubmit(event);
    }
  };

  const renderInputFields = (group: InputGroup) => {
    return (
      <>
        <GridContainerInput
          container
          spacing={2}
          rowGap={group.rowGap}
          columnGap={group.columnsGap}
          paddingTop={group.paddingTop}
          key={`group-input`}
          {...props}
        >
          {group.fields?.map((fieldProps, index) =>
            fieldProps ? (
              <StyledGrid item xs={12 / (group.inputColumns || 1)} key={`input-${index}`}>
                {"options" in fieldProps ? (
                  <CustomSelect
                    textcolor={fieldProps.textcolor}
                    {...(fieldProps as CustomMultiSelectProps)}
                  />
                ) : (
                  <CustomInput
                    textcolor={fieldProps.textcolor}
                    {...(fieldProps as CustomInputProps)}
                  />
                )}
                {group.childrenInput && group.childrenInput}
              </StyledGrid>
            ) : null,
          )}
        </GridContainerInput>

        {group.subGroups?.map((subGroup, subIndex) => (
          <Fragment key={`subgroup-${subIndex}`}>
            <CustomLabel style={{ marginBottom: 16 }} textcolor={theme.colors.secondary}>
              {subGroup.title}
            </CustomLabel>
            {renderInputFields(subGroup)}
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <CustomForm onSubmit={handleSubmit}>
      {showSkipLabel ? (
        <HeaderContainer>
          {title && <Title>{title}</Title>}
          {showSkipLabel && (
            <SkipLabel textcolor={theme.colors.secondary} onClick={onSkip}>
              Salta e aggiungi le foto dopo
            </SkipLabel>
          )}
        </HeaderContainer>
      ) : (
        title && <Title>{title}</Title>
      )}

      {sections.length > 0 && (
        <SectionPagination
          sections={sections}
          selectedSection={selectedSection}
          onChange={handleSectionChange}
        />
      )}

      {inputProps
        .filter((group) => !group.section || group.section === selectedSection)
        .map((group, index) => (
          <GroupContainer key={`group-${index}`}>
            {group.groups
              ? group.groups.map((subGroup, subIndex) => {
                  if (subGroup.isDynamicInput) {
                    return (
                      <DynamicInput
                        key={`dynamic-input-${subIndex}`}
                        inputGroups={
                          (dynamicInputGroups && dynamicInputGroups[subGroup.groupTitle!]) || []
                        }
                        isDynamicInput={subGroup.isDynamicInput}
                        groupTitle={subGroup.groupTitle!}
                        subGroupTitle={subGroup.groupTitle!}
                        mode={mode}
                        isVisibleQuantity={subGroup.isVisibleQuantity}
                        quantities={dynamicInputProps?.quantities}
                        onChangeDynamicInput={(groupIndex, fieldIndex, value) =>
                          handleOnChangeDynamicInput!(
                            subGroup.groupTitle!,
                            groupIndex,
                            fieldIndex,
                            value,
                          )
                        }
                        onChangeQuantity={(groupIndex, fieldIndex, value) =>
                          handleOnChangeQuantity!(groupIndex, fieldIndex, value)
                        }
                        addFieldGroup={() => addFieldGroup!(subGroup.groupTitle!)}
                        removeFieldGroup={(index) => removeFieldGroup!(subGroup.groupTitle!, index)}
                        addField={addField}
                        removeField={removeField}
                      />
                    );
                  }
                  return (
                    <Fragment key={`subgroup-${subIndex}`}>{renderInputFields(subGroup)}</Fragment>
                  );
                })
              : renderInputFields(group)}
          </GroupContainer>
        ))}

      {buttonProps?.map((group, groupIndex) => (
        <GridContainerButton
          container
          spacing={2}
          rowGap={group.rowGap}
          columnGap={group.columnsGap}
          paddingTop={group.paddingTop}
          {...props}
          key={`group-button-${groupIndex}`}
        >
          {group.fields.map((buttonProps, buttonIndex) => (
            <Grid item xs={12 / (group.buttonColumns || 1)} key={`button-${buttonIndex}`}>
              {error && <ErrorText>{error}</ErrorText>}
              {group.childrenButton}
              <CustomButton
                type={buttonProps.type || "button"}
                hoverBgcolor={buttonProps.hoverBgcolor}
                hoverlabelcolor={buttonProps.hoverlabelcolor}
                bgcolor={buttonProps.bgcolor}
                label={buttonProps.label}
                labelcolor={buttonProps.labelcolor}
                {...buttonProps}
              />
            </Grid>
          ))}
        </GridContainerButton>
      ))}
    </CustomForm>
  );
};

const CustomForm = styled.form`
  padding: 2rem;
  margin: 2rem;
  border-radius: 1rem;
  background-color: ${theme.colors.white};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const SkipLabel = styled(CustomLabel)`
  && {
    cursor: pointer;
    margin-bottom: 2rem;
    text-decoration: underline;
  }
`;

const GroupContainer = styled.div`
  width: 100%;
`;

const GridContainerInput = styled(Grid)`
  && {
    align-items: center;
  }
`;

const GridContainerButton = styled(Grid)`
  && {
    align-items: center;
  }
`;

const Title = styled.div`
  margin-top: 0;
  margin-bottom: 2rem;
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.semibold};
  font-size: ${theme.fontSizes.medium};
  color: ${theme.colors.secondary};
  text-align: center;
`;

const StyledGrid = styled(Grid)`
  && {
    .MuiFormControl-root {
      min-height: 5rem;
    }
  }
`;

const ErrorText = styled.div`
  color: ${theme.colors.error};
  font-family: ${theme.fonts.primary};
  margin-bottom: 0.5rem;
`;

export default Form;
