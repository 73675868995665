import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Form from "../../components/Form";
import { buttonProps, inputProps } from "../../constants/user/UserLogin";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { UserType } from "../../models/User";

const UserLoginDataProvider = () => {
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const { token, loading, login, error, setError } = useContext(AppContext);
  const [formData, setFormData] = useState<UserType>({
    email: "",
    password: "",
    rememberMe: true,
  });

  useEffect(() => {
    if (token) {
      const redirectTo = localStorage.getItem("sidebarItemPath") || "/home";
      navigate(redirectTo);
    }
  }, [token, navigate]);

  useEffect(() => {
    setError(null);
  }, [formData.email, formData.password, setError]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent<Element>) => {
    e.preventDefault();
    await login(formData);
  };

  return (
    <LoginContainer devicetype={deviceType}>
      <Form
        title="Login"
        inputProps={inputProps(formData, error, handleOnChange)}
        buttonProps={buttonProps(loading, error, formData)}
        onSubmit={handleOnSubmit}
      />
    </LoginContainer>
  );
};

const LoginContainer = styled.div<{ devicetype: DeviceType }>`
  padding-left: 2rem;
  padding-right: 2rem;
  justify-self: center;
`;

export default UserLoginDataProvider;
