import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Form from "../../components/Form";
import {
  buttonProps,
  initialFormData,
  inputProps,
} from "../../constants/customer/CustomerRegister";
import validators from "../../constants/customer/CustomerValidators";
import { ErrorInputType } from "../../constants/ErrorInput";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import {
  Customer,
  CUSTOMER_STATE,
  CUSTOMER_TYPES,
  CustomerRegisterResponse,
  CustomerRegisterResponseType,
  CustomerType,
} from "../../models/Customer";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { validateForm } from "../../utils/Validate";

const CustomerRegisterDataProvider = () => {
  const deviceType = useDeviceType();
  const { loading, error, setError, setLoading } = useContext(AppContext);
  const [errorInput, setErrorInput] = useState<Record<string, ErrorInputType | null>>({
    email: null,
    password: null,
    name: null,
    surname: null,
    mobile_phone: null,
    address: null,
    city: null,
    province: null,
    date_of_birth: null,
    cf: null,
    pec: null,
  });
  const [formData, setFormData] = useState<CustomerType>(initialFormData);
  const { users, getUsers, loadAllData } = useApiData();
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getUsers);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const validationErrors = validateForm<CustomerType>(formData, validators);
    validationErrors && setErrorInput(validationErrors);

    setError(null);
  }, [formData, setError]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    const updatedValue = type === "checkbox" ? checked : type === "number" ? Number(value) : value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent<Element>) => {
    e.preventDefault();

    const validationErrors = validateForm<CustomerType>(formData, validators);
    if (validationErrors && Object.keys(validationErrors).length > 0) {
      setErrorInput(validationErrors);
      return;
    }

    setError(null);
    setLoading(true);

    try {
      await callApi<CustomerRegisterResponseType, CustomerType>(
        "POST",
        "/customers",
        CustomerRegisterResponse,
        Customer,
        {
          ...formData,
          customer_state: CUSTOMER_STATE.POTENTIAL,
          reference_commercial: `/api/users/${formData.reference_commercial}`,
        },
      );

      alert("Cliente creato con successo!");
      setFormData(initialFormData);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form
        inputProps={inputProps(
          users,
          formData,
          errorInput,
          error,
          deviceType === DeviceType.Mobile,
          handleOnChange,
        )}
        buttonProps={buttonProps(
          dataLoading,
          formData.customer_type === CUSTOMER_TYPES.SELECT_TYPE,
          loading,
          error ?? undefined,
        )}
        onSubmit={handleOnSubmit}
      />
    </Container>
  );
};

const Container = styled.div`
  justify-self: center;
`;

export default CustomerRegisterDataProvider;
