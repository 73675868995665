import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Column, IconType } from "../../components/Table";
import { CustomerResponseType, CustomerType } from "../../models/Customer";
import { theme } from "../../utils/Theme";

export const customerColumns: Column<CustomerType>[] = [
  { header: "Nome", accessor: "name" },
  { header: "Cognome", accessor: "surname" },
  { header: "Email", accessor: "email" },
  { header: "Tipo cliente", accessor: "customer_type" },
  { header: "Stato cliente", accessor: "customer_state" },
];

export const createCustomerColumnsIcons = (
  onShow: (id: number) => void,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
  navigate?: (customer: CustomerResponseType) => void,
): IconType<CustomerResponseType>[] => [
  {
    icon: <HistoryIcon />,
    color: theme.colors.background,
    titleTooltip: "Visualizza storico (filtra per cliente)",
    onClick: (customer) => navigate && navigate(customer),
  },
  {
    icon: <VisibilityIcon />,
    color: theme.colors.background,
    titleTooltip: "Visualizza cliente",
    onClick: (customer: CustomerResponseType) => onShow(customer.id),
  },
  {
    icon: <EditIcon />,
    color: theme.colors.background,
    titleTooltip: "Modifica cliente",
    onClick: (customer: CustomerResponseType) => onEdit(customer.id),
  },
  {
    icon: <DeleteIcon />,
    color: theme.colors.error,
    titleTooltip: "Rimuovi cliente",
    onClick: (customer: CustomerResponseType) => onDelete(customer.id),
  },
];
