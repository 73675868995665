import { Navigate, Route, Routes } from "react-router-dom";
import CustomerHistoryEditPage from "../pages/customer-histories/CustomerHistoryEditPage";
import CustomerHistoryPage from "../pages/customer-histories/CustomerHistoryPage";
import CustomerHistoryProfilePage from "../pages/customer-histories/CustomerHistoryProfilePage";
import CustomerHistoryRegisterPage from "../pages/customer-histories/CustomerHistoryRegisterPage";
import CustomerEditPage from "../pages/customer/CustomerEditPage";
import CustomerPage from "../pages/customer/CustomerPage";
import CustomerProfilePage from "../pages/customer/CustomerProfilePage";
import CustomerRegisterPage from "../pages/customer/CustomerRegisterPage";
import DemandEditPage from "../pages/demand/DemandEditPage";
import DemandPage from "../pages/demand/DemandPage";
import DemandProfilePage from "../pages/demand/DemandProfilePage";
import DemandRegisterPage from "../pages/demand/DemandRegisterPage";
import ExtInspReportEditPage from "../pages/external-report/ExtInspReportEditPage";
import ExtInspReportPage from "../pages/external-report/ExtInspReportPage";
import ExtInspReportProfilePage from "../pages/external-report/ExtInspReportProfilePage";
import ExtInspReportRegisterPage from "../pages/external-report/ExtInspReportRegisterPage";
import HomePage from "../pages/HomePage";
import UserEditPage from "../pages/user/UserEditPage";
import UserLoginPage from "../pages/user/UserLoginPage";
import UserPage from "../pages/user/UserPage";
import UserProfilePage from "../pages/user/UserProfilePage";
import UserRegisterPage from "../pages/user/UserRegisterPage";
import ExtInspReportImageUploadPage from "../pages/external-report/ExtInspReportImageUploadPage";

export const MainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<UserLoginPage />} />
      <Route path="/home/*" element={<HomePage />}>
        {/* users */}
        <Route path="users" element={<UserPage />} />
        <Route path="users/:id" element={<UserProfilePage />} />
        <Route path="users/edit/:id" element={<UserEditPage />} />
        <Route path="register" element={<UserRegisterPage />} />
        {/* customers */}
        <Route path="customer" element={<CustomerPage />} />
        <Route path="customer/:id" element={<CustomerProfilePage />} />
        <Route path="customer/edit/:id" element={<CustomerEditPage />} />
        <Route path="customer/register" element={<CustomerRegisterPage />} />
        {/* demands */}
        <Route path="demand" element={<DemandPage />} />
        <Route path="demand/:id" element={<DemandProfilePage />} />
        <Route path="demand/edit/:id" element={<DemandEditPage />} />
        <Route path="demand/register" element={<DemandRegisterPage />} />
        {/* customer history */}
        <Route path="customer_histories/" element={<CustomerHistoryPage />} />
        <Route path="customer_histories/:id" element={<CustomerHistoryProfilePage />} />
        <Route path="customer_histories/edit/:id" element={<CustomerHistoryEditPage />} />
        <Route path="customer_histories/register" element={<CustomerHistoryRegisterPage />} />
        {/* external report */}
        <Route path="ext_insp_report" element={<ExtInspReportPage />} />
        <Route path="ext_insp_report/:id" element={<ExtInspReportProfilePage />} />
        <Route path="ext_insp_report/edit/:id" element={<ExtInspReportEditPage />} />
        <Route path="ext_insp_report/edit/:id/image" element={<ExtInspReportImageUploadPage />} />
        <Route path="ext_insp_report/register" element={<ExtInspReportRegisterPage />} />
      </Route>
    </Routes>
  );
};
