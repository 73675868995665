import { Percent } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Checkbox, CheckboxProps, InputLabel, Link, LinkProps } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { theme } from "../utils/Theme";

export interface CommonInputProps {
  label: string;
  textcolor?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  name?: string;
  value?: string | number | boolean | (string | undefined)[] | null;
}

interface CustomCheckboxProps extends Omit<CheckboxProps, "value">, CommonInputProps {
  type: "checkbox";
  direction?: "row-reverse" | "column";
}

interface CustomTextFieldProps extends CommonInputProps {
  type: TextFieldProps["type"];
  name?: string;
}

interface CustomLinkProps extends CommonInputProps, LinkProps {
  type: "link";
  href: string;
}

interface CustomFileProps extends CommonInputProps {
  type: "file";
  files: (File | undefined)[];
  labelImage?: string[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile?: (index: number) => void;
}

interface CustomPercentageProps extends CommonInputProps {
  type: "percentage";
}

export type CustomInputProps =
  | CustomCheckboxProps
  | (CustomTextFieldProps & TextFieldProps)
  | CustomLinkProps
  | CustomFileProps
  | CustomPercentageProps;

const CustomInput = ({
  textcolor,
  error,
  label,
  type,
  disabled,
  required,
  ...props
}: CustomInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (type === "checkbox") {
    const { direction = "row-reverse", ...checkboxProps } = props as CustomCheckboxProps;
    return (
      <CheckboxContainer direction={direction}>
        {label && (
          <CustomLabel direction={direction} textcolor={textcolor} error={error}>
            {label} {required && <RequiredIndicator>*</RequiredIndicator>}
          </CustomLabel>
        )}
        <CustomCheckbox
          {...checkboxProps}
          required={required}
          customcolor={error ? "error" : theme.colors.secondary}
          disabled={disabled}
        />
      </CheckboxContainer>
    );
  }

  if (type === "link") {
    const { href, ...linkProps } = props as CustomLinkProps;
    return (
      <LinkContainer>
        <Link href={href} color={textcolor || theme.colors.primary} {...linkProps}>
          <CustomLabel textcolor={theme.colors.primary}>{label}</CustomLabel>
        </Link>
      </LinkContainer>
    );
  }

  if (type === "file") {
    const { files, labelImage, onChange, onRemoveFile, ...fileProps } = props as CustomFileProps;

    return (
      <FileInputContainer>
        <CustomLabel textcolor={textcolor}>
          {label} {required && <RequiredIndicator>*</RequiredIndicator>}
        </CustomLabel>
        <CustomTextField
          {...fileProps}
          type="file"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
              onChange(e);
            }
          }}
          disabled={disabled}
          required={required}
          error={error}
        />
        {files.length > 0 && (
          <ImageContainer>
            {files.map((file, index) => (
              <Container key={index}>
                {labelImage && labelImage[index] && (
                  <CustomLabel textcolor={theme.colors.secondary}>{labelImage[index]}</CustomLabel>
                )}
                <ImageItem>
                  <ImageLink href={file?.name} target="_blank">
                    <ImagePreview src={file?.name} alt={`Image ${index + 1}`} />
                  </ImageLink>
                  <RemoveButton
                    onClick={(e) => {
                      e.preventDefault();
                      onRemoveFile!(index);
                    }}
                  >
                    X
                  </RemoveButton>
                </ImageItem>
              </Container>
            ))}
          </ImageContainer>
        )}
      </FileInputContainer>
    );
  }

  return (
    <CustomTextField
      {...(props as CommonInputProps)}
      name={props.name}
      disabled={disabled}
      required={required}
      label={label}
      error={error}
      type={
        type === "password"
          ? showPassword
            ? "text"
            : "password"
          : type === "percentage"
          ? "number"
          : type
      }
      InputLabelProps={{
        shrink: true,
        style: {
          fontFamily: theme.fonts.primary,
          fontWeight: theme.fontWeights.semibold,
          fontSize: theme.fontSizes.large,
          color: disabled ? "InactiveCaptionText" : error ? theme.colors.error : textcolor,
          paddingRight: "6px",
          background: "#ffff",
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: error ? theme.colors.error : theme.colors.secondary,
          },
        },
      }}
      InputProps={{
        endAdornment:
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : type === "percentage" ? (
            <InputAdornment position="end">
              <Percent />
            </InputAdornment>
          ) : null,
      }}
    />
  );
};

const CustomTextField = styled(TextField)`
  width: 100%;
`;

// checkbox style
const CheckboxContainer = styled.div<{
  direction?: "column" | "row-reverse";
}>`
  display: flex;
  align-items: center;
  width: max-content;
  flex-direction: ${({ direction }) => (direction === "column" ? "column" : "row-reverse")};
`;

const RequiredIndicator = styled.span`
  color: ${theme.colors.secondary};
  font-size: ${theme.fontSizes.large};
  margin-left: 0.2rem;
  font-weight: ${theme.fontWeights.semibold};
  font-family: ${theme.fonts.primary};
`;

const CustomCheckbox = styled(Checkbox)<{ customcolor?: string }>`
  && {
    margin-right: auto;
    width: 1rem;
    height: 1rem;

    &.Mui-checked {
      color: ${({ customcolor }) => customcolor};
    }
  }
`;

export const CustomLabel = styled(InputLabel)<{
  direction?: "column" | "row-reverse";
  textcolor?: string;
}>`
  && {
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fontSizes.medium};
    font-weight: ${theme.fontWeights.semibold};
    margin-left: ${({ direction }) => direction === "row-reverse" && "1rem"};
    color: ${({ textcolor }) => textcolor};
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  row-gap: 3rem;
  margin-top: 10px;
  text-align: center;
  justify-content: center;
`;

const Container = styled.div`
  margin-bottom: auto;
`;

const ImageLink = styled.a`
  display: block;
  border-radius: 4px;
  text-align: center;
  color: ${theme.colors.primary};
  text-decoration: none;
  font-size: 12px;
`;

const ImageItem = styled.div`
  position: relative;
  display: inline-block;
`;

const ImagePreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const RemoveButton = styled.button`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CustomInput;
