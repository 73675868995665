import { FiltersType } from "../../components/Filter";
import { CustomerHistoryResponseType } from "../../models/CustomerHistory";

export const customerHistoryFilters: FiltersType<CustomerHistoryResponseType> = [
  {
    label: "Cliente",
    filterFn: (customerHistory: CustomerHistoryResponseType, filterValue: string | null) => {
      return customerHistory.customer_id && filterValue
        ? customerHistory.customer_id.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Richiesta",
    filterFn: (customerHistory: CustomerHistoryResponseType, filterValue: string | null) => {
      return customerHistory.demand_id && filterValue
        ? customerHistory.demand_id.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
  {
    label: "Tipologia",
    filterFn: (customerHistory: CustomerHistoryResponseType, filterValue: string | null) => {
      return customerHistory.type && filterValue
        ? customerHistory.type.toLowerCase().includes(filterValue.toLowerCase())
        : false;
    },
  },
];
