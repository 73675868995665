import { InputGroup } from "../../components/Form";
import { CustomerCollectionResponseType } from "../../models/Customer";
import { DemandType } from "../../models/Demand";
import { UserResponseCollectionType } from "../../models/User";
import { formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";

const mapUrgencyToString = (urgency: number | undefined): string => {
  switch (urgency) {
    case 1:
      return "Bassa";
    case 2:
      return "Media";
    case 3:
      return "Alta";
    default:
      return "";
  }
};

export const inputProps = (
  customers: CustomerCollectionResponseType | null,
  users: UserResponseCollectionType | null,
  demand: DemandType,
  resize: boolean,
): InputGroup[] => {
  const customer = customers
    ? customers["hydra:member"].find((cust) => String(cust.id) === demand.customer_id)
    : null;
  const customerFullName = customer ? `${customer.name ?? ""} ${customer.surname ?? ""}` : "";

  const user = users
    ? users["hydra:member"].find((usr) => String(usr.id) === demand.commercial_user_id)
    : null;
  const userFullName = user ? `${user.name ?? ""} ${user.surname ?? ""}` : "";

  return [
    {
      fields: [
        {
          value: customerFullName || "",
          label: "ID Cliente",
          name: "customer_id",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: userFullName || "",
          label: "Commerciale di riferimento",
          name: "commercial_user_id",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.address || "",
          label: "Indirizzo",
          name: "address",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.number_estate_units || "",
          label: "Numero Unità Immobiliari",
          name: "number_estate_units",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.number_floors || "",
          label: "Numero Piani",
          name: "number_floors",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.degree_complexity || "",
          label: "Grado di Complessità",
          name: "degree_complexity",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.reference_area || "",
          label: "Area di Riferimento",
          name: "reference_area",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.demand_date ? formatDate(demand.demand_date) : "",
          label: "Data della Richiesta",
          name: "demand_date",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.contact_person || "",
          label: "Persona di Contatto",
          name: "contact_person",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.city || "",
          label: "Città",
          name: "city",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.province || "",
          label: "Provincia",
          name: "province",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: mapUrgencyToString(demand.urgency),
          label: "Urgenza",
          name: "urgency",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.inspection_date ? formatDate(demand.inspection_date) : "",
          label: "Data di Ispezione",
          name: "inspection_date",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.processes || "",
          label: "Lavorazioni",
          name: "processes",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.inspection_type || "",
          label: "Tipo di sopralluogo",
          name: "inspection_type",
          type: "text",
          textcolor: theme.colors.secondary,
        },
        {
          value: demand.unique_protocol || "",
          label: "Protocollo Univoco",
          name: "unique_protocol",
          type: "text",
          textcolor: theme.colors.secondary,
        },
      ],
      inputColumns: resize ? 1 : 3,
    },
  ];
};
