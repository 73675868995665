import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DynamicInputGroup } from "../../components/DynamicInput";
import Form from "../../components/Form";
import Loading from "../../components/Loading";
import { inputProps } from "../../constants/external-report/ExtInspReportProfile";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { ErrorType } from "../../models/Error";
import {
  ExtInspReportResponse,
  ExtInspReportResponseType,
  ExtInspReportType,
} from "../../models/ExtInspReport";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";

const getFields = <T extends { id: number }>(
  collection: T[] | null,
  id: string | null,
  fields: (keyof T)[],
): string => {
  if (!collection || !id) return "";
  const entity = collection.find((item) => item.id === Number(id));

  if (!entity) return "";

  return fields.map((field) => (entity[field] ? String(entity[field]) : "")).join(" ");
};

const ExtInspReportProfileDataProvider = () => {
  const { error, setError } = useContext(AppContext);
  const { users, demands, getUsers, getDemands, loadAllData } = useApiData();
  const deviceType = useDeviceType();
  const { id } = useParams();

  const [extInspReport, setExtInspReport] = useState<ExtInspReportType | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [dynamicInputGroups, setDynamicInputGroups] = useState<{
    [key: string]: DynamicInputGroup[];
  }>({});

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getUsers);
        await loadAllData(getDemands);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (id) {
      getExtInspReport(id);
    }
  }, [id]);

  const getExtInspReport = async (id: string) => {
    try {
      const response = await callApi<ExtInspReportResponseType, undefined>(
        "GET",
        `/ext_insp_reports/${id}`,
        ExtInspReportResponse,
        undefined,
      );

      if (response) {
        const formattedData = {
          ...response,
          commercial_user_id: response.commercial_user_id?.replace("/api/users/", ""),
          demand_id: response.demand_id?.replace("/api/demands/", ""),
        };

        setExtInspReport(formattedData);
        initializeDynamicInputGroups(formattedData);
      }
    } catch (e) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    }
  };

  const initializeDynamicInputGroups = (formData: ExtInspReportType) => {
    const balconySubGroups = inputProps(formData, "", "", deviceType === DeviceType.Mobile)
      .find((section) => section.section === "Balconi privati")
      ?.groups?.find((group) => group.groupTitle === "balconi")?.subGroups;

    if (balconySubGroups) {
      setDynamicInputGroups({
        balconi: balconySubGroups.map(
          ({ fields = [], title, inputColumns, rowGap, columnsGap }) => ({
            fields,
            title,
            inputColumns,
            rowGap,
            columnsGap,
          }),
        ),
      });
    }
  };

  const userFullName = getFields(
    users ? users["hydra:member"] : null,
    extInspReport?.commercial_user_id ?? null,
    ["name", "surname"],
  );

  const demandUniqueProtocol = getFields(
    demands ? demands["hydra:member"] : null,
    extInspReport?.demand_id ?? null,
    ["unique_protocol"],
  );

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        extInspReport && (
          <Form
            title={deviceType !== DeviceType.Mobile ? "" : "Profilo verbale sopralluogo esterno"}
            inputProps={inputProps(
              extInspReport,
              userFullName,
              demandUniqueProtocol,
              deviceType === DeviceType.Mobile,
            )}
            dynamicInputProps={{
              dynamicInputGroups,
              mode: "view",
            }}
            onSubmit={() => {}}
          />
        )
      )}
    </>
  );
};

export default ExtInspReportProfileDataProvider;
