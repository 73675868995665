import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { theme } from "../../utils/Theme";
import { UserType } from "../../models/User";
import { CircularProgress } from "@mui/material";
import { ErrorType } from "../../models/Error";

export const inputProps = (
  user: UserType,
  error: ErrorType | null,
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
): InputGroup[] => [
  {
    fields: [
      {
        value: user.email,
        label: "Email",
        name: "email",
        type: "email",
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user.password,
        label: "Password",
        name: "password",
        type: "password",
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        onChange: handleOnChange,
      },
    ],
    rowGap: 2,
  },
  {
    fields: [
      {
        value: user.rememberMe,
        checked: user.rememberMe,
        label: "Ricordati di me!",
        name: "rememberMe",
        type: "checkbox",
        textcolor: theme.colors.primary,
        onChange: handleOnChange,
      },
      {
        type: "link",
        label: "Ha dimenticato la password?",
        href: "forgot-password",
      },
    ],
    inputColumns: 2,
    paddingTop: 2,
  },
];

export const buttonProps = (
  loading: boolean,
  error: ErrorType | null,
  user: UserType,
): ButtonGroup[] => [
  {
    childrenButton: error && (
      <ErrorText style={{ color: theme.colors.error }}>{error.message}</ErrorText>
    ),
    fields: [
      {
        disabled: (user.email && user.password) === "" || !!error,
        label: loading ? <CircularProgress size={24} /> : "Sign in",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 0.5rem;
`;
