import styled from "styled-components";

interface PngImageProps {
  src: string;
  width?: number | string;
  height?: number | string;
}

const PngImage = ({ src, height, width }: PngImageProps) => {
  return <Image src={require(`../assets/${src}`)} width={width} height={height} alt="png" />;
};

const Image = styled.img<{ width?: number | string; height?: number | string }>`
  width: ${({ width }) => `${width}%` || "auto"};
  height: ${({ height }) => `${height}%` || "auto"};
  object-fit: contain;
`;

export default PngImage;
