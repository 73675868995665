import { BrowserRouter as Router } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import { MainRouter } from "./routes/MainRouter";

function App() {
  return (
    <AppProvider>
      <Router basename="/">
        <MainRouter />
      </Router>
    </AppProvider>
  );
}

export default App;
