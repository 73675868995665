import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { UserEditType } from "../../models/User";
import { ErrorInputType } from "../../constants/ErrorInput";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { validateForm } from "../../utils/Validate";
import { buttonProps, inputProps } from "../../constants/user/UserEdit";
import Form from "../../components/Form";
import validators from "../../constants/user/UserEditValidators";

const UserEditDataProvider = () => {
  const { currentUser, error, loading, setError, setLoading, updateUser } = useContext(AppContext);
  const [editData, setEditData] = useState<UserEditType | null>(currentUser);
  const [errorInput, setErrorInput] = useState<Record<string, ErrorInputType | null>>({
    email: null,
    name: null,
    surname: null,
    mobile_phone: null,
    address: null,
    city: null,
    province: null,
    cf: null,
  });
  const deviceType = useDeviceType();

  useEffect(() => {
    setError(null);
  }, [editData, setError]);

  useEffect(() => {
    if (editData) {
      const validationErrors = validateForm<UserEditType>(editData, validators);
      setErrorInput(validationErrors);
      setError(null);
    }
  }, [editData, setError]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditData((prevState) =>
      prevState ? { ...prevState, [name]: name === "roles" ? [value] : value } : null,
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (editData) {
      const validationErrors = validateForm<UserEditType>(editData, validators);
      if (validationErrors && Object.keys(validationErrors).length > 0) {
        setErrorInput(validationErrors);
        return;
      }

      setError(null);
      setLoading(true);

      try {
        await updateUser(editData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Form
      title="Modifica utente"
      onSubmit={handleSubmit}
      inputProps={inputProps(
        editData,
        errorInput,
        error,
        deviceType === DeviceType.Mobile,
        handleOnChange,
      )}
      buttonProps={buttonProps(loading, error)}
    />
  );
};

export default UserEditDataProvider;
