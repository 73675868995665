import * as io from "io-ts";

export const ROOF_TYPE = {
  PIANA: "Piana",
  FALDA: "Falda",
};

export const FACADE_TYPE = {
  FACCIATA: "Facciata",
  CIELINI: "Cielini",
  FRONTALINI: "Frontalini",
  SPORTO_GRONDAIA: "Sporto di grondaia",
};

export const GUTTERS_TYPE = {
  PULIZIA: "Pulizia",
  SOSTITUZIONE: "Sostituzione",
};

export const roofTypeOptions = [
  { value: ROOF_TYPE.PIANA, label: "Piana" },
  { value: ROOF_TYPE.FALDA, label: "Falda" },
];

export const BALCONIES_VALUE = {
  ALTEZZA: "altezza",
  PROFONDITA: "profondità",
  LARGHEZZA: "larghezza",
};

export const IMAGE_TYPE_VALUE = {
  COVERAGE: "coverage",
  TINSMITHERY: "tinsmithery",
  OTHER: "other",
  SELECT_TYPE: "Seleziona la tipologia",
};

export const facadeTypeOptions = [
  { value: FACADE_TYPE.FACCIATA, label: "Facciata" },
  { value: FACADE_TYPE.CIELINI, label: "Cielini" },
  { value: FACADE_TYPE.FRONTALINI, label: "Frontalini" },
  { value: FACADE_TYPE.SPORTO_GRONDAIA, label: "Sporto di grondaia" },
];

export const guttersTypeOptions = [
  { value: GUTTERS_TYPE.PULIZIA, label: "Pulizia" },
  { value: GUTTERS_TYPE.SOSTITUZIONE, label: "Sostituzione" },
];

export const downspoutsTypeOptions = [{ value: GUTTERS_TYPE.SOSTITUZIONE, label: "Sostituzione" }];

export const balconiesValueOptions = [
  { value: BALCONIES_VALUE.ALTEZZA, label: "Altezza" },
  { value: BALCONIES_VALUE.PROFONDITA, label: "Profondità" },
  { value: BALCONIES_VALUE.LARGHEZZA, label: "Larghezza" },
];

export const ImageTypeOptions = [
  { value: IMAGE_TYPE_VALUE.COVERAGE, label: "Copertura" },
  { value: IMAGE_TYPE_VALUE.TINSMITHERY, label: "Lattoneria" },
  { value: IMAGE_TYPE_VALUE.OTHER, label: "Altro" },
  {
    value: IMAGE_TYPE_VALUE.SELECT_TYPE,
    label: "Seleziona la tipologia",
  },
];

export const SideType = io.type({
  mt: io.union([io.string, io.undefined]),
  ground: io.union([io.string, io.undefined]),
  border: io.union([io.string, io.undefined]),
  h_interplane: io.union([io.string, io.undefined]),
});

export const BalconyType = io.type({
  pavimenti: io.array(io.array(io.union([io.string, io.undefined]))),
  ringhiere: io.array(io.array(io.union([io.string, io.undefined]))),
  parapetto_muratura: io.array(io.array(io.union([io.string, io.undefined]))),
  frontalini: io.array(io.array(io.union([io.string, io.undefined]))),
});

export const File = io.type({
  id: io.union([io.number, io.undefined]),
  name: io.union([io.string, io.undefined]),
  path: io.union([io.string, io.undefined]),
  type: io.union([io.string, io.undefined]),
  id_ext_insp_report: io.union([io.number, io.undefined]),
});

export const ImageUpload = io.type({
  name: io.union([io.string, io.undefined]),
  "files[]": io.array(io.union([io.string, io.undefined])),
  type: io.union([io.string, io.undefined]),
});

export const ExtInspReport = io.type({
  commercial_user_id: io.union([io.string, io.undefined]),
  demand_id: io.union([io.string, io.undefined]),
  date: io.union([io.string, io.undefined]),
  address: io.union([io.string, io.undefined]),
  work_contact: io.union([io.string, io.undefined]),
  phone_work_contact: io.union([io.string, io.undefined]),
  email_work_contact: io.union([io.string, io.undefined]),
  access_contact: io.union([io.string, io.undefined]),
  phone_access_contact: io.union([io.string, io.undefined]),
  email_access_contact: io.union([io.string, io.undefined]),
  other: io.union([io.string, io.undefined]),
  // info
  roof_type: io.array(io.union([io.string, io.undefined])),
  number_pitches: io.union([io.number, io.undefined]),
  elevator: io.union([io.boolean, io.undefined]),
  attic: io.union([io.boolean, io.undefined]),
  floors_number: io.union([io.number, io.undefined]),
  life_line: io.union([io.boolean, io.undefined]),
  notes: io.union([io.string, io.undefined]),
  water_point: io.union([io.boolean, io.undefined]),
  water_point_place: io.union([io.string, io.undefined]),
  light_point: io.union([io.boolean, io.undefined]),
  light_point_place: io.union([io.string, io.undefined]),
  tech_stock_room: io.union([io.boolean, io.undefined]),
  tech_stock_room_place: io.union([io.string, io.undefined]),
  number_estate_units: io.union([io.number, io.undefined]),
  condos_roof_access: io.union([io.boolean, io.undefined]),
  private_cover_access: io.union([io.boolean, io.undefined]),
  condo_name: io.union([io.string, io.undefined]),
  condo_surname: io.union([io.string, io.undefined]),
  phone: io.union([io.string, io.undefined]),
  email: io.union([io.string, io.undefined]),
  // facciate
  securing: io.array(io.union([io.string, io.undefined])),
  restoration: io.array(io.union([io.string, io.undefined])),
  shave: io.array(io.union([io.string, io.undefined])),
  painting: io.array(io.union([io.string, io.undefined])),
  water_repellent_treatment: io.array(io.string),
  window_wash: io.union([io.boolean, io.undefined]),
  facade_wash: io.union([io.boolean, io.undefined]),
  // balconi
  adjustment: io.union([io.boolean, io.undefined]),
  waterproofing: io.union([io.boolean, io.undefined]),
  flooring: io.union([io.boolean, io.undefined]),
  rehabilitation_railings: io.union([io.boolean, io.undefined]),
  balconies_notes: io.union([io.string, io.undefined]),
  // copertura
  antivolatile: io.union([io.boolean, io.undefined]),
  insulated: io.union([io.boolean, io.undefined]),
  new_roof: io.union([io.boolean, io.undefined]),
  structure: io.union([io.boolean, io.undefined]),
  lifeline_project: io.union([io.boolean, io.undefined]),
  maintenance: io.union([io.boolean, io.undefined]),
  overhaul_coverage: io.union([io.boolean, io.undefined]),
  overhaul_coverage_percentage: io.union([io.number, io.undefined]),
  sheath: io.union([io.boolean, io.undefined]),
  tiles: io.union([io.boolean, io.undefined]),
  obligatory_booklet: io.union([io.string, io.undefined]),
  // lattoneria
  gutters: io.array(io.union([io.string, io.undefined])),
  gutters_mt: io.union([io.number, io.undefined]),
  downspouts: io.array(io.union([io.string, io.undefined])),
  downspouts_mt: io.union([io.number, io.undefined]),
  tinsmithery_notes: io.union([io.string, io.undefined]),
  // facciata
  sides: io.array(SideType),
  facade_notes: io.union([io.string, io.undefined]),
  // balconi privati
  balconies: io.union([io.record(io.string, BalconyType), io.null, io.undefined]),
  ferrous_works: io.union([io.string, io.undefined]),
  common_parts: io.union([io.string, io.undefined]),
  private_balconies_notes: io.union([io.string, io.undefined]),
});

export const ExtInspReportResponse = io.type({
  id: io.number,
  ...ExtInspReport.props,
});

export const ExtInspReportRegisterResponse = io.type({
  "@context": io.string,
  "@id": io.string,
  "@type": io.string,
  id: io.number,
  ...ExtInspReport.props,
});

export const ExtInspReportCollectionResponse = io.type({
  "hydra:totalItems": io.number,
  "hydra:member": io.array(ExtInspReportResponse),
  "hydra:view": io.union([
    io.type({
      "@id": io.string,
      "@type": io.string,
      "hydra:first": io.string,
      "hydra:last": io.string,
      "hydra:next": io.union([io.string, io.undefined]),
    }),
    io.undefined,
  ]),
});

export const FileUploadResponse = io.type({
  status: io.string,
  files: io.array(File),
});

export const DeleteResponse = io.type({
  status: io.string,
});

export const ExtInspReportImageCollectionResponse = io.type({
  "hydra:totalItems": io.number,
  "hydra:member": io.array(File),
  "hydra:view": io.union([
    io.type({
      "@id": io.string,
      "@type": io.string,
      "hydra:first": io.string,
      "hydra:last": io.string,
      "hydra:next": io.union([io.string, io.undefined]),
    }),
    io.undefined,
  ]),
});

export type ExtInspReportType = io.TypeOf<typeof ExtInspReport>;
export type ExtInspReportResponseType = io.TypeOf<typeof ExtInspReportResponse>;
export type ExtInspReportRegisterResponseType = io.TypeOf<typeof ExtInspReportRegisterResponse>;
export type ExtInspReportCollectionResponseType = io.TypeOf<typeof ExtInspReportCollectionResponse>;
export type SidesType = io.TypeOf<typeof SideType>;
export type BalconiesType = io.TypeOf<typeof BalconyType>;
export type DeleteResponseType = io.TypeOf<typeof DeleteResponse>;
export type ImageUploadType = io.TypeOf<typeof ImageUpload>;
export type FileType = io.TypeOf<typeof File>;
export type FileUploadResponseType = io.TypeOf<typeof FileUploadResponse>;
export type ExtInspReportImageCollectionResponseType = io.TypeOf<
  typeof ExtInspReportImageCollectionResponse
>;
