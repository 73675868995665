import { Pagination } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FilterBar from "../../components/Filter";
import Loading from "../../components/Loading";
import GenericTable from "../../components/Table";
import { createUserColumnsIcons, userColumns } from "../../constants/user/UserTable";
import { userFilters } from "../../constants/UserFiltersItem";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { ErrorType } from "../../models/Error";
import { UserResponseType } from "../../models/User";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { theme } from "../../utils/Theme";

const UserDataProvider = () => {
  const {
    users,
    loading,
    error,
    currentPage,
    totalPages,
    getUsers,
    setCurrentUser,
    setError,
    setLoading,
  } = useContext(AppContext);
  const deviceType = useDeviceType();
  const navigate = useNavigate();
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<UserResponseType[]>(
    users?.["hydra:member"] || [],
  );

  useEffect(() => {
    getUsers(`/users?page=${currentPage}`, currentPage);
  }, [getUsers, currentPage, isDeleted]);

  const handlePageChange = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => {
      getUsers(`/users?page=${page}`, page);
    },
    [getUsers],
  );

  const handleShowUser = useCallback(
    (id: number) => {
      const user = users?.["hydra:member"].find((u: UserResponseType) => u.id === id);
      if (user) {
        setCurrentUser(user);
        navigate(`/home/users/${id}`);
      }
    },
    [navigate, users, setCurrentUser],
  );

  const handleEditUser = useCallback(
    (id: number) => {
      const user = users?.["hydra:member"].find((u: UserResponseType) => u.id === id);
      if (user) {
        setCurrentUser(user);
        navigate(`/home/users/edit/${id}`);
      }
    },
    [navigate, users, setCurrentUser],
  );

  const handleDeleteUser = useCallback(async (id: number) => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo utente?");
    if (!confirmDelete) return;

    setLoading(true);
    try {
      await callApi("DELETE", `/users/${id}`);
      setIsDeleted((prev) => !prev);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer>
          <FilterBar<UserResponseType>
            data={users?.["hydra:member"] || []}
            filters={userFilters}
            columns={deviceType === DeviceType.Mobile ? 2 : 4}
            onFilter={setFilteredUsers}
          />
          {filteredUsers.length > 0 ? (
            <GenericTable<UserResponseType>
              data={filteredUsers}
              columns={userColumns}
              icons={createUserColumnsIcons(handleShowUser, handleEditUser, handleDeleteUser)}
              loading={loading}
              error={error?.["hydra:description"] || null}
            />
          ) : (
            <NoFoundItems>nessun risultato di ricerca</NoFoundItems>
          )}
          <PaginationContainer $ismobile={deviceType !== DeviceType.Desktop}>
            <StyledPagination
              size="medium"
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </PaginationContainer>
        </TableContainer>
      )}
    </Container>
  );
};

export default UserDataProvider;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const TableContainer = styled.div`
  margin-top: 1rem;
`;

const NoFoundItems = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primary};
`;

const PaginationContainer = styled.div<{ $ismobile: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const StyledPagination = styled(Pagination)`
  && {
    .MuiPaginationItem-root {
    }
    .MuiPaginationItem-page.Mui-selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${theme.colors.primary};
      }
    }
  }
`;
