import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Form from "../../components/Form";
import { ErrorInputType } from "../../constants/ErrorInput";
import { buttonProps, initialFormData, inputProps } from "../../constants/demand/DemandRegister";
import validators from "../../constants/demand/DemandValidators";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import {
  Demand,
  DemandRegisterResponse,
  DemandRegisterResponseType,
  DemandType,
} from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { validateForm } from "../../utils/Validate";

const DemandRegisterDataProvider = () => {
  const deviceType = useDeviceType();
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const { customers, users, getCustomers, getUsers, loadAllData } = useApiData();
  const [formData, setFormData] = useState<DemandType>(initialFormData);
  const [errorInput, setErrorInput] = useState<Record<string, ErrorInputType | null>>({
    province: null,
  });
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getCustomers);
        await loadAllData(getUsers);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const validationErrors = validateForm<DemandType>(formData, validators);
    setErrorInput(validationErrors);
  }, [formData]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "number" ? Number(value) : value,
    }));
  };

  const handleOnSubmit = async (e: React.FormEvent<Element>) => {
    e.preventDefault();

    const validationErrors = validateForm<DemandType>(formData, validators);
    if (validationErrors && Object.keys(validationErrors).length > 0) {
      setErrorInput(validationErrors);
      return;
    }

    setError(null);
    setLoading(true);

    try {
      await callApi<DemandRegisterResponseType, DemandType>(
        "POST",
        "/demands",
        DemandRegisterResponse,
        Demand,
        {
          ...formData,
          customer_id: `/api/customers/${formData.customer_id}`,
          commercial_user_id: `/api/users/${formData.commercial_user_id}`,
        },
      );

      alert("Richiesta creata con successo!");
      setFormData(initialFormData);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form
        inputProps={inputProps(
          formData,
          customers,
          users,
          error,
          errorInput,
          deviceType === DeviceType.Mobile,
          handleOnChange,
        )}
        buttonProps={buttonProps(dataLoading, loading, error ?? undefined)}
        onSubmit={handleOnSubmit}
      />
    </Container>
  );
};

const Container = styled.div`
  justify-self: center;
`;

export default DemandRegisterDataProvider;
