import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { CustomerCollectionResponseType } from "../../models/Customer";
import {
  DEGREE_COMPLEXITY,
  degreeComplexityOptions,
  DemandType,
  inspectionOptions,
  URGENCY_LEVELS,
  urgencyOptions,
} from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import { UserResponseCollectionType } from "../../models/User";
import { currentDate, formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";
import { ERROR_INPUT } from "../ErrorInput";
import { CircularProgress } from "@mui/material";

export const initialFormData: DemandType = {
  // uuid: undefined,
  customer_id: undefined,
  address: undefined,
  number_estate_units: 1,
  number_floors: 1,
  degree_complexity: DEGREE_COMPLEXITY.EASY,
  reference_area: undefined,
  unique_protocol: undefined,
  demand_date: undefined,
  contact_person: undefined,
  city: undefined,
  province: undefined,
  urgency: URGENCY_LEVELS.LOW,
  inspection_date: undefined,
  processes: undefined,
  commercial_user_id: undefined,
  inspection_type: undefined,
  demand_state: "",
};

export const inputProps = (
  demand: DemandType,
  customers: CustomerCollectionResponseType | null,
  users: UserResponseCollectionType | null,
  error: ErrorType | null,
  errorHelperText: Record<string, string | null>,
  resize: boolean,
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
): InputGroup[] => {
  return [
    {
      fields: [
        {
          value: demand.customer_id || "",
          label: "Cliente",
          name: "customer_id",
          type: "select",
          options: customers
            ? customers["hydra:member"].map((customer) => ({
                value: customer.id,
                label: `${customer.name ?? ""} ${customer.surname ?? ""}`,
              }))
            : [],
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.commercial_user_id || "",
          label: "Commerciale di riferimento",
          name: "commercial_user_id",
          type: "select",
          options: users
            ? users["hydra:member"].map((user) => ({
                value: user.id,
                label: `${user.name ?? ""} ${user.surname ?? ""}`,
              }))
            : [],
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.address || "",
          label: "Indirizzo",
          name: "address",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.number_estate_units || "",
          label: "Numero Unità Immobiliari",
          name: "number_estate_units",
          type: "number",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.number_floors || "",
          label: "Numero Piani",
          name: "number_floors",
          type: "number",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.degree_complexity || "",
          label: "Grado di Complessità",
          name: "degree_complexity",
          type: "select",
          options: degreeComplexityOptions,
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.reference_area || "",
          label: "Area di Riferimento",
          name: "reference_area",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.demand_date ? formatDate(demand.demand_date) : "",
          label: "Data della Richiesta",
          name: "demand_date",
          type: "date",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          inputProps: { max: currentDate() },
          onChange: handleOnChange,
        },
        {
          value: demand.contact_person || "",
          label: "Persona di Contatto",
          name: "contact_person",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.city || "",
          label: "Città",
          name: "city",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.province || "",
          label: "Provincia",
          name: "province",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: (demand.province && !!errorHelperText.province) || !!error,
          helperText:
            demand.province && errorHelperText.province === "invalid"
              ? ERROR_INPUT.province.invalid
              : undefined,
          inputProps: { maxLength: 3, style: { textTransform: "uppercase" } },
          onChange: handleOnChange,
        },
        {
          value: demand.urgency || "",
          label: "Urgenza",
          name: "urgency",
          type: "select",
          options: urgencyOptions,
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.inspection_date ? formatDate(demand.inspection_date) : "",
          label: "Data di Ispezione",
          name: "inspection_date",
          type: "date",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          inputProps: { max: currentDate() },
          onChange: handleOnChange,
        },
        {
          value: demand.processes || "",
          label: "Lavorazioni",
          name: "processes",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.inspection_type || "",
          label: "Tipo di sopralluogo",
          name: "inspection_type",
          type: "select",
          options: inspectionOptions,
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          onChange: handleOnChange,
        },
        {
          value: demand.unique_protocol || "",
          label: "Protocollo Univoco",
          name: "unique_protocol",
          type: "text",
          textcolor: theme.colors.secondary,
          required: true,
          error: (demand.unique_protocol && !!errorHelperText.unique_protocol) || !!error,
          helperText:
            demand.unique_protocol && errorHelperText.unique_protocol === "invalid"
              ? ERROR_INPUT.unique_protocol.invalid
              : undefined,
          onChange: handleOnChange,
        },
      ],
      inputColumns: resize ? 1 : 3,
    },
  ];
};

export const buttonProps = (
  dataLoading: boolean,
  loading: boolean,
  error: ErrorType | undefined,
): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{error.detail}</ErrorText> : null,
    fields: [
      {
        disabled: dataLoading || loading || !!error,
        label: dataLoading ? <CircularProgress size={24} /> : "Registra richiesta",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 0.5rem;
`;
