import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "../../components/Form";
import { buttonProps, inputProps } from "../../constants/customer-histories/CustomerHistoryEdit";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import {
  CustomerHistory,
  CustomerHistoryResponse,
  CustomerHistoryResponseType,
  CustomerHistoryType,
} from "../../models/CustomerHistory";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import Loading from "../../components/Loading";

const CustomerHistoryEditDataProvider = () => {
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const deviceType = useDeviceType();
  const { id } = useParams<string>();

  const { customers, users, demands, getCustomers, getUsers, getDemands, loadAllData } =
    useApiData();

  const [editData, setEditData] = useState<CustomerHistoryType | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    setError(null);
  }, [editData]);

  useEffect(() => {
    getCustomerHistory();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getCustomers);
        await loadAllData(getUsers);
        await loadAllData(getDemands);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setEditData((prevState) =>
      prevState ? { ...prevState, [name]: type === "number" ? Number(value) : value } : null,
    );
  };

  const getCustomerHistory = async () => {
    setLoading(true);

    try {
      const response = await callApi<CustomerHistoryResponseType, undefined>(
        "GET",
        `/customer_histories/${id}`,
        CustomerHistoryResponse,
        undefined,
      );

      if (response) {
        const formattedData = {
          ...response,
          customer_id: response.customer_id?.replace("/api/customers/", ""),
          user_id: response.user_id?.replace("/api/users/", ""),
          demand_id: response.demand_id?.replace("/api/demands/", ""),
        };

        setEditData(formattedData);
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  const updateCustomerHistory = useCallback(async (history: CustomerHistoryType) => {
    setError(null);
    setLoading(true);

    const body: CustomerHistoryType = {
      customer_id: history.customer_id,
      date: history.date,
      type: history.type,
      result: history.result,
      demand_id: history.demand_id,
      user_id: history.user_id,
    };

    try {
      const response = await callApi<CustomerHistoryResponseType, CustomerHistoryType>(
        "PUT",
        `/customer_histories/${id}`,
        CustomerHistoryResponse,
        CustomerHistory,
        {
          ...body,
          customer_id: `/api/customers/${body.customer_id}`,
          user_id: `/api/users/${body.user_id}`,
          demand_id: `/api/demands/${body.demand_id}`,
        },
      );

      if (response) {
        const formattedData = {
          ...response,
          customer_id: response.customer_id?.replace("/api/customers/", ""),
          user_id: response.user_id?.replace("/api/users/", ""),
          demand_id: response.demand_id?.replace("/api/demands/", ""),
        };

        setEditData(formattedData);
        alert("Storico cliente aggiornato con successo!");
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (editData) {
      await updateCustomerHistory(editData);
    }
  };

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        editData && (
          <Form
            title="Modifica Storico Cliente"
            onSubmit={handleSubmit}
            inputProps={inputProps(
              editData,
              customers,
              users,
              demands,
              error,
              deviceType === DeviceType.Mobile,
              handleOnChange,
            )}
            buttonProps={buttonProps(loading, error)}
          />
        )
      )}
    </>
  );
};

export default CustomerHistoryEditDataProvider;
