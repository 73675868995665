import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Navbar } from "../components/Navbar";
import PngImage from "../components/PngImage";
import { ItemType, Sidebar } from "../components/Sidebar";
import { AppContext } from "../context/AppContext";
import useDeviceType, { DeviceType } from "../hooks/useDeviceType";
import { getSidebarItems } from "../constants/SidebarItem";
import { ROLES } from "../models/User";

const HomePage = () => {
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const { token, userDecodeToken } = useContext(AppContext);
  const [selectedItemText, setSelectedItemText] = useState<string | null>(
    localStorage.getItem("sidebarItemText"),
  );

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const handleSelectItem = (item: ItemType) => {
    if (item.text && item.path) {
      setSelectedItemText(item.text);
      localStorage.setItem("sidebarItemPath", item.path);
      localStorage.setItem("sidebarItemText", item.text);
      navigate(item.path);
    }
  };

  return (
    <Container>
      <Sidebar
        open={deviceType === DeviceType.Desktop ? true : false}
        items={getSidebarItems(!!userDecodeToken?.roles.includes(ROLES.ADMIN))}
        selectedItem={selectedItemText}
        onSelectItem={handleSelectItem}
      />
      <StyledBox open={deviceType === DeviceType.Desktop} component="main">
        {deviceType !== DeviceType.Mobile && selectedItemText && (
          <Navbar
            icon={<PngImage src="logo/icaro_world_logo.png" width={10} />}
            title=""
            selectedItem={selectedItemText}
          />
        )}
        <Content>
          <Outlet />
        </Content>
      </StyledBox>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const StyledBox = styled(Box)<{ open: boolean }>`
  flex-grow: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: ${({ open }) => (open ? "16rem" : "4.75rem")};
  overflow: scroll;
`;

const Content = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100vh;
`;

export default HomePage;
