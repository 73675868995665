import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import {
  CUSTOMER_STATE,
  CUSTOMER_TYPES,
  CustomerType,
  customerTypeOptions,
} from "../../models/Customer";
import { ErrorType } from "../../models/Error";
import { UserResponseCollectionType } from "../../models/User";
import { currentDate, formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";
import { ERROR_INPUT, ErrorInputType } from "../ErrorInput";

export const initialFormData: CustomerType = {
  name: undefined,
  surname: undefined,
  prefix_name_admin: undefined,
  business_name: undefined,
  address: undefined,
  city: undefined,
  province: undefined,
  cf: undefined,
  vat_number: undefined,
  email: undefined,
  sdi: undefined,
  mobile_phone: undefined,
  landline_phone: undefined,
  website: undefined,
  sector: undefined,
  pec: undefined,
  date_of_birth: undefined,
  birth_place: undefined,
  studio_name: undefined,
  condo_name: undefined,
  number_estate_units: 0,
  number_floors: 0,
  reference_commercial: undefined,
  studio_classification: undefined,
  studio_size: undefined,
  condos_number: 1,
  associated_customer: undefined,
  note: undefined,
  customer_type: CUSTOMER_TYPES.SELECT_TYPE,
  customer_state: CUSTOMER_STATE.POTENTIAL,
};

export const inputProps = (
  users: UserResponseCollectionType | null,
  customer: CustomerType,
  errorHelperText: Record<string, ErrorInputType | null>,
  error: ErrorType | null,
  resize: boolean,
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
): InputGroup[] => {
  const isCondoAdmin = customer.customer_type === CUSTOMER_TYPES.CONDO_ADMIN;
  const isCondo = customer.customer_type === CUSTOMER_TYPES.CONDO;
  const isCompany = customer.customer_type === CUSTOMER_TYPES.COMPANY;
  const isPrivate = customer.customer_type === CUSTOMER_TYPES.PRIVATE;

  const userOptions = users
    ? users["hydra:member"].map((user) => ({
        value: user.id,
        label: `${user.name ?? ""} ${user.surname ?? ""}`,
      }))
    : [];

  // Common fields
  const commonFields = [
    {
      value: customer.name || "",
      label: "Nome",
      name: "name",
      type: "text",
      textcolor: theme.colors.secondary,
      required: true,
      error: !!error,
      onChange: handleOnChange,
    },
    {
      value: customer.surname || "",
      label: "Cognome",
      name: "surname",
      type: "text",
      textcolor: theme.colors.secondary,
      required: true,
      error: !!error,
      onChange: handleOnChange,
    },
    {
      value: customer.pec || "",
      label: "PEC",
      name: "pec",
      type: "text",
      textcolor: theme.colors.secondary,
      required: false,
      error: (customer.pec && !!errorHelperText.pec) || !!error,
      helperText:
        customer.pec && errorHelperText.pec === "invalid" ? ERROR_INPUT.pec.invalid : undefined,
      onChange: handleOnChange,
    },
    {
      value: customer.email || "",
      label: "Email",
      name: "email",
      type: "email",
      textcolor: theme.colors.secondary,
      required: true,
      error: (customer.email && !!errorHelperText.email) || !!error,
      helperText:
        customer.email && errorHelperText.email === "invalid"
          ? ERROR_INPUT.email.invalid
          : undefined,
      onChange: handleOnChange,
    },
    {
      value: customer.reference_commercial || "",
      label: "Riferimento Commerciale",
      name: "reference_commercial",
      type: "select",
      options: userOptions,
      textcolor: theme.colors.secondary,
      required: true,
      error: !!error,
      onChange: handleOnChange,
    },
  ];

  return [
    {
      fields: commonFields,
      inputColumns: resize ? 1 : 3,
    },
    {
      fields: [
        {
          value: customer.customer_type || "",
          label: "Tipo Cliente",
          name: "customer_type",
          type: "select",
          textcolor: theme.colors.secondary,
          required: true,
          error: !!error,
          options: customerTypeOptions,
          onChange: handleOnChange,
        },
      ],
      paddingTop: 2,
    },
    {
      fields: [
        // customer private
        isPrivate
          ? {
              value: customer.date_of_birth ? formatDate(customer.date_of_birth) : "",
              label: "Data di Nascita",
              name: "date_of_birth",
              type: "date",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              inputProps: { max: currentDate() },
              onChange: handleOnChange,
            }
          : null,
        isPrivate
          ? {
              value: customer.birth_place || "",
              label: "Luogo di Nascita",
              name: "birth_place",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        // customer condo admin
        isCondoAdmin
          ? {
              value: customer.prefix_name_admin || "",
              label: "Prefisso Nome Admin",
              name: "prefix_name_admin",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.business_name || "",
              label: "Nome Commerciale",
              name: "business_name",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.landline_phone || "",
              label: "Tel fisso",
              name: "landline_phone",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: (customer.landline_phone && !!errorHelperText.landline_phone) || !!error,
              helperText:
                customer.landline_phone && errorHelperText.landline_phone === "invalid"
                  ? ERROR_INPUT.landline_phone.invalid
                  : undefined,
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.province || "",
              label: "Provincia",
              name: "province",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: (customer.province && !!errorHelperText.province) || !!error,
              helperText:
                customer.province && errorHelperText.province === "invalid"
                  ? ERROR_INPUT.province.invalid
                  : undefined,
              inputProps: { maxLength: 3, style: { textTransform: "uppercase" } },
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.studio_name || "",
              label: "Nome Studio",
              name: "studio_name",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.studio_classification || "",
              label: "Classificazione Studio",
              name: "studio_classification",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.studio_size || "",
              label: "Dimensione Studio",
              name: "studio_size",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.condos_number || "",
              label: "Numero Condomini",
              name: "condos_number",
              type: "number",
              textcolor: theme.colors.secondary,
              required: true,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        // customer condomini
        isCondo
          ? {
              value: customer.condo_name || "",
              label: "Nome Condominio",
              name: "condo_name",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCondo
          ? {
              value: customer.number_estate_units || "",
              label: "Numero Unità Immobiliari",
              name: "number_estate_units",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCondo
          ? {
              value: customer.number_floors || "",
              label: "Numero Piani",
              name: "number_floors",
              type: "number",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        // customer company
        isCompany
          ? {
              value: customer.vat_number || "",
              label: "Partita IVA",
              name: "vat_number",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCompany
          ? {
              value: customer.website || "",
              label: "Sito Web",
              name: "website",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCompany
          ? {
              value: customer.sector || "",
              label: "Settore",
              name: "sector",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isPrivate || isCondo || isCompany
          ? {
              value: customer.address || "",
              label: "Indirizzo",
              name: "address",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isPrivate || isCondo || isCondoAdmin
          ? {
              value: customer.mobile_phone || "",
              label: "Tel cellulare",
              name: "mobile_phone",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: (customer.mobile_phone && !!errorHelperText.mobile_phone) || !!error,
              helperText:
                customer.mobile_phone && errorHelperText.mobile_phone === "invalid"
                  ? ERROR_INPUT.mobile_phone.invalid
                  : undefined,
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin || isCompany
          ? {
              value: customer.city || "",
              label: "Città",
              name: "city",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isPrivate || isCondo || isCompany
          ? {
              value: customer.cf || "",
              label: "Codice Fiscale",
              name: "cf",
              type: "text",
              textcolor: theme.colors.secondary,
              required: false,
              error: (customer.cf && !!errorHelperText.cf) || !!error,
              helperText:
                customer.cf && errorHelperText.cf === "invalid"
                  ? ERROR_INPUT.cf.invalid
                  : undefined,
              inputProps: { style: { textTransform: "uppercase" } },
              onChange: handleOnChange,
            }
          : null,
      ],
      paddingTop: 2,
      rowGap: 2,
      inputColumns: resize ? 1 : 3,
    },
    {
      fields: [
        isCondoAdmin
          ? {
              value: customer.associated_customer ?? (false || ""),
              label: "Cliente Associato",
              name: "associated_customer",
              type: "checkbox",
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.note || "",
              label: "Note",
              name: "note",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
              error: !!error,
              onChange: handleOnChange,
            }
          : null,
      ],
      rowGap: 2,
    },
  ];
};

export const buttonProps = (
  dataLoading: boolean,
  isNotSelectCustomerType: boolean,
  loading: boolean,
  error: ErrorType | undefined,
): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{error.detail}</ErrorText> : null,
    fields: [
      {
        disabled: loading || !!error || isNotSelectCustomerType,
        label: dataLoading ? <CircularProgress size={24} /> : "Registra cliente",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  color: ${theme.colors.error};
  margin-bottom: 0.5rem;
`;
