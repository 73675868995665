import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { ButtonGroup, InputGroup } from "../../components/Form";
import { ROLES, UserRegisterType } from "../../models/User";
import { formatDate, formatServerError } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";
import { ERROR_INPUT, ErrorInputType } from "../ErrorInput";
import { ErrorType } from "../../models/Error";

const roleOptions = [
  { value: ROLES.USER, label: "User" },
  { value: ROLES.ADMIN, label: "Admin" },
  { value: ROLES.SELECT_ROLE, label: "Seleziona il ruolo" },
];

const currentDate = new Date().toISOString().split("T")[0];

export const inputProps = (
  user: UserRegisterType,
  errorHelperText: Record<string, ErrorInputType | null>,
  error: ErrorType | null,
  isMobile: boolean,
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
): InputGroup[] & { options?: typeof ROLES } => [
  {
    fields: [
      {
        value: user.name,
        label: "Nome",
        name: "name",
        type: "text",
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user.surname,
        label: "Cognome",
        name: "surname",
        type: "text",
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user.email,
        label: "Email",
        name: "email",
        type: "email",
        textcolor: theme.colors.secondary,
        required: true,
        error: (user.email && !!errorHelperText.email) || !!error,
        helperText: user.email && errorHelperText.email === "invalid" && ERROR_INPUT.email.invalid,
        onChange: handleOnChange,
      },
      {
        value: user.password,
        label: "Password",
        name: "password",
        type: "password",
        textcolor: theme.colors.secondary,
        required: true,
        error: (user.password && !!errorHelperText.password) || !!error,
        helperText:
          user.password &&
          errorHelperText.password === "minLength" &&
          ERROR_INPUT.password.minLength,
        onChange: handleOnChange,
      },
      {
        value: user.confirm_password,
        disabled: !user.password,
        label: "Conferma password",
        name: "confirm_password",
        type: "password",
        textcolor: theme.colors.secondary,
        required: true,
        error:
          (user.confirm_password && errorHelperText.confirm_password === "mismatch") || !!error,
        helperText:
          user.confirm_password &&
          errorHelperText.confirm_password === "mismatch" &&
          ERROR_INPUT.confirm_password.mismatch,
        onChange: handleOnChange,
      },
      {
        value: user.roles,
        label: "Ruoli",
        name: "roles",
        type: "select",
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        options: roleOptions,
        onChange: handleOnChange,
      },
      {
        value: user.mobile_phone,
        label: "Tel",
        name: "mobile_phone",
        type: "number",
        textcolor: theme.colors.secondary,
        required: true,
        error: (user.mobile_phone && !!errorHelperText.mobile_phone) || !!error,
        helperText:
          user.mobile_phone &&
          errorHelperText.mobile_phone === "invalid" &&
          ERROR_INPUT.mobile_phone.invalid,
        inputProps: { maxLength: 10 },
        onChange: handleOnChange,
      },
      {
        value: user.address,
        label: "Indirizzo",
        name: "address",
        type: "text",
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user.city,
        label: "Città",
        name: "city",
        type: "text",
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        onChange: handleOnChange,
      },
      {
        value: user.province,
        label: "Provincia",
        name: "province",
        type: "text",
        textcolor: theme.colors.secondary,
        required: true,
        error: (user.province && !!errorHelperText.province) || !!error,
        helperText:
          user.province && errorHelperText.province === "invalid" && ERROR_INPUT.province.invalid,
        inputProps: { maxLength: 3, style: { textTransform: "uppercase" } },
        onChange: handleOnChange,
      },
      {
        value: user?.date_of_birth ? formatDate(user.date_of_birth) : "",
        label: "Data di nascita",
        name: "date_of_birth",
        type: "date",
        textcolor: theme.colors.secondary,
        required: true,
        error: !!error,
        inputProps: { max: currentDate },
        onChange: handleOnChange,
      },
      {
        value: user.cf,
        label: "Codice Fiscale",
        name: "cf",
        type: "text",
        textcolor: theme.colors.secondary,
        required: true,
        error: (user.cf && !!errorHelperText.cf) || !!error,
        helperText: user.cf && errorHelperText.cf === "invalid" && ERROR_INPUT.cf.invalid,
        inputProps: { style: { textTransform: "uppercase" } },
        onChange: handleOnChange,
      },
    ],
    rowGap: 2,
    inputColumns: isMobile ? 1 : 2,
  },
];

export const buttonProps = (loading: boolean, error: ErrorType | null): ButtonGroup[] => [
  {
    childrenButton: error ? <ErrorText>{formatServerError(error.detail)}</ErrorText> : null,
    fields: [
      {
        disabled: loading || !!error,
        label: loading ? <CircularProgress size={24} /> : "Registra utente",
        type: "submit",
        bgcolor: theme.colors.primary,
        labelcolor: "#ffffff",
        hoverlabelcolor: theme.colors.primary,
      },
    ],
    paddingTop: 8,
  },
];

const ErrorText = styled.div`
  && {
    color: ${theme.colors.error};
    margin-bottom: 0.5rem;
  }
`;
