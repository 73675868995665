import { InputGroup } from "../../components/Form";
import { ExtInspReportType } from "../../models/ExtInspReport";
import { formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";

export const inputProps = (
  formData: ExtInspReportType,
  userFullName: string,
  demandId: string,
  resize: boolean,
): InputGroup[] => {
  return [
    {
      section: "Dati generici",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              value: userFullName || "",
              label: "Consulente",
              name: "commercial_user_id",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: demandId || "",
              label: "Id della richiesta da associare",
              name: "demand_id",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.date ? formatDate(formData.date) : "",
              label: "Data",
              name: "date",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.address || "",
              label: "Indirizzo",
              name: "address",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.work_contact || "",
              label: "Referente lavori",
              name: "work_contact",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.phone_work_contact || "",
              label: "Cellulare referente lavori",
              name: "phone_work_contact",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.email_work_contact || "",
              label: "Mail referente lavori",
              name: "email_work_contact",
              type: "email",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.access_contact || "",
              label: "Referente accesso",
              name: "access_contact",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.phone_access_contact || "",
              label: "Cellulare referente accesso",
              name: "phone_access_contact",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.email_access_contact || "",
              label: "Mail referente accesso",
              name: "email_access_contact",
              type: "text",
              textcolor: theme.colors.secondary,
            },
          ],
        },
        {
          paddingTop: 2,
          fields: [
            {
              value: formData.other || "",
              label: "Altro",
              name: "other",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
            },
          ],
        },
      ],
    },
    {
      section: "Info",
      groups: [
        {
          inputColumns: resize ? 1 : 3,
          fields: [
            // checkbox
            {
              checked: formData.elevator || false,
              label: "Ascensore",
              name: "elevator",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.attic || false,
              label: "Sottotetto",
              name: "attic",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.life_line || false,
              label: "Linea vita",
              name: "life_line",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.water_point || false,
              label: "Punto acqua",
              name: "water_point",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.light_point || false,
              label: "Punto luce",
              name: "light_point",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.tech_stock_room || false,
              label: "Locale tecnico/stoccaggio",
              name: "tech_stock_room",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.condos_roof_access || false,
              label: "Accesso tetto condominio",
              name: "condos_roof_access",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.private_cover_access || false,
              label: "Accesso copertura privata",
              name: "private_cover_access",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
          ],
        },
        {
          inputColumns: resize ? 1 : 3,
          paddingTop: 8,
          fields: [
            {
              value: formData.roof_type || "",
              label: "Tipologia tetto",
              name: "roof_type",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.number_pitches || "",
              label: "Numero falde",
              name: "number_pitches",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.floors_number || "",
              label: "N° piani",
              name: "floors_number",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.water_point_place || "",
              label: "Punto acqua luogo",
              name: "water_point_place",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.light_point_place || "",
              label: "Punto luce luogo",
              name: "light_point_place",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.tech_stock_room_place || "",
              label: "Locale tecnico/stoccaggio luogo",
              name: "tech_stock_room_place",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.number_estate_units || "",
              label: "N° unità immobiliari",
              name: "number_estate_units",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.condo_name || "",
              label: "Nome condominio",
              name: "condo_name",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.condo_surname || "",
              label: "Cognome condominio",
              name: "condo_surname",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.phone || "",
              label: "Telefono",
              name: "phone",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.email || "",
              label: "Email",
              name: "email",
              type: "text",
              textcolor: theme.colors.secondary,
            },
          ],
        },
        {
          paddingTop: 2,
          fields: [
            {
              value: formData.notes || "",
              label: "Note",
              name: "notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
            },
          ],
        },
      ],
    },
    {
      section: "Facciate",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              checked: formData.window_wash || false,
              label: "Lavaggio vetri",
              name: "window_wash",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.facade_wash || false,
              label: "Lavaggio facciate",
              name: "facade_wash",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
          ],
        },
        {
          inputColumns: resize ? 1 : 2,
          paddingTop: 8,
          fields: [
            {
              value: formData.securing || "",
              label: "Messa in sicurezza",
              name: "securing",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.restoration || "",
              label: "Ripristini",
              name: "restoration",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.shave || "",
              label: "Rasatura",
              name: "shave",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.painting || "",
              label: "Tinteggiatura",
              name: "painting",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.water_repellent_treatment || "",
              label: "Trattamento idrorepellente",
              name: "water_repellent_treatment",
              type: "text",
              textcolor: theme.colors.secondary,
            },
          ],
        },
      ],
    },
    {
      section: "Balconi",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              checked: formData.adjustment || false,
              label: "Adeguamento",
              name: "adjustment",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.waterproofing || false,
              label: "Impermeabilizzazione",
              name: "waterproofing",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.flooring || false,
              label: "Pavimentazione",
              name: "flooring",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.rehabilitation_railings || false,
              label: "Risanamento ringhiere",
              name: "rehabilitation_railings",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
          ],
        },
        {
          paddingTop: 8,
          fields: [
            {
              value: formData.balconies_notes || "",
              label: "Note balconi",
              name: "balconies_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
            },
          ],
        },
      ],
    },
    {
      section: "Copertura",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              checked: formData.antivolatile || false,
              label: "Antivolatili",
              name: "antivolatile",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.insulated || false,
              label: "Coibentato",
              name: "insulated",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.new_roof || false,
              label: "Nuovo tetto (listelli, tegole)",
              name: "new_roof",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.structure || false,
              label: "Struttura",
              name: "structure",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.lifeline_project || false,
              label: "Linea vita progetto",
              name: "lifeline_project",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.maintenance || false,
              label: "Manutenzione",
              name: "maintenance",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.sheath || false,
              label: "Guaina",
              name: "sheath",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              checked: formData.tiles || false,
              label: "Tegola",
              name: "tiles",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
          ],
        },
        {
          inputColumns: 1,
          paddingTop: 8,
          fields: [
            {
              value: formData.obligatory_booklet || "",
              label: "Libretto obbligatorio",
              name: "obligatory_booklet",
              type: "text",
              textcolor: theme.colors.secondary,
            },
          ],
        },
        {
          inputColumns: 1,
          paddingTop: 2,
          fields: [
            {
              checked: formData.overhaul_coverage || false,
              label: "Ripasso copertura",
              name: "overhaul_coverage",
              type: "checkbox",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.overhaul_coverage_percentage || "",
              label: "",
              name: "overhaul_coverage_percentage",
              type: "percentage",
              textcolor: theme.colors.secondary,
            },
          ],
        },
      ],
    },
    {
      section: "Lattoneria",
      groups: [
        {
          inputColumns: resize ? 1 : 2,
          fields: [
            {
              value: formData.gutters || "",
              label: "Gronde",
              name: "gutters",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.gutters_mt || "",
              label: "Gronde (mt)",
              name: "gutters_mt",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.downspouts || "",
              label: "Pluviali",
              name: "downspouts",
              type: "text",
              textcolor: theme.colors.secondary,
            },
            {
              value: formData.downspouts_mt || "",
              label: "Scossaline (mt)",
              name: "downspouts_mt",
              type: "text",
              textcolor: theme.colors.secondary,
            },
          ],
        },
        {
          paddingTop: 2,
          fields: [
            {
              value: formData.tinsmithery_notes || "",
              label: "Note lattoneria",
              name: "tinsmithery_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
            },
          ],
        },
      ],
    },
    {
      section: "Facciata",
      groups: [
        {
          groupTitle: "lato facciata",
          subGroups: formData.sides.map((data, index) => ({
            title: `L${index + 1}`,
            inputColumns: resize ? 1 : 4,
            fields: [
              {
                value: data?.mt || "",
                label: "Metrature",
                name: "mt",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
              },
              {
                value: data?.ground || "",
                label: "Terra",
                name: "ground",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
              },
              {
                value: data?.border || "",
                label: "Bordo",
                name: "border",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
              },
              {
                value: data?.h_interplane || "",
                label: "H Interpiano",
                name: "h_interplane",
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
              },
            ],
          })),
        },
        {
          paddingTop: 4,
          fields: [
            {
              value: formData.facade_notes || "",
              label: "Note facciata",
              name: "facade_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
              required: false,
            },
          ],
        },
      ],
    },
    {
      section: "Balconi privati",
      groups: [
        {
          groupTitle: "balconi",
          isDynamicInput: true,
          isVisibleQuantity: true,
          inputColumns: resize ? 1 : 4,
          subGroups: Object.keys(formData.balconies || {}).map((key, index) => {
            const balcony = formData.balconies ? formData.balconies[key] : null;

            if (!balcony) {
              return {
                inputColumns: resize ? 1 : 4,
                title: `Balcone ${index + 1}`,
                fields: [],
              };
            }

            const createGroupedFields = (
              values: (string | undefined)[][],
              label: string,
              name: string,
            ) => {
              const filteredValues = values.filter((valueArray) =>
                valueArray.some((val) => val !== undefined && val !== ""),
              );

              return filteredValues.map((valueArray) => ({
                value: valueArray || "",
                label,
                name,
                type: "text",
                textcolor: theme.colors.secondary,
                required: false,
              }));
            };

            return {
              inputColumns: resize ? 1 : 4,
              title: `Balcone ${index + 1}`,
              fields: [
                ...createGroupedFields(
                  balcony.pavimenti || [],
                  "MQ Pavimenti/Cielini",
                  "pavimenti",
                ),
                ...createGroupedFields(balcony.frontalini || [], "ML Frontalini", "frontalini"),
                ...createGroupedFields(
                  balcony.parapetto_muratura || [],
                  "ML Parapetto Muratura",
                  "parapetto_muratura",
                ),
                ...createGroupedFields(balcony.ringhiere || [], "ML Ringhiere", "ringhiere"),
              ],
            };
          }),
        },
        {
          paddingTop: 2,
          fields: [
            {
              value: formData.private_balconies_notes || "",
              label: "Note balconi privati",
              name: "private_balconies_notes",
              type: "text",
              multiline: true,
              textcolor: theme.colors.secondary,
            },
          ],
        },
      ],
    },
  ];
};
