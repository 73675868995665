import { jwtDecode } from "jwt-decode";
import { ReactNode, createContext, useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { ErrorType } from "../models/Error";
import {
  RolesArrayType,
  User,
  UserEdit,
  UserEditType,
  UserLoggedResponseType,
  UserLoginResponse,
  UserLoginResponseType,
  UserRegister,
  UserRegisterResponse,
  UserRegisterResponseType,
  UserRegisterType,
  UserResponse,
  UserResponseCollection,
  UserResponseCollectionType,
  UserResponseType,
  UserType,
} from "../models/User";
import { callApi } from "../services/ApiServices";
import { handleApiError } from "../utils/ErrorUtils";

export type AppContextType = {
  token: string | null;
  loading: boolean;
  error: ErrorType | null;
  users: UserResponseCollectionType | null;
  currentUser: UserResponseType | null;
  currentPage: number;
  totalPages: number;
  userDecodeToken: DecodedToken | null;
  userLogged: UserLoggedResponseType | null;
  login: (user: UserType) => Promise<void>;
  _refreshToken: () => Promise<void>;
  register: (user: UserRegisterType) => Promise<void>;
  logout: () => void;
  setError: (error: ErrorType | null) => void;
  getUsers: (endpoint: string, page?: number) => Promise<void>;
  setCurrentUser: (user: UserResponseType | null) => void;
  setLoading: (loading: boolean) => void;
  updateUser: (user: UserEditType) => Promise<void>;
  getUserById: (userId: number) => Promise<void>;
  setUsers: (users: UserResponseCollectionType | null) => void;
};

type AppProviderType = {
  children: ReactNode;
};

export interface DecodedToken {
  roles: RolesArrayType;
  exp?: number;
}

const defaultContextValue: AppContextType = {
  token: null,
  loading: false,
  error: null,
  users: null,
  currentUser: null,
  currentPage: 1,
  totalPages: 0,
  userDecodeToken: null,
  userLogged: null,
  login: async () => {},
  _refreshToken: async () => {},
  register: async () => {},
  logout: () => {},
  setError: () => {},
  getUsers: async () => {},
  setCurrentUser: () => {},
  setLoading: () => {},
  updateUser: async () => {},
  getUserById: async () => {},
  setUsers: () => {},
};

const TOKEN_KEY = "token";

// gestione token
const getAuthToken = (): { token: string | null; rememberMe: boolean } => {
  const localToken = localStorage.getItem(TOKEN_KEY);
  const sessionToken = sessionStorage.getItem(TOKEN_KEY);

  if (localToken) {
    return { token: localToken, rememberMe: true };
  } else if (sessionToken) {
    return { token: sessionToken, rememberMe: false };
  }

  return { token: null, rememberMe: false };
};

const setAuthToken = (token: string, rememberMe: boolean): void => {
  if (rememberMe) {
    sessionStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  } else {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.setItem(TOKEN_KEY, token);
  }
};

const removeAuthToken = (rememberMe: boolean): void => {
  if (rememberMe) {
    localStorage.removeItem(TOKEN_KEY);
  } else {
    sessionStorage.removeItem(TOKEN_KEY);
  }
};

const isTokenExpired = (exp: number): boolean => {
  const currentTime = Math.floor(Date.now() / 1000);
  return exp < currentTime;
};

const AppContext = createContext<AppContextType>(defaultContextValue);

const AppProvider = ({ children }: AppProviderType) => {
  const { token: initialToken, rememberMe: initialRememberMe } = getAuthToken();
  const [rememberMe, setRememberMe] = useState<boolean>(initialRememberMe);
  const [token, setToken] = useState<string | null>(initialToken);
  const [error, setError] = useState<ErrorType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserResponseCollectionType | null>(null);
  const [currentUser, setCurrentUser] = useState<UserResponseType | null>(null);
  const [userLogged, setUserLogged] = useLocalStorage<UserLoggedResponseType | null>(
    "userLogged",
    null,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [userDecodeToken, setUserDecodeToken] = useState<DecodedToken | null>(null);

  // useEffect
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode<DecodedToken>(token);
        setUserDecodeToken({ exp: decodedToken.exp, roles: decodedToken.roles });
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token, setUserDecodeToken]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (userDecodeToken && userDecodeToken.exp) {
        if (isTokenExpired(userDecodeToken.exp)) {
          logout();
        }
      }
    };

    checkTokenExpiration();

    const intervalId = setInterval(checkTokenExpiration, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [userDecodeToken]);

  // chiamate API
  const getUserById = async (userId: number) => {
    try {
      const response = await callApi<UserResponseType, undefined>(
        "GET",
        `/users/${userId}`,
        UserResponse,
      );
      if (response) {
        setCurrentUser(response);
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    }
  };

  const updateUser = useCallback(
    async (user: UserEditType) => {
      setError(null);
      setLoading(true);
      try {
        const body: UserEditType = {
          id: user.id,
          email: user.email,
          roles: user.roles,
          name: user.name,
          surname: user.surname,
          mobile_phone: user.mobile_phone,
          address: user.address,
          city: user.city,
          province: user.province,
          cf: user.cf,
          date_of_birth: user.date_of_birth,
        };

        const response = await callApi<UserResponseType, UserEditType>(
          "PUT",
          `/users/${user.id}`,
          UserResponse,
          UserEdit,
          body,
        );

        if (response) {
          setCurrentUser(response);
          setUserDecodeToken({ roles: response.roles });
          setUserLogged({ name: response.name, surname: response.surname, id: response.id });
          alert("utente aggiornato!");
        }
      } catch (error) {
        handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      } finally {
        setLoading(false);
      }
    },
    [userDecodeToken],
  );

  const login = useCallback(async (user: UserType) => {
    setError(null);
    setLoading(true);
    try {
      const body: UserType = {
        email: user.email,
        password: user.password,
        rememberMe: user.rememberMe,
      };
      const response = await callApi<UserLoginResponseType, UserType>(
        "POST",
        "/login",
        UserLoginResponse,
        User,
        body,
      );

      setRememberMe(user.rememberMe);

      if (response) {
        setAuthToken(response.token, user.rememberMe);
        setToken(response.token);
        setUserLogged(response.data);
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const _refreshToken = useCallback(async () => {
    try {
      const response = await callApi<UserLoginResponseType, undefined>(
        "GET",
        "/token/refresh",
        UserLoginResponse,
        undefined,
        undefined,
      );

      if (response) {
        console.log(response.token);
      }
    } catch (error) {
      console.error("Errore durante il refresh del token:", error);
    }
  }, []);

  const register = useCallback(async (user: UserRegisterType) => {
    setError(null);
    setLoading(true);
    try {
      const body: UserRegisterType = {
        email: user.email,
        password: user.password,
        confirm_password: user.confirm_password,
        name: user.name,
        surname: user.surname,
        roles: user.roles,
        mobile_phone: user.mobile_phone,
        address: user.address,
        city: user.city,
        province: user.province,
        date_of_birth: user.date_of_birth,
        cf: user.cf,
      };
      await callApi<UserRegisterResponseType, UserRegisterType>(
        "POST",
        "/register",
        UserRegisterResponse,
        UserRegister,
        body,
      );

      alert("Utente creato con successo!");
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const getUsers = useCallback(async (endpoint: string, page?: number) => {
    setLoading(true);
    try {
      const response = await callApi<UserResponseCollectionType, undefined>(
        "GET",
        endpoint,
        UserResponseCollection,
      );

      if (response) {
        setUsers(response);
      }

      if (page && response) {
        setCurrentPage(page);
        setTotalPages(Math.ceil(response["hydra:totalItems"] / 10));
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const _setCurrentUser = (user: UserResponseType | null) => {
    setCurrentUser(user);
  };

  const logout = () => {
    removeAuthToken(rememberMe);
    setToken(null);
  };

  return (
    <AppContext.Provider
      value={{
        token,
        loading,
        error,
        users,
        currentUser,
        currentPage,
        totalPages,
        userDecodeToken,
        userLogged,
        login,
        _refreshToken,
        register,
        logout,
        setError,
        getUsers,
        setCurrentUser: _setCurrentUser,
        setLoading,
        updateUser,
        getUserById,
        setUsers,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
