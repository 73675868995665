import { ExitToApp, Person } from "@mui/icons-material";
import { DropdownOptionType } from "../components/Dropdown";
import { theme } from "../utils/Theme";

export const dropdownOptions = (
  handleProfileClick: () => void,
  handleLogoutClick: () => void,
  showIconsOnly?: boolean,
): DropdownOptionType[] => {
  return [
    {
      label: "Profilo",
      icon: <Person sx={{ color: showIconsOnly ? theme.colors.white : theme.colors.background }} />,
      onClick: handleProfileClick,
    },
    {
      label: "Logout",
      icon: <ExitToApp sx={{ color: theme.colors.error }} />,
      onClick: handleLogoutClick,
    },
  ];
};
