import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "../../components/Form";
import Loading from "../../components/Loading";
import { inputProps } from "../../constants/customer-histories/CustomerHistoryProfile";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import {
  CustomerHistoryResponse,
  CustomerHistoryResponseType,
  CustomerHistoryType,
} from "../../models/CustomerHistory";
import { ErrorType } from "../../models/Error";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";

// export function
const getFields = <T extends { id: number }>(
  collection: T[] | null,
  id: string | null,
  fields: (keyof T)[],
): string => {
  if (!collection || !id) return "";
  const entity = collection.find((item) => item.id === Number(id));

  if (!entity) return "";

  return fields.map((field) => (entity[field] ? String(entity[field]) : "")).join(" ");
};

const CustomerHistoryProfileDataProvider = () => {
  const { error, setError } = useContext(AppContext);
  const { customers, users, demands, getCustomers, getUsers, getDemands, loadAllData } =
    useApiData();
  const deviceType = useDeviceType();
  const { id } = useParams();

  const [customerHistory, setCustomerHistory] = useState<CustomerHistoryType | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      try {
        await loadAllData(getCustomers);
        await loadAllData(getUsers);
        await loadAllData(getDemands);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (id) {
      getCustomerHistory(id);
    }
  }, [id]);

  const getCustomerHistory = async (id: string) => {
    try {
      const response = await callApi<CustomerHistoryResponseType, undefined>(
        "GET",
        `/customer_histories/${id}`,
        CustomerHistoryResponse,
        undefined,
      );

      if (response) {
        const formattedData = {
          ...response,
          customer_id: response.customer_id?.replace("/api/customers/", ""),
          user_id: response.user_id?.replace("/api/users/", ""),
          demand_id: response.demand_id?.replace("/api/demands/", ""),
        };

        setCustomerHistory(formattedData);
      }
    } catch (e) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      console.error(e);
    }
  };

  const userFullName = getFields(
    users ? users["hydra:member"] : null,
    customerHistory?.user_id ?? null,
    ["name", "surname"],
  );

  const customerFullName = getFields(
    customers ? customers["hydra:member"] : null,
    customerHistory?.customer_id ?? null,
    ["name", "surname"],
  );

  const demandUniqueProtocol = getFields(
    demands ? demands["hydra:member"] : null,
    customerHistory?.demand_id ?? null,
    ["unique_protocol"],
  );

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        customerHistory && (
          <Form
            title={deviceType !== DeviceType.Mobile ? "" : "Profilo storico cliente"}
            inputProps={inputProps(
              customerHistory,
              customerFullName,
              userFullName,
              demandUniqueProtocol,
              deviceType === DeviceType.Mobile,
            )}
            onSubmit={() => {}}
          />
        )
      )}
    </>
  );
};

export default CustomerHistoryProfileDataProvider;
