import { InputGroup } from "../../components/Form";
import { CustomInputProps } from "../../components/Input";
import { CUSTOMER_TYPES, CustomerType } from "../../models/Customer";
import { currentDate, formatDate } from "../../utils/Formatters";
import { theme } from "../../utils/Theme";

export const inputProps = (
  userFullName: string,
  customer: CustomerType,
  resize: boolean,
): InputGroup[] => {
  const isCondoAdmin = customer.customer_type === CUSTOMER_TYPES.CONDO_ADMIN;
  const isCondo = customer.customer_type === CUSTOMER_TYPES.CONDO;
  const isCompany = customer.customer_type === CUSTOMER_TYPES.COMPANY;
  const isPrivate = customer.customer_type === CUSTOMER_TYPES.PRIVATE;

  // Common fields
  const commonFields: CustomInputProps[] = [
    {
      value: customer.name || "",
      label: "Nome",
      name: "name",
      type: "text",
      textcolor: theme.colors.secondary,
      required: false,
    },
    {
      value: customer.surname || "",
      label: "Cognome",
      name: "surname",
      type: "text",
      textcolor: theme.colors.secondary,
    },
    {
      value: customer.pec || "",
      label: "PEC",
      name: "pec",
      type: "text",
      textcolor: theme.colors.secondary,
    },
    {
      value: customer.email || "",
      label: "Email",
      name: "email",
      type: "text",
      textcolor: theme.colors.secondary,
    },
    {
      value: userFullName || "",
      label: "Riferimento Commerciale",
      name: "reference_commercial",
      type: "text",
      textcolor: theme.colors.secondary,
    },
    {
      value: customer.customer_state,
      label: "Stato cliente",
      name: "customer_state",
      type: "text",
      textcolor: theme.colors.secondary,
    },
  ];

  return [
    {
      fields: commonFields,
      inputColumns: resize ? 1 : 2,
    },
    {
      fields: [
        {
          value: customer.customer_type || "",
          label: "Tipo Cliente",
          name: "customer_type",
          type: "text",
          textcolor: theme.colors.secondary,
          required: false,
        },
        isPrivate
          ? {
              value: customer.date_of_birth ? formatDate(customer.date_of_birth) : "",
              label: "Data di Nascita",
              name: "date_of_birth",
              type: "text",
              textcolor: theme.colors.secondary,
              inputProps: { max: currentDate() },
            }
          : null,
        isPrivate
          ? {
              value: customer.birth_place || "",
              label: "Luogo di Nascita",
              name: "birth_place",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.prefix_name_admin || "",
              label: "Prefisso Nome Admin",
              name: "prefix_name_admin",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.business_name || "",
              label: "Nome Commerciale",
              name: "business_name",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.landline_phone || "",
              label: "Tel fisso",
              name: "landline_phone",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.province || "",
              label: "Provincia",
              name: "province",
              type: "text",
              textcolor: theme.colors.secondary,
              inputProps: { maxLength: 3, style: { textTransform: "uppercase" } },
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.studio_name || "",
              label: "Nome Studio",
              name: "studio_name",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.studio_classification || "",
              label: "Classificazione Studio",
              name: "studio_classification",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.studio_size || "",
              label: "Dimensione Studio",
              name: "studio_size",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondoAdmin
          ? {
              value: customer.condos_number || "",
              label: "Numero Condomini",
              name: "condos_number",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondo
          ? {
              value: customer.condo_name || "",
              label: "Nome Condominio",
              name: "condo_name",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondo
          ? {
              value: customer.number_estate_units || "",
              label: "Numero Unità Immobiliari",
              name: "number_estate_units",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondo
          ? {
              value: customer.number_floors || "",
              label: "Numero Piani",
              name: "number_floors",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCompany
          ? {
              value: customer.vat_number || "",
              label: "Partita IVA",
              name: "vat_number",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCompany
          ? {
              value: customer.website || "",
              label: "Sito Web",
              name: "website",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCompany
          ? {
              value: customer.sector || "",
              label: "Settore",
              name: "sector",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isPrivate || isCondo || isCompany
          ? {
              value: customer.address || "",
              label: "Indirizzo",
              name: "address",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isPrivate || isCondo || isCondoAdmin
          ? {
              value: customer.mobile_phone || "",
              label: "Tel cellulare",
              name: "mobile_phone",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isCondoAdmin || isCompany
          ? {
              value: customer.city || "",
              label: "Città",
              name: "city",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
        isPrivate || isCondo || isCompany
          ? {
              value: customer.cf || "",
              label: "Codice Fiscale",
              name: "cf",
              type: "text",
              textcolor: theme.colors.secondary,
            }
          : null,
      ],
      paddingTop: 2,
      inputColumns: resize ? 1 : 2,
    },
  ];
};
