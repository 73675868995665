import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import styled from "styled-components";
import { formatDate, formatRoles } from "../utils/Formatters";
import { theme } from "../utils/Theme";

export type IconType<T> = {
  icon: React.ReactNode;
  color?: string;
  titleTooltip?: string;
  onClick: (item: T) => void;
};

export type Column<T> = {
  header: string;
  accessor: keyof T;
};

type GenericTableProps<T> = {
  data: T[] | null;
  columns: Column<T>[];
  icons?: IconType<T>[];
  loading: boolean;
  error: string | null;
  children?: ReactNode;
};

const GenericTable = <T,>({
  data,
  columns,
  icons,
  loading,
  error,
  children,
}: GenericTableProps<T>) => {
  const formatValue = <T,>(value: T[keyof T], accessor: keyof T): string => {
    if (accessor === "roles" && Array.isArray(value)) {
      return formatRoles(value);
    }

    if (accessor === "date") {
      return formatDate(String(value));
    }

    if (typeof value === "string" || typeof value === "number") {
      return String(value);
    }

    return "";
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorText color={theme.colors.error}>{error}</ErrorText>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCellTitle key={index}>{column.header}</StyledTableCellTitle>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <StyledTableCell key={colIndex}>
                    {row[column.accessor] !== undefined
                      ? formatValue(row[column.accessor], column.accessor)
                      : ""}
                  </StyledTableCell>
                ))}

                {icons && (
                  <StyledTableCellIcons key={`cell-${rowIndex}`}>
                    {icons.map((icon, iconIndex) => (
                      <Tooltip title={icon.titleTooltip} key={`icon-${iconIndex}`}>
                        <IconButton onClick={() => icon.onClick(row)} style={{ color: icon.color }}>
                          {icon.icon}
                        </IconButton>
                      </Tooltip>
                    ))}
                  </StyledTableCellIcons>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {children}
    </TableContainer>
  );
};

const StyledTableCell = styled(TableCell)`
  && {
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.text};
  }
`;

const StyledTableCellIcons = styled(StyledTableCell)`
  && {
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.text};
    display: flex;
    flex-direction: row;
  }
`;

const StyledTableCellTitle = styled(TableCell)`
  && {
    font-family: ${theme.fonts.primary};
    font-size: ${theme.fontSizes.medium};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.secondary};
  }
`;

const ErrorText = styled(Typography)`
  && {
    font-family: ${theme.fonts.primary};
    display: flex;
    justify-content: center;
  }
`;

export default GenericTable;
