import { Tab, Tabs } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { theme } from "../utils/Theme";
import useDeviceType, { DeviceType } from "../hooks/useDeviceType";

interface SectionPaginationProps {
  sections?: string[];
  selectedSection: string;
  onChange: (section: string) => void;
}

const SectionPagination = ({
  sections = [],
  selectedSection,
  onChange,
}: SectionPaginationProps) => {
  const deviceType = useDeviceType();

  const handleOnChange = (_: React.SyntheticEvent, newValue: string) => {
    onChange(newValue);
  };

  return (
    <Container>
      <StyledTabs
        devicetype={deviceType}
        value={selectedSection}
        onChange={handleOnChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.colors.primary,
          },
        }}
      >
        {sections.map((section) => (
          <StyledTab key={section} label={section} value={section} />
        ))}
      </StyledTabs>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;

const StyledTabs = styled(Tabs)<{ devicetype?: DeviceType }>`
  width: 100%;

  .MuiTabs-flexContainer {
    justify-content: ${({ devicetype: deviceType }) =>
      deviceType !== DeviceType.Desktop ? "" : "center"};
  }
`;

const StyledTab = styled(Tab)`
  && {
    font-family: ${theme.fonts.primary};
  }

  &&.Mui-selected {
    color: ${theme.colors.secondary};
    border-bottom-color: ${theme.colors.secondary};
  }
`;

export default SectionPagination;
