import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { dropdownOptions } from "../constants/DropdownOptions";
import { AppContext } from "../context/AppContext";
import useDeviceType, { DeviceType } from "../hooks/useDeviceType";
import { theme } from "../utils/Theme";
import Dropdown from "./Dropdown";

export type ItemType = {
  text?: string;
  icon?: React.ReactNode;
  path?: string;
};

export type GroupItemType = {
  title?: string;
  items: ItemType[];
};

interface SidebarProps {
  open: boolean;
  items: GroupItemType[];
  selectedItem: string | null;
  backgroundColor?: string;
  textColor?: string;
  selectedColor?: string;
  onSelectItem: (item: ItemType) => void;
}

export const Sidebar = ({
  open,
  items,
  selectedItem,
  backgroundColor = theme.colors.secondary,
  textColor = theme.colors.white,
  selectedColor = theme.colors.primary,
  onSelectItem,
}: SidebarProps) => {
  const { userLogged, logout, getUserById } = useContext(AppContext);
  const [isOpenGroup, setIsOpenGroup] = useState<string | false>(false);
  const deviceType = useDeviceType();
  const navigate = useNavigate();
  const location = useLocation();

  // aggiorna la selezione dell'item in base al path corrente
  useEffect(() => {
    const findActiveItem = (path: string) => {
      for (const group of items) {
        for (const item of group.items) {
          if (item.path === path) {
            return item.text;
          }
        }
      }
      return null;
    };

    const activeItemText = findActiveItem(location.pathname);

    if (activeItemText !== selectedItem) {
      onSelectItem({ path: location.pathname, text: activeItemText || "", icon: null });
    }
  }, [location.pathname, selectedItem]);

  const handleAccordionChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setIsOpenGroup(isExpanded ? panel : false);
    };

  const handleProfileClick = async () => {
    try {
      if (userLogged) {
        await getUserById(userLogged.id);
        navigate(`/home/users/${userLogged.id}`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: open ? 240 : 60,
        height: "100vh",
        position: "fixed",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        backgroundColor,
      }}
    >
      {items.map(({ title, items }, index) =>
        title ? (
          <Tooltip key={index} title={deviceType === DeviceType.Desktop ? "" : title}>
            <StyledAccordion
              expanded={isOpenGroup === `panel-${index}`}
              onChange={handleAccordionChange(`panel-${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.colors.white }} />}
                id={`panel-${index}-header`}
                sx={{ backgroundColor: "transparent" }}
              >
                {open && <Title $textcolor={textColor}>{title}</Title>}
              </AccordionSummary>
              <StyledAccordionDetails>
                <StyledList open={open}>
                  {items.map(({ icon, text, path }, index) => {
                    const listItemContent = (
                      <ListItem
                        key={index}
                        onClick={() => onSelectItem({ path, text, icon })}
                        sx={{
                          display: "flex",
                          flexDirection: open ? "row" : "column",
                          color: textColor,
                          paddingLeft: 0,
                          paddingRight: 0,
                          justifyContent: "center",
                          backgroundColor: selectedItem === text ? selectedColor : "inherit",
                          borderRadius: "1rem",
                          "&:hover": {
                            backgroundColor: selectedColor,
                            cursor: "pointer",
                            borderRadius: "1rem",
                          },
                        }}
                      >
                        {icon && (
                          <ListItemIcon sx={{ color: textColor, justifyContent: "center" }}>
                            {icon}
                          </ListItemIcon>
                        )}
                        {open && (
                          <ListItemText
                            primary={text}
                            sx={{ justifyContent: "center" }}
                            primaryTypographyProps={{
                              sx: {
                                fontFamily: theme.fonts.primary,
                                color: textColor,
                              },
                            }}
                          />
                        )}
                      </ListItem>
                    );

                    return deviceType !== DeviceType.Desktop ? (
                      <Tooltip title={text} key={index}>
                        {listItemContent}
                      </Tooltip>
                    ) : (
                      listItemContent
                    );
                  })}
                </StyledList>
              </StyledAccordionDetails>
            </StyledAccordion>
          </Tooltip>
        ) : (
          <StyledList open={open} key={index}>
            {items.map(({ icon, text, path }, index) => {
              const listItemContent = (
                <ListItem
                  key={index}
                  onClick={() => onSelectItem({ path, text, icon })}
                  sx={{
                    display: "flex",
                    flexDirection: open ? "row" : "column",
                    color: textColor,
                    paddingLeft: 0,
                    paddingRight: 0,
                    justifyContent: "center",
                    backgroundColor: selectedItem === text ? selectedColor : "inherit",
                    borderRadius: "1rem",
                    "&:hover": {
                      backgroundColor: selectedColor,
                      cursor: "pointer",
                      borderRadius: "1rem",
                    },
                  }}
                >
                  {icon && (
                    <ListItemIcon sx={{ color: textColor, justifyContent: "center" }}>
                      {icon}
                    </ListItemIcon>
                  )}
                  {open && (
                    <ListItemText
                      primary={text}
                      sx={{ justifyContent: "center" }}
                      primaryTypographyProps={{
                        sx: {
                          fontFamily: theme.fonts.primary,
                          color: textColor,
                        },
                      }}
                    />
                  )}
                </ListItem>
              );

              return deviceType !== DeviceType.Desktop ? (
                <Tooltip title={text} key={index}>
                  {listItemContent}
                </Tooltip>
              ) : (
                listItemContent
              );
            })}
          </StyledList>
        ),
      )}
      {deviceType === DeviceType.Mobile && (
        <Dropdown
          showIconsOnly={true}
          options={dropdownOptions(handleProfileClick, logout, true)}
          textColor={theme.colors.primary}
        />
      )}
    </Box>
  );
};

const Title = styled.label<{ $textcolor: string }>`
  color: ${({ $textcolor }) => $textcolor};
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.semibold};
`;

const StyledList = styled(List)<{ open: boolean }>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding-left: ${({ open }) => (open ? 0 : "1rem")};
    align-self: ${({ open }) => (open ? "auto" : "center")};
    align-items: center;
    padding-bottom: 0;
    padding: 1rem 0;
  }
`;

const StyledAccordion = styled(Accordion)`
  && {
    background: transparent;
    padding: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0 8px 0 8px;
  }
`;
