import { FilterList } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { DefaultFilterValuesType } from "../data-providers/customer-histories/CustomerHistoryDataProvider";
import { theme } from "../utils/Theme";
import CustomInput from "./Input";

export interface FilterProps<T> {
  label: string;
  type?: string;
  filterFn: (item: T, filterValue: string | null) => boolean;
}

export type FiltersType<T> = FilterProps<T>[];

export interface FilterBarProps<T> {
  data: T[];
  filters: FiltersType<T>;
  onFilter: (filteredData: T[]) => void;
  columns: number;
  defaultFilterValues?: DefaultFilterValuesType | null;
}

const FilterBar = <T,>({
  data,
  filters,
  onFilter,
  columns,
  defaultFilterValues = [],
}: FilterBarProps<T>) => {
  const [filterValues, setFilterValues] = useState<(string | null)[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFilters, setSelectedFilters] = useState<FiltersType<T>>([]);

  useEffect(() => {
    // Inizializza i filtri con i valori predefiniti
    if (defaultFilterValues && defaultFilterValues.length > 0) {
      const initialFilters = defaultFilterValues.map(({ label }) => {
        return filters.find((filter) => filter.label === label) || null;
      }) as FiltersType<T>;

      setSelectedFilters(initialFilters);
      setFilterValues(defaultFilterValues.map(({ value }) => value));
    }
  }, [defaultFilterValues, filters]);

  useEffect(() => {
    applyFilters();
  }, [filterValues]);

  const handleFilterChange = (index: number, value: string | null) => {
    const newFilterValues = [...filterValues];
    newFilterValues[index] = value;
    setFilterValues(newFilterValues);
  };

  const applyFilters = useCallback(() => {
    let filteredData = data;
    selectedFilters.forEach((filter, index) => {
      const filterValue = filterValues[index];
      if (filterValue !== null && filterValue !== "") {
        filteredData = filteredData.filter((item) => filter.filterFn(item, filterValue));
      }
    });
    onFilter(filteredData);
  }, [filterValues, selectedFilters, data, onFilter]);

  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddFilter = (filter: FilterProps<T>) => {
    const isSelected = selectedFilters.some((selected) => selected.label === filter.label);
    if (isSelected) {
      setSelectedFilters(selectedFilters.filter((selected) => selected.label !== filter.label));
      setFilterValues(
        filterValues.filter((_, index) => selectedFilters[index].label !== filter.label),
      );
    } else {
      setSelectedFilters([...selectedFilters, filter]);
      setFilterValues([...filterValues, null]);
    }
  };

  return (
    <FilterContainer>
      <FiltersColumn columns={columns} gap={2}>
        {selectedFilters.map((filter, index) => (
          <CustomInput
            key={index}
            type={filter.type}
            label={filter.label}
            value={filterValues[index] || ""}
            textcolor={theme.colors.secondary}
            onChange={(e) => handleFilterChange(index, e.target.value || null)}
            variant="outlined"
            size="small"
          />
        ))}
      </FiltersColumn>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <IconButton onClick={handleFilterMenuOpen}>
          <FilterList />
        </IconButton>
        <Label>Filtri</Label>
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          {filters.map((filter, index) => {
            const isSelected = selectedFilters.some((selected) => selected.label === filter.label);
            return [
              <StyledMenuItem
                key={filter.label}
                onClick={() => handleAddFilter(filter)}
                style={{ backgroundColor: isSelected ? theme.colors.primary : "transparent" }}
              >
                <MenuLabel>{filter.label}</MenuLabel>
              </StyledMenuItem>,
              index < filters.length - 1 && <StyledDivider />,
            ];
          })}
        </Menu>
      </Box>
    </FilterContainer>
  );
};

export default FilterBar;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const FiltersColumn = styled(Box)<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 1rem;
  flex-grow: 1;
  margin-right: 1rem;
`;

const Label = styled.label`
  color: ${theme.colors.secondary};
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.semibold};
  font-size: ${theme.fontSizes.medium};
`;

const MenuLabel = styled.label`
  color: ${theme.colors.text};
  font-family: ${theme.fonts.primary};
  font-weight: ${theme.fontWeights.semibold};
  font-size: ${theme.fontSizes.medium};
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    cursor: pointer;
    min-width: 200px;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    background-color: ${theme.colors.backgroundLight};
  }
`;
