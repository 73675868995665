import { ErrorInputType } from "../constants/ErrorInput";

export interface Validators<T> {
  [key: string]: (value: T[keyof T], data?: T) => ErrorInputType | null;
}

export const validateForm = <T>(
  data: T,
  validators: Validators<T>,
): Record<string, ErrorInputType | null> => {
  const errors: Record<string, ErrorInputType | null> = {};

  for (const [key, validator] of Object.entries(validators)) {
    const value = data[key as keyof T];
    const error = validator(value, data);
    if (error) {
      errors[key] = error;
    }
  }

  return errors;
};
