import { ExtInspReportType } from "../../models/ExtInspReport";
import { Validators } from "../../utils/Validate";

const validators: Validators<ExtInspReportType> = {
  email_work_contact: (value) => {
    if (typeof value === "string" && !/\S+@\S+\.\S+/.test(value)) {
      return "invalid";
    }
    return null;
  },

  email_access_contact: (value) => {
    if (typeof value === "string" && !/\S+@\S+\.\S+/.test(value)) {
      return "invalid";
    }
    return null;
  },

  phone_access_contact: (value) => {
    if (typeof value === "string") {
      if (value.length < 9 || (value.length > 10 && !/^\d+$/.test(value))) {
        return "invalid";
      }
    }
    return null;
  },

  phone_work_contact: (value) => {
    if (typeof value === "string") {
      if (value.length < 9 || (value.length > 10 && !/^\d+$/.test(value))) {
        return "invalid";
      }
    }
    return null;
  },
};

export default validators;
