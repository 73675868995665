import { Pagination } from "@mui/material";
import * as io from "io-ts";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FilterBar from "../../components/Filter";
import Loading from "../../components/Loading";
import GenericTable from "../../components/Table";
import { extInspReportFilters } from "../../constants/external-report/ExtInspReportFiltersItem";
import {
  createExtInspReportColumnsIcons,
  extInspReportColumns,
} from "../../constants/external-report/ExtInspReportTable";
import { AppContext } from "../../context/AppContext";
import useDeviceType, { DeviceType } from "../../hooks/useDeviceType";
import { DemandResponse, DemandResponseType } from "../../models/Demand";
import { ErrorType } from "../../models/Error";
import {
  DeleteResponse,
  DeleteResponseType,
  ExtInspReportCollectionResponse,
  ExtInspReportCollectionResponseType,
  ExtInspReportImageCollectionResponse,
  ExtInspReportImageCollectionResponseType,
  ExtInspReportResponseType,
} from "../../models/ExtInspReport";
import { UserResponse, UserResponseType } from "../../models/User";
import { callApi } from "../../services/ApiServices";
import { handleApiError } from "../../utils/ErrorUtils";
import { theme } from "../../utils/Theme";

const transformUserDetails = (data: UserResponseType) => ({
  name: data.name,
  surname: data.surname,
});

const transformDemandDetails = (data: DemandResponseType) => ({
  protocol: data.unique_protocol,
});

const ExtInspReportDataProvider = () => {
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const [extInspReport, setExtInspReport] = useState<ExtInspReportCollectionResponseType | null>(
    null,
  );
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filteredExtInspReport, setFilteredExtInspReport] = useState<ExtInspReportResponseType[]>(
    extInspReport?.["hydra:member"] || [],
  );

  const navigate = useNavigate();
  const deviceType = useDeviceType();

  useEffect(() => {
    getExtInspReports(`/ext_insp_reports?page=${currentPage}`, currentPage);
  }, [currentPage, isDeleted]);

  const handlePageChange = useCallback((_: React.ChangeEvent<unknown>, page: number) => {
    getExtInspReports(`/ext_insp_reports?page=${page}`, page);
  }, []);

  const handleImagesUploadExtInspReport = useCallback(
    (id: number) => {
      navigate(`/home/ext_insp_report/edit/${id}/image`);
    },
    [navigate],
  );

  const handleShowExtInspReport = useCallback(
    (id: number) => {
      navigate(`/home/ext_insp_report/${id}`);
    },
    [navigate],
  );

  const handleEditExtInspReport = useCallback(
    (id: number) => {
      navigate(`/home/ext_insp_report/edit/${id}`);
    },
    [navigate],
  );

  const handleDeleteExtInspReport = useCallback(async (id: number) => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo verbale?");
    if (!confirmDelete) return;

    setLoading(true);
    try {
      // Recupera le immagini associate al report
      const response = await callApi<ExtInspReportImageCollectionResponseType, undefined>(
        "GET",
        `/files`,
        ExtInspReportImageCollectionResponse,
        undefined,
      );

      if (response) {
        const filteredFiles = response["hydra:member"].filter(
          (file) => file.id_ext_insp_report === id,
        );

        for (const file of filteredFiles) {
          await callApi<DeleteResponseType, undefined>(
            "DELETE",
            `/files/${file.id}`,
            DeleteResponse,
          );
        }
      }

      await callApi<DeleteResponseType, undefined>(
        "DELETE",
        `/ext_insp_reports/${id}`,
        DeleteResponse,
      );

      setIsDeleted((prev) => !prev);
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  }, []);

  const getExtInspReports = async (url: string, page: number) => {
    setLoading(true);

    try {
      const response = await callApi<ExtInspReportCollectionResponseType, undefined>(
        "GET",
        url,
        ExtInspReportCollectionResponse,
        undefined,
      );

      if (response) {
        const updatedExtInspReports: ExtInspReportResponseType[] = [];

        for (const report of response["hydra:member"]) {
          const userId = report.commercial_user_id?.replace("/api/users/", "");
          const demandId = report.demand_id?.replace("/api/demands/", "");

          let userDetails = null;
          let demandDetails = null;

          if (userId) {
            userDetails = await getResponseById(
              "/users",
              parseInt(userId),
              UserResponse,
              transformUserDetails,
            );
          }

          if (demandId) {
            demandDetails = await getResponseById(
              "/demands",
              parseInt(demandId),
              DemandResponse,
              transformDemandDetails,
            );
          }

          updatedExtInspReports.push({
            ...report,
            commercial_user_id:
              userDetails && `${userDetails.name ?? ""} ${userDetails.surname ?? ""}`,
            demand_id: demandDetails && `${demandDetails.protocol ?? ""}`,
          });
        }

        setExtInspReport({
          ...response,
          "hydra:member": updatedExtInspReports,
        });

        if (page && response) {
          setCurrentPage(page);
          setTotalPages(Math.ceil(response["hydra:totalItems"] / 10));
        }
      }
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
    } finally {
      setLoading(false);
    }
  };

  // TODO: export function
  const getResponseById = async <T,>(
    endpoint: string,
    id: number,
    responseSchema: io.Type<T>,
    transform: (data: T) => any,
  ): Promise<any | null> => {
    try {
      const response = await callApi<T, undefined>("GET", `${endpoint}/${id}`, responseSchema);
      return response ? transform(response) : null;
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Si è verificato un errore imprevisto");
      return null;
    }
  };

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <TableContainer>
          <FilterBar<ExtInspReportResponseType>
            data={extInspReport?.["hydra:member"] || []}
            filters={extInspReportFilters}
            columns={deviceType === DeviceType.Mobile ? 2 : 4}
            onFilter={setFilteredExtInspReport}
          />
          {filteredExtInspReport.length > 0 ? (
            <GenericTable<ExtInspReportResponseType>
              data={filteredExtInspReport}
              columns={extInspReportColumns}
              icons={createExtInspReportColumnsIcons(
                handleShowExtInspReport,
                handleEditExtInspReport,
                handleDeleteExtInspReport,
                handleImagesUploadExtInspReport,
              )}
              loading={loading}
              error={error?.["hydra:description"] || null}
            />
          ) : (
            <NoFoundItems>nessun risultato di ricerca</NoFoundItems>
          )}
          <PaginationContainer $ismobile={deviceType !== DeviceType.Desktop}>
            <StyledPagination
              size="medium"
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </PaginationContainer>
        </TableContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const TableContainer = styled.div`
  margin-top: 2rem;
`;

const NoFoundItems = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primary};
`;

const PaginationContainer = styled.div<{ $ismobile: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const StyledPagination = styled(Pagination)`
  && {
    .MuiPaginationItem-root {
    }
    .MuiPaginationItem-page.Mui-selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${theme.colors.primary};
      }
    }
  }
`;

export default ExtInspReportDataProvider;
