import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Form from "../../components/Form";
import Loading from "../../components/Loading";
import {
  buttonImageProps,
  inputProps,
} from "../../constants/external-report/ExtInspReportImageUpload";
import { AppContext } from "../../context/AppContext";
import { useApiData } from "../../hooks/useApiData";
import { ErrorType } from "../../models/Error";
import {
  DeleteResponse,
  DeleteResponseType,
  ExtInspReportImageCollectionResponse,
  ExtInspReportImageCollectionResponseType,
  FileType,
  FileUploadResponse,
  FileUploadResponseType,
  IMAGE_TYPE_VALUE,
  ImageUploadType,
} from "../../models/ExtInspReport";
import { callApi } from "../../services/ApiServices";
import { getS3ImageUrl } from "../../services/s3Service";
import { handleApiError } from "../../utils/ErrorUtils";

const ExtInspReportImageUploadDataProvider = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, error, setLoading, setError } = useContext(AppContext);
  const { loadAllData } = useApiData();
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<ImageUploadType>({
    name: "",
    type: IMAGE_TYPE_VALUE.SELECT_TYPE,
    "files[]": [],
  });
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<FileType[]>([]);
  const [labelsImages, setLabelsImages] = useState<string[]>([]);

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);

      try {
        setFiles([]);
        setLabelsImages([]);
        setFormData((prev) => ({ ...prev, "files[]": [] }));

        await loadAllData(getAndFilterImages);
      } catch (e) {
        handleApiError(
          error as ErrorType,
          setError,
          "Si è verificato un errore nel caricamento dei dati",
        );
      } finally {
        setDataLoading(false);
      }
    };

    loadData();
  }, [formData.type]);

  useEffect(() => {
    setError(null);
  }, [formData]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;

    if (name === "files[]") {
      if (files) {
        const newFiles = Array.from(files);
        setLocalFiles((prev) => [...prev, ...newFiles]);

        setFormData((prev) => ({
          ...prev,
          "files[]": [...prev["files[]"], ...newFiles.map((file) => URL.createObjectURL(file))],
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    }
  };

  const handleRemoveFile = async (index: number) => {
    const fileToRemove = files[index];

    if (fileToRemove) {
      try {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare l'immagine?");
        if (!confirmDelete) return;

        if (fileToRemove.id) {
          await callApi<DeleteResponseType, undefined>(
            "DELETE",
            `/files/${fileToRemove.id}`,
            DeleteResponse,
          );
        }

        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setLabelsImages((prevLabels) => prevLabels.filter((_, i) => i !== index));
        setLocalFiles((prev) => prev.filter((_, i) => i !== index));

        setFormData((prev) => ({
          ...prev,
          name: "",
          "files[]": prev["files[]"].filter((_, i) => i !== index),
        }));
      } catch (error) {
        handleApiError(error as ErrorType, setError, "Errore durante l'eliminazione del file");
      }
    } else {
      const confirmDelete = window.confirm("Sei sicuro di voler eliminare l'immagine?");
      if (!confirmDelete) return;

      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      setLocalFiles((prev) => prev.filter((_, i) => i !== index));
      setLabelsImages((prevLabels) => prevLabels.filter((_, i) => i !== index));

      setFormData((prev) => ({
        ...prev,
        name: "",
        "files[]": prev["files[]"].filter((_, i) => i !== index),
      }));
    }
  };

  const getAndFilterImages = async (page: number = 1): Promise<boolean> => {
    setLoading(true);

    try {
      const response = await callApi<ExtInspReportImageCollectionResponseType, undefined>(
        "GET",
        `/files?page=${page}`,
        ExtInspReportImageCollectionResponse,
        undefined,
      );

      if (response) {
        const filteredFiles = response["hydra:member"].filter(
          (file) => file.id_ext_insp_report === Number(id) && file.type === formData.type,
        );
        const imageUrls = filteredFiles.map((file) => getS3ImageUrl(file.path || ""));
        const names = filteredFiles.map((file) => file.name || "");

        setLabelsImages((prev) => [...prev, ...names]);
        setFiles(filteredFiles);

        setFormData((prev) => ({
          ...prev,
          name: "",
          type: formData.type,
          "files[]": [...prev["files[]"], ...imageUrls.filter((file) => !file.startsWith("blob"))],
        }));

        return response?.["hydra:view"]?.["hydra:next"] !== undefined;
      }

      return false;
    } catch (error) {
      handleApiError(error as ErrorType, setError, "Errore durante il recupero delle immagini");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("name", formData.name || "");
      payload.append("type", formData.type || "");
      payload.append("id_ext_insp_report", id || "");

      localFiles.forEach((file) => {
        payload.append("files[]", file);
      });

      const response = await callApi<FileUploadResponseType, FormData>(
        "POST",
        `/files/upload`,
        FileUploadResponse,
        undefined,
        payload,
      );

      if (response && response.files.length > 0) {
        const newNames = response.files.map((file) => file.name || "");

        setFiles((prevFiles) => [...prevFiles, ...response.files]);
        setLabelsImages((prevLabels) => [...prevLabels, ...newNames]);
      }

      alert("File salvati con successo!");
      setLocalFiles([]);
    } catch (err) {
      handleApiError(err as ErrorType, setError, "Errore durante il salvataggio dei file");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {dataLoading ? (
        <Loading />
      ) : (
        <>
          <Form
            title="Aggiungi immagini"
            inputProps={inputProps(
              localFiles,
              formData,
              error,
              handleRemoveFile,
              handleInputChange,
              labelsImages,
            )}
            buttonProps={buttonImageProps(loading, error)}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default ExtInspReportImageUploadDataProvider;
