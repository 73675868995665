import * as io from "io-ts";

export const ROLES = {
  USER: "ROLE_USER",
  ADMIN: "ROLE_ADMIN",
  SELECT_ROLE: "",
};

export const Role = io.union([io.literal(ROLES.USER), io.literal(ROLES.ADMIN)]);

export const RolesArray = io.array(Role);

export const User = io.type({
  email: io.string,
  password: io.string,
  rememberMe: io.boolean,
});

export const UserRegister = io.intersection([
  io.type({
    roles: RolesArray,
    email: io.string,
    password: io.string,
    confirm_password: io.string,
  }),
  io.partial({
    name: io.string,
    surname: io.string,
    mobile_phone: io.string,
    address: io.string,
    city: io.string,
    province: io.string,
    date_of_birth: io.string,
    cf: io.string,
  }),
]);

export const UserEdit = io.intersection([
  io.type({ id: io.number, email: io.string, roles: RolesArray }),
  io.partial({
    name: io.string,
    surname: io.string,
    mobile_phone: io.string,
    address: io.string,
    city: io.string,
    province: io.string,
    cf: io.string,
    date_of_birth: io.string,
  }),
]);

export const UserLoggedResponse = io.intersection([
  io.type({
    id: io.number,
  }),
  io.partial({ name: io.string, surname: io.string }),
]);

export const UserLoginResponse = io.intersection([
  io.type({
    token: io.string,
    refresh_token: io.string,
  }),
  io.type({
    data: UserLoggedResponse,
  }),
]);

export const UserRegisterResponse = io.type({
  status: io.string,
});

export const UserResponse = io.type({
  id: io.number,
  email: io.string,
  roles: RolesArray,
  password: io.string,
  userIdentifier: io.string,
  name: io.union([io.string, io.undefined]),
  surname: io.union([io.string, io.undefined]),
  mobile_phone: io.union([io.string, io.undefined]),
  address: io.union([io.string, io.undefined]),
  city: io.union([io.string, io.undefined]),
  province: io.union([io.string, io.undefined]),
  cf: io.union([io.string, io.undefined]),
  date_of_birth: io.union([io.string, io.undefined]),
});

export const UserResponseCollection = io.type({
  "hydra:totalItems": io.number,
  "hydra:member": io.array(UserResponse),
  "hydra:view": io.union([
    io.type({
      "@id": io.string,
      "@type": io.string,
      "hydra:first": io.string,
      "hydra:last": io.string,
      "hydra:next": io.union([io.string, io.undefined]),
    }),
    io.undefined,
  ]),
});

export type UserType = io.TypeOf<typeof User>;
export type UserRegisterType = io.TypeOf<typeof UserRegister>;
export type UserLoginResponseType = io.TypeOf<typeof UserLoginResponse>;
export type UserLoggedResponseType = io.TypeOf<typeof UserLoggedResponse>;
export type UserRegisterResponseType = io.TypeOf<typeof UserRegisterResponse>;
export type UserResponseType = io.TypeOf<typeof UserResponse>;
export type UserResponseCollectionType = io.TypeOf<typeof UserResponseCollection>;
export type UserEditType = io.TypeOf<typeof UserEdit>;
export type RolesArrayType = io.TypeOf<typeof RolesArray>;
